////
/// @group _partials/view-text.scss
////


// scss
// ================================

.view-text {
	color: inherit;
	position: relative;
	font-size: 14px;
	font-family: 'Open Sans';
	margin-bottom: 20px;
	color: $c-black;
	column-gap: 80px;

	@include media($ms){
		font-size: 18px;
	}

	@include media($def){
		margin-bottom: 55px;
	}

	&:after{
		display: block;
		content: "";
		clear: both;
	}

	blockquote, & {
		> :first-child {
			margin-top: 0;
		}

		> :last-child {
			margin-bottom: 0;
		}
	}

	p{
		line-height: 20px;
		margin: 15px 0 25px;

		@include media($ms){
			line-height: 30px;
			margin: 30px 0 40px;
		}
	}

	&--seo{
		p{
			margin-bottom: 16px!important;
			font-size: 14px!important;
			line-height: 18px!important;
			font-weight: 300!important;
		}
	}
	
}

// заголовки
.view-text {
	h1, h2, h3, h4, h5, h6 {
		clear: both;
		line-height: 100%;
		font-weight: bold;
		margin: 14px 0 22px;
		font-family: 'DIN Pro';
	}

	h1 {
		font-size: 34px;

		@include media($ms){
			font-size: 38px;
		}
	}

	h2 {
		font-size: 26px;

		@include media($ms){
			font-size: 30px;
		}
	}

	h3 {
		font-size: 22px;

		@include media($ms){
			font-size: 26px;
		}
	}

	h4 {
		font-size: 18px;

		@include media($ms){
			font-size: 22px;
		}
	}

	h5 {
		font-size: 16px;

		@include media($ms){
			font-size: 18px;
		}
	}

	h6 {
		font-size: 16px;

		@include media($ms){
			line-height: 30px;
			margin: 30px 0 40px;
		}
	}
}

// основные элементы
.view-text {
	p, ul, ol, hr, dl, pre, address, blockquote, table,
	.table-wrapper, .media-wrapper {
		margin: 1em 0;
	}

	blockquote {
		padding: 1em 1.5em;
		background-color: #f7f8e2;
	}

	hr {
		clear: both;
		border-width: 0;
		border-top-width: 1px;
	}
}

// списки
.view-text {
	li{
		font-weight: bold;
		margin-bottom: 10px;
	}
	ul, ol{
		margin: 20px 0 40px;
	}
	ol{
		padding-left: 20px;
	}
	ul{
		list-style-type: none;
		padding: 0;

		li{
			padding-left: 26px;
			position: relative;

			&:after{
				content: "";
				position: absolute;
				top: 9px;
				left: 0;
				background-color: currentColor;
				width: 18px;
				height: 1px;
			}
		}
	}

	dd {
		margin-left: 1.5em;
	}

	dt {
		font-weight: bold;
	}

	dd + dt {
		margin: 1em 0 0;
	}
}

// демонстрация кода
.view-text {
	pre, code, kbd, samp {
		font-style: normal;
		font-weight: normal;
		font-family: $font-family--monospace;
	}

	pre {
		tab-size: 4;
		padding: 1em 1.5em;
		background-color: #f5f5f5;
	}

	code, kbd, samp {
		padding: 0 .4em;
	}

	code {
		background-color: #f5f5f5;
	}

	kbd {
		background-color: #e1f0fa;
	}

	samp {
		background-color: #f7f8e2;
	}
}

// media элементы
.view-text {
	iframe {
		border-width: 0;
	}

	audio {
		width: 100%;
	}

	audio[controls] {
		display: block;
	}

	.media-wrapper {
		&__holder {
			background-color: #f5f5f5;
			position: relative;
			iframe, video {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
		}
	}
}

// таблицы
.view-text {
	table  {
		width: 100%;
		background-color: transparent;
		border-collapse: collapse;
		border-spacing: 0;
		border: 1px solid #ddd;
	}

	caption {
		margin-bottom: .5em;
		caption-side: top;
	}

	tbody, tfoot, thead, tr, th, td {
		border: inherit;
	}

	th, td {
	   padding: .5em;
	   text-align: left;
	   vertical-align: top;
	}

	th {
		font-weight: bold;
		text-align: center;
		background-color: #f5f5f5;
	}
}

// таблица "без границ"
.view-text .table-null {
	&, th, td {
		border-color: transparent;
	}

	th {
		background: transparent;
	}
}

// таблица "зеброй"
.view-text .table-zebra {
	&, th, td {
		border-color: transparent;
	}

	th {
		background: transparent;
	}

	tr:nth-child(odd) {
		background-color: #f5f5f5;
	}
}

// ссылки
.view-text {
	a {
		color: #43f;
		&:visited {
			color: #0e00ad;
		}
		&:hover {
			color: #f34;
		}
		&:active {
			color: #000;
		}
	}
}

// форматирование и акцентирование
.view-text {
	b, strong {
		font-weight: bold;
	}

	i, cite, em, var, address, dfn, caption {
		font-style: italic;
	}

	abbr[title], dfn[title] {
		text-decoration: none;
		border-bottom: 1px dotted;
		cursor: help;
	}
}

// выход элементов за границы
// отступы должны совпадать с отступами .view-size
.view-text {
	@include media( $def, max ) {
		> blockquote,
		> pre,
		> .media-wrapper,
		> video,
		> iframe
		{
			margin-left: -1.5rem;
			margin-right: -1.5rem;
		}

		> blockquote,
		> pre
		{
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}
	}
}

// текст с короткой длиной строки
.view-text--article {
	> p,
	> h1,
	> h2,
	> h3,
	> h4,
	> h5,
	> h6,
	//> blockquote,
	//> pre,
	> hr,
	> ul,
	> ol,
	> dl,
	> audio,
	> address
	{
		max-width: $def;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (max-width: 1023px){
	.view-text{
		columns: 1!important;
	}
}