.search-block{
	margin-bottom: 20px;

	@include media($md){
		margin-bottom: 50px;
	}

	&__title{
		margin-bottom: 20px;
		font-size: 18px;
		color: $c-dark;

		@include media($md){
			margin-bottom: 40px;
			font-size: 22px;
		}
	}
	&__form{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		
		@include media($ms){
			justify-content: space-between;
		}

		.form__group{
			width: 100%;

			@include media($ms){
				width: calc(100% - 220px);
			}
		}

		.button{
			margin-bottom: 18px;

			@include media($md){
				margin-bottom: 28px;
			}
		}
	}
	&__grid{
		.search-item{
			&:nth-child(1){
				border-top: 1px solid $c-grey-lblue;
			}
		}
	}
}

.search-item{
	width: 100%;
	padding: 36px 0 30px;
	border-bottom: 1px solid $c-grey-lblue;
	display: flex;
	flex-direction: column;
	text-decoration: none;
	color: $c-dark;
	transition: border 0.3s ease, color 0.3s ease, opacity 0.3s 0.1s ease;

	&__title{
		font-size: 18px;
		line-height: 22px;
		transition: color 0.3s ease;
		position: relative;
		padding-bottom: 10px;
		margin-bottom: 12px;

		&:after{
			position: absolute;
			left: 0;
			bottom: 0;
			width: 24px;
			height: 2px;
			content: "";
			background-color: $c-red;
		}

		@include media($md){
			font-size: 22px;
			line-height: 28px;
		}
	}
	&__chart{
		font-size: 12px;
		text-transform: uppercase;
		font-weight: 300;
		letter-spacing: 1px;
		margin-bottom: 16px;
	}
	&__text{
		font-size: 16px;
		line-height: 20px;

		@include media($md){
			font-size: 18px;
			line-height: 24px;
		}
	}

	&:hover{
		border-bottom: 1px solid $c-red;
		.search-item{
			&__title{
				color: $c-red;
			}
		}
	}
}
