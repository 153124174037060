.semi-slider-block{
	display: flex;
	width: 100%;
	flex-direction: column;

	@include media($lg){
		flex-direction: row;
	}

	&__descr{
		width: 100%;
		min-width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		border: 1px solid $c-lgrey2;
		margin-right: 0;
		padding: 20px 2%;
		background-color: #e2e2e2;
		justify-content: space-between;

		@include media($ms){
			background-color: transparent;
		}

		@include media($lg){
			width: calc(25% - 2px);
			margin-right: 2px;
			min-width: 478px;
			padding: 40px 2%;
		}
	}
	&__descr-title{
		font-size: 32px;
		line-height: 100%;
		font-weight: bold;
		color: $c-dark;
		margin-bottom: 20px;
		width: 100%;

		@include media($md){
			font-size: 48px;
		}
	}
	&__button{
		font-size: 12px;
		color: $c-dark;
		letter-spacing: 1px;
		text-transform: uppercase;
		font-weight: 300;
		font-family: 'Open Sans';
		text-decoration: none;
		display: inline-flex;
		align-items: center;
		position: relative;
		padding: 10px 70px 10px 10px;

		svg{
			width: 48px;
			height: 10px;
			fill: $c-dark;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto 0;
			transition: right 0.3s ease;
		}

		span{
			position: relative;

			&:before{
				position: absolute;
				right: 0;
				bottom: 0;
				content: "";
				width: 0;
				height: 1px;
				background-color: $c-dark;
				transition: width 0.3s ease;
			}
		}

		&:hover{
			span{
				&:before{
					width: 100%;
				}
			}
			svg{
				right: 8px;
			}
		}
	}
	&__slider{
		width: 100%;
		border: 1px solid $c-lgrey2;
		position: relative;

		@include media($lg){
			width: calc(100% - 470px);
		}
	}
}
