.solutions-item{
	width: 100%;
	height: 130px;
	display: flex;
	border-bottom: 1px solid $c-lblue2;
	text-decoration: none;
    backface-visibility: hidden;
	transition: border-color 0.3s ease;

	@include media($ms){
		height: 162px;
	}

	&.is-active{
		pointer-events: none;
		border-color: $c-blue;

		.solutions-item{
			&__num{
				color: $c-white;
			}
			&__descr{
				background-color: $c-lblue2;
			}
			&__image{
				&:after{
					opacity: 0.5;
				}
				img{
					transform: scale(1.05) translate(-50%, -50%);
				}
			}
		}
	}

	&__image{
		min-width: 134px;
		width: 43.8%;
		max-width: 208px;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;
		background-color: #000;

		img{
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			position: absolute;
			min-width: 100%;
			max-width: initial;
			z-index: 1;
			transition: transform 0.3s ease, opacity 0.3s ease;
		}
	}
	&__descr{
		padding: 10px 14px;
		border-top: 2px solid $c-lblue2;
		background-color: $c-white;
		width: 56.2%;
		transition: background-color 0.3s ease;

		@include media($def){
			width: 100%;
		}
	}
	&__num{
		font-size: 30px;
		font-weight: bold;
		margin-bottom: 4px;
		color: $c-lblue2;
		transition: color 0.3s ease;

		@include media($ms){
			margin-bottom: 10px;
		}
	}
	&__text{
		font-size: 18px;
		color: $c-dark;
		line-height: 22px;
		font-family: 'Open Sans';
	    text-overflow: ellipsis;
    	overflow: hidden;

		@include media($ms){
			font-size: 22px;
			line-height: 25px;
		}
	}

	&:hover{
		border-color: $c-blue;

		.solutions-item{
			&__num{
				color: $c-white;
			}
			&__descr{
				background-color: $c-lblue2;
			}
			&__image{
				img{
					transform: scale(1.05) translate(-50%, -50%);
					opacity: 0.5;
				}
			}
		}
	}
}

.inner-solutions-img-num{
	position: absolute;
	right: 20px;
	top: 20px;
	opacity: 0.2;
	font-size: 60px;
	font-weight: bold;
	font-family: 'DIN Pro';
	line-height: 100%;
	z-index: 2;
	color: $c-white;

	@include media($def){
		font-size: 120px;
	}
}