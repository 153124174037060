.buy-widget{
	margin-right: 10px;
	margin-bottom: 10px;
	width: 100%;

	@include media($ms){
		width: auto;
		width: initial;
	}

	&__form{
		width: 100%;
		justify-content: space-between;
		display: flex;
		align-items: center;
		flex-direction: column;

		@include media($ms){
			width: 464px;
		}

		@include media($ms){
			flex-direction: row;
			align-items: flex-end;
		}

		.form__group{
			width: 100%;

			@include media($ms){
				width: calc(100% - 194px);
			}
		}

		.button{
			margin-bottom: 18px;

			@include media($md){
				margin-bottom: 28px;
			}
		}
	}
}