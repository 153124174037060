@media only screen and (min-width: 320px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 375px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 412px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 480px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 640px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 768px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1024px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1025px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1280px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1440px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1540px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1539px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1439px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1279px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1024px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1023px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 767px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 639px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 479px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 411px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 374px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 319px) {
  /*! $mqpacker hack */
}

html {
  font-family: "Open Sans";
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

figcaption,
figure {
  display: block;
}

figure {
  margin: 1em 0;
}

main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
}

b, strong {
  font-weight: bold;
}

code, kbd, samp {
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  font-family: "Open Sans";
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

a:active, a:hover {
  outline-width: 0;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

abbr[title], dfn[title] {
  cursor: help;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[readonly] {
  cursor: default;
}

input:disabled {
  cursor: not-allowed;
}

html {
  position: relative;
  height: 100%;
  font-size: 12px;
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1280px) {
  html {
    font-size: 16px;
  }
}

*, :after, :before {
  box-sizing: inherit;
}

a {
  color: #43f;
  transition: color 0.1s ease;
}

a:hover {
  transition: color 0.3s ease;
}

body {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  min-width: 320px;
  font-family: 'DIN Pro';
}

pre {
  padding: 1.2em;
  tab-size: 4;
  border-width: 0;
  white-space: pre;
  white-space: pre-wrap;
  background: #eee;
  word-wrap: break-word;
}

img {
  max-width: 100%;
  height: auto;
}

small {
  display: inline-block;
  vertical-align: text-bottom;
}

svg {
  pointer-events: none;
  transition: fill 0.3s ease;
}

hr {
  border-width: 0;
  border-top: 1px solid #aaa;
}

::selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-moz-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-ms-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

input {
  box-shadow: none;
}

input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
  opacity: .5;
}

input:-ms-input-placeholder {
  color: #999;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea {
  box-shadow: none;
}

textarea::-webkit-input-placeholder {
  color: #999;
}

textarea::-moz-placeholder {
  color: #999;
  opacity: .5;
}

textarea:-ms-input-placeholder {
  color: #999;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.view-wrapper {
  position: relative;
  min-height: 100%;
  overflow: hidden;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 640px) {
  .view-wrapper {
    padding-top: 80px;
  }
}

.view-footer {
  margin-top: auto;
}

.view-size {
  position: relative;
  max-width: 1820px;
  max-width: calc(1820px + 48px);
  padding: 0 14px;
  margin: 0 auto;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
}

.view-size--md {
  max-width: 960px;
}

.view-size--lg {
  max-width: 1440px;
}

.view-size--mg {
  max-width: 100%;
  width: 100%;
  padding: 0 14px 0 14px;
}

@media only screen and (min-width: 1025px) {
  .view-size--mg {
    padding: 0 0 0 30px;
  }
}

.view-size--no-gap {
  max-width: 1280px;
}

.view-section {
  position: relative;
}

.view-header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #ccd3dd;
  z-index: 11000;
  background-color: #fff;
}

@media only screen and (min-width: 640px) {
  .view-header {
    height: 80px;
    padding: 4px 40px;
    z-index: 1100;
  }
}

.view-footer {
  position: relative;
  background-color: #373d41;
  backface-visibility: hidden;
}

.view-footer__top-row {
  padding: 70px 2.6% 58px 6%;
  border-bottom: 1px solid #464d52;
}

.view-footer__block {
  width: 100%;
}

.view-footer__bottom-row {
  padding: 52px 2.6% 30px 6%;
}

@media only screen and (min-width: 1024px) {
  .view-footer__bottom-row {
    padding: 30px 2.6% 30px 6%;
  }
}

.zoom-in {
  /* animate in */
  /* animate out */
  /* Dark overlay, start state */
  /* animate in */
  /* animate out */
}

.zoom-in .hidden-wrapper {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(0.8);
}

.zoom-in.mfp-ready .hidden-wrapper {
  opacity: 1;
  transform: scale(1);
}

.zoom-in.mfp-removing .hidden-wrapper {
  transform: scale(0.8);
  opacity: 0;
}

.zoom-in.mfp-bg {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.hidden-wrapper {
  position: relative;
  padding: 2rem;
  margin: 1.2rem auto;
  background-color: #fff;
  width: 96%;
  max-width: 1024px;
}

.hidden-wrapper--right {
  margin-top: 50px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: -0;
  max-width: 100%;
  min-height: calc(100vh - 80px);
  width: 100%;
}

@media only screen and (min-width: 640px) {
  .hidden-wrapper--right {
    max-width: 480px;
    margin-right: -8px;
    width: 96%;
    margin-top: 77px;
  }
}

.hidden-wrapper--right .mfp-close {
  display: none;
}

.hidden-title {
  font-size: 22px;
  color: #333;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .hidden-title {
    margin-bottom: 32px;
  }
}

.hidden-wrapper--lg {
  max-width: 1280px;
}

.hidden-wrapper--md {
  max-width: 768px;
}

.hidden-wrapper--sm {
  max-width: 480px;
}

.hidden-wrapper--strip {
  background: none;
  padding: 0;
}

.hidden-wrapper--no-gap {
  padding: 0;
}

.hidden-wrapper ._show-in--hidden-wrapper {
  display: block;
}

.view-text {
  color: inherit;
  position: relative;
  font-size: 14px;
  font-family: 'Open Sans';
  margin-bottom: 20px;
  color: #000;
  column-gap: 80px;
}

@media only screen and (min-width: 640px) {
  .view-text {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1025px) {
  .view-text {
    margin-bottom: 55px;
  }
}

.view-text:after {
  display: block;
  content: "";
  clear: both;
}

.view-text blockquote > :first-child, .view-text > :first-child {
  margin-top: 0;
}

.view-text blockquote > :last-child, .view-text > :last-child {
  margin-bottom: 0;
}

.view-text p {
  line-height: 20px;
  margin: 15px 0 25px;
}

@media only screen and (min-width: 640px) {
  .view-text p {
    line-height: 30px;
    margin: 30px 0 40px;
  }
}

.view-text--seo p {
  margin-bottom: 16px !important;
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: 300 !important;
}

.view-text h1, .view-text h2, .view-text h3, .view-text h4, .view-text h5, .view-text h6 {
  clear: both;
  line-height: 100%;
  font-weight: bold;
  margin: 14px 0 22px;
  font-family: 'DIN Pro';
}

.view-text h1 {
  font-size: 34px;
}

@media only screen and (min-width: 640px) {
  .view-text h1 {
    font-size: 38px;
  }
}

.view-text h2 {
  font-size: 26px;
}

@media only screen and (min-width: 640px) {
  .view-text h2 {
    font-size: 30px;
  }
}

.view-text h3 {
  font-size: 22px;
}

@media only screen and (min-width: 640px) {
  .view-text h3 {
    font-size: 26px;
  }
}

.view-text h4 {
  font-size: 18px;
}

@media only screen and (min-width: 640px) {
  .view-text h4 {
    font-size: 22px;
  }
}

.view-text h5 {
  font-size: 16px;
}

@media only screen and (min-width: 640px) {
  .view-text h5 {
    font-size: 18px;
  }
}

.view-text h6 {
  font-size: 16px;
}

@media only screen and (min-width: 640px) {
  .view-text h6 {
    line-height: 30px;
    margin: 30px 0 40px;
  }
}

.view-text p, .view-text ul, .view-text ol, .view-text hr, .view-text dl, .view-text pre, .view-text address, .view-text blockquote, .view-text table,
.view-text .table-wrapper, .view-text .media-wrapper {
  margin: 1em 0;
}

.view-text blockquote {
  padding: 1em 1.5em;
  background-color: #f7f8e2;
}

.view-text hr {
  clear: both;
  border-width: 0;
  border-top-width: 1px;
}

.view-text li {
  font-weight: bold;
  margin-bottom: 10px;
}

.view-text ul, .view-text ol {
  margin: 20px 0 40px;
}

.view-text ol {
  padding-left: 20px;
}

.view-text ul {
  list-style-type: none;
  padding: 0;
}

.view-text ul li {
  padding-left: 26px;
  position: relative;
}

.view-text ul li:after {
  content: "";
  position: absolute;
  top: 9px;
  left: 0;
  background-color: currentColor;
  width: 18px;
  height: 1px;
}

.view-text dd {
  margin-left: 1.5em;
}

.view-text dt {
  font-weight: bold;
}

.view-text dd + dt {
  margin: 1em 0 0;
}

.view-text pre, .view-text code, .view-text kbd, .view-text samp {
  font-style: normal;
  font-weight: normal;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
}

.view-text pre {
  tab-size: 4;
  padding: 1em 1.5em;
  background-color: #f5f5f5;
}

.view-text code, .view-text kbd, .view-text samp {
  padding: 0 .4em;
}

.view-text code {
  background-color: #f5f5f5;
}

.view-text kbd {
  background-color: #e1f0fa;
}

.view-text samp {
  background-color: #f7f8e2;
}

.view-text iframe {
  border-width: 0;
}

.view-text audio {
  width: 100%;
}

.view-text audio[controls] {
  display: block;
}

.view-text .media-wrapper__holder {
  background-color: #f5f5f5;
  position: relative;
}

.view-text .media-wrapper__holder iframe, .view-text .media-wrapper__holder video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.view-text table {
  width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
}

.view-text caption {
  margin-bottom: .5em;
  caption-side: top;
}

.view-text tbody, .view-text tfoot, .view-text thead, .view-text tr, .view-text th, .view-text td {
  border: inherit;
}

.view-text th, .view-text td {
  padding: .5em;
  text-align: left;
  vertical-align: top;
}

.view-text th {
  font-weight: bold;
  text-align: center;
  background-color: #f5f5f5;
}

.view-text .table-null, .view-text .table-null th, .view-text .table-null td {
  border-color: transparent;
}

.view-text .table-null th {
  background: transparent;
}

.view-text .table-zebra, .view-text .table-zebra th, .view-text .table-zebra td {
  border-color: transparent;
}

.view-text .table-zebra th {
  background: transparent;
}

.view-text .table-zebra tr:nth-child(odd) {
  background-color: #f5f5f5;
}

.view-text a {
  color: #43f;
}

.view-text a:visited {
  color: #0e00ad;
}

.view-text a:hover {
  color: #f34;
}

.view-text a:active {
  color: #000;
}

.view-text b, .view-text strong {
  font-weight: bold;
}

.view-text i, .view-text cite, .view-text em, .view-text var, .view-text address, .view-text dfn, .view-text caption {
  font-style: italic;
}

.view-text abbr[title], .view-text dfn[title] {
  text-decoration: none;
  border-bottom: 1px dotted;
  cursor: help;
}

@media only screen and (max-width: 1024px) {
  .view-text > blockquote,
  .view-text > pre,
  .view-text > .media-wrapper,
  .view-text > video,
  .view-text > iframe {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .view-text > blockquote,
  .view-text > pre {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.view-text--article > p,
.view-text--article > h1,
.view-text--article > h2,
.view-text--article > h3,
.view-text--article > h4,
.view-text--article > h5,
.view-text--article > h6,
.view-text--article > hr,
.view-text--article > ul,
.view-text--article > ol,
.view-text--article > dl,
.view-text--article > audio,
.view-text--article > address {
  max-width: 1025px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1023px) {
  .view-text {
    columns: 1 !important;
  }
}

.table-wrapper {
  clear: both;
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
}

.table-wrapper__holder {
  max-width: 100%;
  overflow-x: auto;
  padding: 0;
  margin: 0;
}

.table-wrapper__table {
  width: 100%;
  border-collapse: collapse;
}

.table-wrapper:before, .table-wrapper:after {
  content: '';
  width: 8px;
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity .25s linear;
}

.table-wrapper:before {
  left: 0;
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
}

.table-wrapper:after {
  right: 0;
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.table-wrapper--outside-left:before {
  opacity: .3;
}

.table-wrapper--outside-right:after {
  opacity: .3;
}

.content-image {
  position: relative;
}

.content-image--width-1200-and-more {
  display: block;
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.view-text--article .content-image--width-600-and-more {
  display: block;
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media only screen and (max-width: 1539px) {
  .content-image--width-1000-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 1439px) {
  .content-image--width-900-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 1279px) {
  .content-image--width-800-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 767px) {
  .content-image--width-500-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 639px) {
  .content-image--width-300-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 479px) {
  .content-image--width-200-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 374px) {
  .content-image--width-100-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.title {
  line-height: 1.2;
  font-weight: bold;
  color: #404040;
  font-size: 2rem;
  margin: 1.2em 0 1rem;
}

.title:first-child {
  margin-top: 0;
}

.title--sup {
  font-size: 2.5rem;
}

.title--sub {
  font-size: 1.5rem;
}

.breadcrumbs {
  width: 100%;
  display: table;
  font-size: 0;
  line-height: 0;
  font-size: 14px;
  color: #fff;
  font-family: 'Open Sans';
}

@media only screen and (min-width: 640px) {
  .breadcrumbs {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1025px) {
  .breadcrumbs {
    font-size: 18px;
  }
}

.breadcrumbs-wrapper {
  background-color: #0c4da2;
  width: 100%;
  padding: 10px 16px;
}

@media only screen and (min-width: 640px) {
  .breadcrumbs-wrapper {
    padding: 20px 32px;
  }
}

.breadcrumb-mega-wrapper {
  margin-bottom: 30px;
}

@media only screen and (min-width: 1025px) {
  .breadcrumb-mega-wrapper {
    margin-bottom: 60px;
  }
}

.breadcrumbs a {
  color: currentColor;
  text-decoration: none;
  font-weight: normal;
}

.breadcrumbs a:hover {
  color: #ed1a3b;
}

.breadcrumbs span {
  color: currentColor;
  display: inline-block;
  vertical-align: top;
  font-size: 1em;
  line-height: 1.4;
  margin-right: .5em;
  font-weight: bold;
}

.breadcrumbs span:before {
  margin-right: .5em;
  content: '\203A';
  display: inline-block;
  vertical-align: top;
  font-weight: normal;
  line-height: 90%;
  font-size: 22px;
}

.breadcrumbs span:first-child:before {
  display: none;
}

@media only screen and (max-width: 767px) {
  .breadcrumbs {
    display: block;
  }
  .breadcrumbs span, .breadcrumbs span:before {
    display: none;
  }
  .breadcrumbs span:nth-last-child(2) {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    background: none;
    margin: 0;
  }
  .breadcrumbs span:nth-last-child(2):before {
    content: '\2039';
    margin: 0 5px 0 3px;
    display: inline-block;
  }
}

.sitemap {
  max-width: 768px;
  padding: 1rem;
  position: relative;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.sitemap ul {
  list-style: none;
  padding: 0 0 0 2em;
  margin: 0;
}

.sitemap li {
  list-style: none;
  line-height: 1.4em;
}

.sitemap a {
  display: block;
}

.sitemap > ul > li > a {
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: none;
  color: #ed1a3b;
  transition: color .5s ease;
  border-bottom: 2px solid #f1ebdf;
  min-height: 40px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  position: relative;
}

.sitemap > ul > li > a:after {
  position: absolute;
  left: 0;
  bottom: -2px;
  content: "";
  height: 2px;
  width: 100%;
  transform: rotateY(86deg);
  transform-origin: left;
  transition: background-color .5s ease,-webkit-transform .6s ease;
  transition: background-color .5s ease,transform .6s ease;
  transition: background-color .5s ease,transform .6s ease,-webkit-transform .6s ease;
  background-color: #b89b5e;
}

.sitemap > ul > li > a:hover {
  color: #0c4da2;
}

.sitemap > ul > li > a:hover:after {
  background-color: #0c4da2;
}

.sitemap > ul > li > ul {
  padding-left: 0;
}

.sitemap > ul > li > ul > li > ul > li > ul > li > a {
  color: #ed1a3b;
}

.sitemap > ul ul {
  list-style: none;
  padding: 0 0 0 2em;
  margin: 0;
}

.sitemap > ul ul a {
  font-size: 1rem;
  color: #2d3b4e;
  font-weight: 500;
  transition: color .5s ease, border-color 0.5s ease;
  border-bottom: 1px solid #e1dbcd;
  min-height: 30px;
  padding: 8px 0 8px 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  position: relative;
}

.sitemap > ul ul a:hover {
  color: #0c4da2;
  border-color: #0c4da2;
}

.sitemap > ul ul li {
  position: relative;
  margin: .1em 0;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.pagination__btns-group {
  display: flex;
  justify-content: center;
  margin: 0 3%;
  align-items: center;
}

.pagination__arrow {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
}

.pagination__arrow svg {
  width: 22px;
  height: 14px;
  fill: #333;
  transition: fill 0.1s ease, transform 0.1s ease;
  transform-origin: center;
}

.pagination__arrow:hover svg {
  fill: #ed1a3b;
}

.pagination__arrow:active svg {
  fill: #373d41;
  transform: scale(0.92);
  transition: fill 0s ease, transform 0s ease;
}

.pagination__btn {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #333;
  text-decoration: none;
  margin: 0 2px;
}

@media only screen and (min-width: 640px) {
  .pagination__btn {
    width: 34px;
    height: 34px;
  }
}

.pagination__btn.is-active {
  background-color: #ed1a3b;
  color: #fff;
  pointer-events: none;
}

.pagination__btn:hover {
  color: #ed1a3b;
}

.form {
  position: relative;
  font-size: 1rem;
}

.form__group {
  display: block;
  position: relative;
  margin: 0 0 18px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .form__group {
    margin: 0 0 28px;
  }
}

.form__group .form__group {
  margin: 0 0 16px;
}

.form__group--ask-flag {
  display: flex;
  align-items: center;
  padding-top: 14px;
}

.form__label {
  cursor: default;
  display: inline-block;
  vertical-align: top;
  user-select: none;
  margin-bottom: .4rem;
}

.form__caption {
  font-size: 15px;
  color: #68737a;
  display: block;
  margin-bottom: 3px;
}

.form__info {
  display: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  font-size: .7em;
  background-color: #000;
  color: #fff;
  padding: .1em .3em;
}

input:focus ~ .form__info,
textarea:focus ~ .form__info,
select:focus ~ .form__info {
  display: block;
}

label.form__label {
  cursor: pointer;
}

.control-holder {
  position: relative;
}

.control-holder label.has-error {
  display: inline-block;
  vertical-align: top;
  user-select: none;
  font-size: 12px;
  right: 20px;
  line-height: 100%;
  padding: 2px 2px;
  color: #ed1a3b;
  position: absolute;
  top: 100%;
  background-color: transparent;
}

.control-holder--text input, .control-holder--text textarea, .control-holder--text select {
  padding: 12px 20px;
  display: block;
  width: 100%;
  border: 1px solid #b6b6b6;
  transition: border-color 0.3s ease;
  outline: none;
}

.control-holder--text input:focus, .control-holder--text textarea:focus, .control-holder--text select:focus {
  border-color: #0c4da2;
}

.control-holder--text input.has-error, .control-holder--text textarea.has-error, .control-holder--text select.has-error {
  border-color: #ed1a3b;
}

.control-holder--text textarea {
  resize: none;
  min-height: 7em;
  max-height: 18em;
}

.control-holder--text input, .control-holder--text select {
  height: 50px;
}

.control-holder--flag label {
  user-select: none;
  margin-right: 1em;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.control-holder--flag label:only-child, .control-holder--flag label.has-error {
  cursor: default;
  margin-right: 0;
}

.control-holder--flag ins {
  position: relative;
  display: inline-block;
  vertical-align: text-bottom;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border: 1px solid #ed1a3b;
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
}

.control-holder--flag span {
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

.control-holder--flag input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -1;
}

.control-holder--flag input[type="radio"] ~ ins {
  border-radius: 50%;
}

.control-holder--flag input:checked ~ ins {
  background-color: #ed1a3b;
  background-image: url("pic/okay.svg");
}

.control-holder--flag input:disabled ~ ins,
.control-holder--flag input:disabled ~ span {
  opacity: .5;
  cursor: not-allowed;
}

.control-holder--file label {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.control-holder--file input {
  position: absolute;
  outline: none;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
}

.control-holder--file ._ellipsis {
  max-width: 200px;
  width: auto;
  display: inline-block;
  vertical-align: top;
}

#fPopUp {
  max-width: 80% !important;
  min-width: 280px !important;
}

@media only screen and (min-width: 768px) {
  #fPopUp {
    max-width: 50% !important;
    min-width: 400px !important;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
}

.grid .gbox {
  height: 100%;
  min-height: 100%;
}

.grid--1 > .gcell {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

.grid--2 > .gcell {
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
}

.grid--3 > .gcell {
  width: 33.3333333%;
  max-width: 33.3333333%;
  flex-basis: 33.3333333%;
}

.grid--4 > .gcell {
  width: 25%;
  max-width: 25%;
  flex-basis: 25%;
}

.grid--5 > .gcell {
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
}

.grid--6 > .gcell {
  width: 16.6666667%;
  max-width: 16.6666667%;
  flex-basis: 16.6666667%;
}

.grid--7 > .gcell {
  width: 14.2857143%;
  max-width: 14.2857143%;
  flex-basis: 14.2857143%;
}

.grid--8 > .gcell {
  width: 12.5%;
  max-width: 12.5%;
  flex-basis: 12.5%;
}

.grid--9 > .gcell {
  width: 11.1111111%;
  max-width: 11.1111111%;
  flex-basis: 11.1111111%;
}

.grid--10 > .gcell {
  width: 10%;
  max-width: 10%;
  flex-basis: 10%;
}

.grid--11 > .gcell {
  width: 9.0909091%;
  max-width: 9.0909091%;
  flex-basis: 9.0909091%;
}

.grid--12 > .gcell {
  width: 8.3333333%;
  max-width: 8.3333333%;
  flex-basis: 8.3333333%;
}

.grid--space-sm {
  margin-left: -1.25px;
  margin-right: -1.25px;
}

.grid--space-sm > .gcell {
  padding-left: 1.25px;
  padding-right: 1.25px;
}

.grid--space-md {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.grid--space-md > .gcell {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.grid--space-def {
  margin-left: -5px;
  margin-right: -5px;
}

.grid--space-def > .gcell {
  padding-left: 5px;
  padding-right: 5px;
}

.grid--space-lg {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.grid--space-lg > .gcell {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.grid--space-xl {
  margin-left: -10px;
  margin-right: -10px;
}

.grid--space-xl > .gcell {
  padding-left: 10px;
  padding-right: 10px;
}

.grid--space-mg {
  margin-left: -20px;
  margin-right: -20px;
}

.grid--space-mg > .gcell {
  padding-left: 20px;
  padding-right: 20px;
}

.grid > .gcell {
  display: inline-flex;
  flex-wrap: wrap;
}

.grid > .gcell--1 {
  width: 8.3333333%;
  max-width: 8.3333333%;
  flex-basis: 8.3333333%;
}

.grid > .gcell--2 {
  width: 16.6666667%;
  max-width: 16.6666667%;
  flex-basis: 16.6666667%;
}

.grid > .gcell--3 {
  width: 25%;
  max-width: 25%;
  flex-basis: 25%;
}

.grid > .gcell--4 {
  width: 33.3333333%;
  max-width: 33.3333333%;
  flex-basis: 33.3333333%;
}

.grid > .gcell--5 {
  width: 41.6666667%;
  max-width: 41.6666667%;
  flex-basis: 41.6666667%;
}

.grid > .gcell--6 {
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
}

.grid > .gcell--7 {
  width: 58.3333333%;
  max-width: 58.3333333%;
  flex-basis: 58.3333333%;
}

.grid > .gcell--8 {
  width: 66.6666667%;
  max-width: 66.6666667%;
  flex-basis: 66.6666667%;
}

.grid > .gcell--9 {
  width: 75%;
  max-width: 75%;
  flex-basis: 75%;
}

.grid > .gcell--10 {
  width: 83.3333333%;
  max-width: 83.3333333%;
  flex-basis: 83.3333333%;
}

.grid > .gcell--11 {
  width: 91.6666667%;
  max-width: 91.6666667%;
  flex-basis: 91.6666667%;
}

.grid > .gcell--12 {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

@media only screen and (min-width: 375px) {
  .grid--xs-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--xs-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--xs-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--xs-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--xs-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--xs-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--xs-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--xs-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--xs-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--xs-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--xs-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--xs-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--xs-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--xs-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--xs-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--xs-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--xs-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--xs-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--xs-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--xs-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--xs-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--xs-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--xs-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--xs-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--xs-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--xs-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 480px) {
  .grid--sm-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--sm-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--sm-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--sm-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--sm-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--sm-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--sm-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--sm-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--sm-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--sm-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--sm-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--sm-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--sm-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--sm-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--sm-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--sm-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--sm-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--sm-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--sm-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--sm-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--sm-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--sm-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--sm-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--sm-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--sm-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--sm-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 640px) {
  .grid--ms-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--ms-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--ms-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--ms-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--ms-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--ms-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--ms-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--ms-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--ms-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--ms-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--ms-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--ms-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--ms-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--ms-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--ms-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--ms-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--ms-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--ms-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--ms-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--ms-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--ms-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--ms-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--ms-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--ms-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--ms-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--ms-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 768px) {
  .grid--md-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--md-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--md-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--md-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--md-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--md-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--md-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--md-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--md-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--md-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--md-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--md-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--md-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--md-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--md-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--md-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--md-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--md-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--md-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--md-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--md-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--md-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--md-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--md-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--md-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--md-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .grid--defp-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--defp-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--defp-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--defp-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--defp-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--defp-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--defp-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--defp-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--defp-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--defp-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--defp-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--defp-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--defp-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--defp-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--defp-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--defp-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--defp-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--defp-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--defp-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--defp-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--defp-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--defp-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--defp-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--defp-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--defp-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--defp-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 1025px) {
  .grid--def-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--def-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--def-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--def-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--def-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--def-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--def-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--def-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--def-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--def-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--def-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--def-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--def-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--def-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--def-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--def-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--def-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--def-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--def-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--def-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--def-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--def-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--def-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--def-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--def-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--def-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 1280px) {
  .grid--lg-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--lg-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--lg-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--lg-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--lg-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--lg-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--lg-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--lg-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--lg-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--lg-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--lg-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--lg-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--lg-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--lg-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--lg-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--lg-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--lg-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--lg-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--lg-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--lg-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--lg-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--lg-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--lg-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--lg-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--lg-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--lg-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 1440px) {
  .grid--xl-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--xl-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--xl-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--xl-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--xl-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--xl-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--xl-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--xl-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--xl-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--xl-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--xl-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--xl-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--xl-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--xl-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--xl-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--xl-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--xl-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--xl-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--xl-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--xl-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--xl-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--xl-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--xl-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--xl-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--xl-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--xl-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 1540px) {
  .grid--mg-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--mg-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--mg-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--mg-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--mg-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--mg-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--mg-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--mg-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--mg-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--mg-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--mg-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--mg-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--mg-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--mg-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--mg-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--mg-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--mg-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--mg-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--mg-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--mg-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--mg-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--mg-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--mg-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--mg-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--mg-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--mg-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

.grid--inline {
  display: inline-flex;
}

.grid--items-start {
  align-items: flex-start;
}

.grid--items-end {
  align-items: flex-end;
}

.grid--items-center {
  align-items: center;
}

.grid--items-stretch {
  align-items: stretch;
}

.grid--justify-start {
  justify-content: flex-start;
}

.grid--justify-end {
  justify-content: flex-end;
}

.grid--justify-center {
  justify-content: center;
}

.grid--justify-between {
  justify-content: space-between;
}

.grid--justify-around {
  justify-content: space-around;
}

.grid--nowrap {
  flex-wrap: nowrap;
}

.grid--column {
  flex-direction: column;
}

.grid--row {
  flex-direction: row;
}

.grid--row-reverse {
  flex-direction: row-reverse;
}

.grid > .gcell--start {
  align-self: flex-start;
}

.grid > .gcell--end {
  align-self: flex-end;
}

.grid > .gcell--center {
  align-self: center;
}

.grid > .gcell--stretch {
  align-self: stretch;
}

.grid > .gcell--nogrow {
  flex-grow: 0;
}

.grid > .gcell--grow {
  flex-grow: 1;
}

.grid > .gcell--shrink {
  flex-shrink: 1;
}

.grid > .gcell--noshrink {
  flex-shrink: 0;
}

.grid > .gcell--order-start {
  order: -1;
}

.grid > .gcell--order-end {
  order: 1;
}

.grid > .gcell--noorder {
  order: 0;
}

.grid > .gcell--nobasis {
  flex-basis: 0;
}

@media only screen and (min-width: 375px) {
  .grid--xs-items-start {
    align-items: flex-start;
  }
  .grid--xs-items-end {
    align-items: flex-end;
  }
  .grid--xs-items-center {
    align-items: center;
  }
  .grid--xs-items-stretch {
    align-items: stretch;
  }
  .grid--xs-justify-start {
    justify-content: flex-start;
  }
  .grid--xs-justify-end {
    justify-content: flex-end;
  }
  .grid--xs-justify-center {
    justify-content: center;
  }
  .grid--xs-justify-between {
    justify-content: space-between;
  }
  .grid--xs-justify-around {
    justify-content: space-around;
  }
  .grid--xs-nowrap {
    flex-wrap: nowrap;
  }
  .grid--xs-wrap {
    flex-wrap: wrap;
  }
  .grid--xs-column {
    flex-direction: column;
  }
  .grid--xs-row {
    flex-direction: row;
  }
  .grid--xs-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--xs-start {
    align-self: flex-start;
  }
  .grid > .gcell--xs-end {
    align-self: flex-end;
  }
  .grid > .gcell--xs-center {
    align-self: center;
  }
  .grid > .gcell--xs-stretch {
    align-self: stretch;
  }
  .grid > .gcell--xs-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--xs-grow {
    flex-grow: 1;
  }
  .grid > .gcell--xs-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--xs-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--xs-order-start {
    order: -1;
  }
  .grid > .gcell--xs-order-end {
    order: 1;
  }
  .grid > .gcell--xs-noorder {
    order: 0;
  }
  .grid > .gcell--xs-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 480px) {
  .grid--sm-items-start {
    align-items: flex-start;
  }
  .grid--sm-items-end {
    align-items: flex-end;
  }
  .grid--sm-items-center {
    align-items: center;
  }
  .grid--sm-items-stretch {
    align-items: stretch;
  }
  .grid--sm-justify-start {
    justify-content: flex-start;
  }
  .grid--sm-justify-end {
    justify-content: flex-end;
  }
  .grid--sm-justify-center {
    justify-content: center;
  }
  .grid--sm-justify-between {
    justify-content: space-between;
  }
  .grid--sm-justify-around {
    justify-content: space-around;
  }
  .grid--sm-nowrap {
    flex-wrap: nowrap;
  }
  .grid--sm-wrap {
    flex-wrap: wrap;
  }
  .grid--sm-column {
    flex-direction: column;
  }
  .grid--sm-row {
    flex-direction: row;
  }
  .grid--sm-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--sm-start {
    align-self: flex-start;
  }
  .grid > .gcell--sm-end {
    align-self: flex-end;
  }
  .grid > .gcell--sm-center {
    align-self: center;
  }
  .grid > .gcell--sm-stretch {
    align-self: stretch;
  }
  .grid > .gcell--sm-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--sm-grow {
    flex-grow: 1;
  }
  .grid > .gcell--sm-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--sm-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--sm-order-start {
    order: -1;
  }
  .grid > .gcell--sm-order-end {
    order: 1;
  }
  .grid > .gcell--sm-noorder {
    order: 0;
  }
  .grid > .gcell--sm-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 640px) {
  .grid--ms-items-start {
    align-items: flex-start;
  }
  .grid--ms-items-end {
    align-items: flex-end;
  }
  .grid--ms-items-center {
    align-items: center;
  }
  .grid--ms-items-stretch {
    align-items: stretch;
  }
  .grid--ms-justify-start {
    justify-content: flex-start;
  }
  .grid--ms-justify-end {
    justify-content: flex-end;
  }
  .grid--ms-justify-center {
    justify-content: center;
  }
  .grid--ms-justify-between {
    justify-content: space-between;
  }
  .grid--ms-justify-around {
    justify-content: space-around;
  }
  .grid--ms-nowrap {
    flex-wrap: nowrap;
  }
  .grid--ms-wrap {
    flex-wrap: wrap;
  }
  .grid--ms-column {
    flex-direction: column;
  }
  .grid--ms-row {
    flex-direction: row;
  }
  .grid--ms-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--ms-start {
    align-self: flex-start;
  }
  .grid > .gcell--ms-end {
    align-self: flex-end;
  }
  .grid > .gcell--ms-center {
    align-self: center;
  }
  .grid > .gcell--ms-stretch {
    align-self: stretch;
  }
  .grid > .gcell--ms-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--ms-grow {
    flex-grow: 1;
  }
  .grid > .gcell--ms-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--ms-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--ms-order-start {
    order: -1;
  }
  .grid > .gcell--ms-order-end {
    order: 1;
  }
  .grid > .gcell--ms-noorder {
    order: 0;
  }
  .grid > .gcell--ms-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 768px) {
  .grid--md-items-start {
    align-items: flex-start;
  }
  .grid--md-items-end {
    align-items: flex-end;
  }
  .grid--md-items-center {
    align-items: center;
  }
  .grid--md-items-stretch {
    align-items: stretch;
  }
  .grid--md-justify-start {
    justify-content: flex-start;
  }
  .grid--md-justify-end {
    justify-content: flex-end;
  }
  .grid--md-justify-center {
    justify-content: center;
  }
  .grid--md-justify-between {
    justify-content: space-between;
  }
  .grid--md-justify-around {
    justify-content: space-around;
  }
  .grid--md-nowrap {
    flex-wrap: nowrap;
  }
  .grid--md-wrap {
    flex-wrap: wrap;
  }
  .grid--md-column {
    flex-direction: column;
  }
  .grid--md-row {
    flex-direction: row;
  }
  .grid--md-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--md-start {
    align-self: flex-start;
  }
  .grid > .gcell--md-end {
    align-self: flex-end;
  }
  .grid > .gcell--md-center {
    align-self: center;
  }
  .grid > .gcell--md-stretch {
    align-self: stretch;
  }
  .grid > .gcell--md-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--md-grow {
    flex-grow: 1;
  }
  .grid > .gcell--md-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--md-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--md-order-start {
    order: -1;
  }
  .grid > .gcell--md-order-end {
    order: 1;
  }
  .grid > .gcell--md-noorder {
    order: 0;
  }
  .grid > .gcell--md-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .grid--defp-items-start {
    align-items: flex-start;
  }
  .grid--defp-items-end {
    align-items: flex-end;
  }
  .grid--defp-items-center {
    align-items: center;
  }
  .grid--defp-items-stretch {
    align-items: stretch;
  }
  .grid--defp-justify-start {
    justify-content: flex-start;
  }
  .grid--defp-justify-end {
    justify-content: flex-end;
  }
  .grid--defp-justify-center {
    justify-content: center;
  }
  .grid--defp-justify-between {
    justify-content: space-between;
  }
  .grid--defp-justify-around {
    justify-content: space-around;
  }
  .grid--defp-nowrap {
    flex-wrap: nowrap;
  }
  .grid--defp-wrap {
    flex-wrap: wrap;
  }
  .grid--defp-column {
    flex-direction: column;
  }
  .grid--defp-row {
    flex-direction: row;
  }
  .grid--defp-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--defp-start {
    align-self: flex-start;
  }
  .grid > .gcell--defp-end {
    align-self: flex-end;
  }
  .grid > .gcell--defp-center {
    align-self: center;
  }
  .grid > .gcell--defp-stretch {
    align-self: stretch;
  }
  .grid > .gcell--defp-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--defp-grow {
    flex-grow: 1;
  }
  .grid > .gcell--defp-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--defp-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--defp-order-start {
    order: -1;
  }
  .grid > .gcell--defp-order-end {
    order: 1;
  }
  .grid > .gcell--defp-noorder {
    order: 0;
  }
  .grid > .gcell--defp-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 1025px) {
  .grid--def-items-start {
    align-items: flex-start;
  }
  .grid--def-items-end {
    align-items: flex-end;
  }
  .grid--def-items-center {
    align-items: center;
  }
  .grid--def-items-stretch {
    align-items: stretch;
  }
  .grid--def-justify-start {
    justify-content: flex-start;
  }
  .grid--def-justify-end {
    justify-content: flex-end;
  }
  .grid--def-justify-center {
    justify-content: center;
  }
  .grid--def-justify-between {
    justify-content: space-between;
  }
  .grid--def-justify-around {
    justify-content: space-around;
  }
  .grid--def-nowrap {
    flex-wrap: nowrap;
  }
  .grid--def-wrap {
    flex-wrap: wrap;
  }
  .grid--def-column {
    flex-direction: column;
  }
  .grid--def-row {
    flex-direction: row;
  }
  .grid--def-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--def-start {
    align-self: flex-start;
  }
  .grid > .gcell--def-end {
    align-self: flex-end;
  }
  .grid > .gcell--def-center {
    align-self: center;
  }
  .grid > .gcell--def-stretch {
    align-self: stretch;
  }
  .grid > .gcell--def-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--def-grow {
    flex-grow: 1;
  }
  .grid > .gcell--def-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--def-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--def-order-start {
    order: -1;
  }
  .grid > .gcell--def-order-end {
    order: 1;
  }
  .grid > .gcell--def-noorder {
    order: 0;
  }
  .grid > .gcell--def-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 1280px) {
  .grid--lg-items-start {
    align-items: flex-start;
  }
  .grid--lg-items-end {
    align-items: flex-end;
  }
  .grid--lg-items-center {
    align-items: center;
  }
  .grid--lg-items-stretch {
    align-items: stretch;
  }
  .grid--lg-justify-start {
    justify-content: flex-start;
  }
  .grid--lg-justify-end {
    justify-content: flex-end;
  }
  .grid--lg-justify-center {
    justify-content: center;
  }
  .grid--lg-justify-between {
    justify-content: space-between;
  }
  .grid--lg-justify-around {
    justify-content: space-around;
  }
  .grid--lg-nowrap {
    flex-wrap: nowrap;
  }
  .grid--lg-wrap {
    flex-wrap: wrap;
  }
  .grid--lg-column {
    flex-direction: column;
  }
  .grid--lg-row {
    flex-direction: row;
  }
  .grid--lg-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--lg-start {
    align-self: flex-start;
  }
  .grid > .gcell--lg-end {
    align-self: flex-end;
  }
  .grid > .gcell--lg-center {
    align-self: center;
  }
  .grid > .gcell--lg-stretch {
    align-self: stretch;
  }
  .grid > .gcell--lg-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--lg-grow {
    flex-grow: 1;
  }
  .grid > .gcell--lg-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--lg-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--lg-order-start {
    order: -1;
  }
  .grid > .gcell--lg-order-end {
    order: 1;
  }
  .grid > .gcell--lg-noorder {
    order: 0;
  }
  .grid > .gcell--lg-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .grid--xl-items-start {
    align-items: flex-start;
  }
  .grid--xl-items-end {
    align-items: flex-end;
  }
  .grid--xl-items-center {
    align-items: center;
  }
  .grid--xl-items-stretch {
    align-items: stretch;
  }
  .grid--xl-justify-start {
    justify-content: flex-start;
  }
  .grid--xl-justify-end {
    justify-content: flex-end;
  }
  .grid--xl-justify-center {
    justify-content: center;
  }
  .grid--xl-justify-between {
    justify-content: space-between;
  }
  .grid--xl-justify-around {
    justify-content: space-around;
  }
  .grid--xl-nowrap {
    flex-wrap: nowrap;
  }
  .grid--xl-wrap {
    flex-wrap: wrap;
  }
  .grid--xl-column {
    flex-direction: column;
  }
  .grid--xl-row {
    flex-direction: row;
  }
  .grid--xl-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--xl-start {
    align-self: flex-start;
  }
  .grid > .gcell--xl-end {
    align-self: flex-end;
  }
  .grid > .gcell--xl-center {
    align-self: center;
  }
  .grid > .gcell--xl-stretch {
    align-self: stretch;
  }
  .grid > .gcell--xl-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--xl-grow {
    flex-grow: 1;
  }
  .grid > .gcell--xl-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--xl-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--xl-order-start {
    order: -1;
  }
  .grid > .gcell--xl-order-end {
    order: 1;
  }
  .grid > .gcell--xl-noorder {
    order: 0;
  }
  .grid > .gcell--xl-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 1540px) {
  .grid--mg-items-start {
    align-items: flex-start;
  }
  .grid--mg-items-end {
    align-items: flex-end;
  }
  .grid--mg-items-center {
    align-items: center;
  }
  .grid--mg-items-stretch {
    align-items: stretch;
  }
  .grid--mg-justify-start {
    justify-content: flex-start;
  }
  .grid--mg-justify-end {
    justify-content: flex-end;
  }
  .grid--mg-justify-center {
    justify-content: center;
  }
  .grid--mg-justify-between {
    justify-content: space-between;
  }
  .grid--mg-justify-around {
    justify-content: space-around;
  }
  .grid--mg-nowrap {
    flex-wrap: nowrap;
  }
  .grid--mg-wrap {
    flex-wrap: wrap;
  }
  .grid--mg-column {
    flex-direction: column;
  }
  .grid--mg-row {
    flex-direction: row;
  }
  .grid--mg-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--mg-start {
    align-self: flex-start;
  }
  .grid > .gcell--mg-end {
    align-self: flex-end;
  }
  .grid > .gcell--mg-center {
    align-self: center;
  }
  .grid > .gcell--mg-stretch {
    align-self: stretch;
  }
  .grid > .gcell--mg-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--mg-grow {
    flex-grow: 1;
  }
  .grid > .gcell--mg-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--mg-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--mg-order-start {
    order: -1;
  }
  .grid > .gcell--mg-order-end {
    order: 1;
  }
  .grid > .gcell--mg-noorder {
    order: 0;
  }
  .grid > .gcell--mg-nobasis {
    flex-basis: 0;
  }
}

.grid > .gcell--parser-half {
  width: 360px;
  max-width: 360px;
  flex-basis: 360px;
}

@media only screen and (min-width: 375px) {
  .grid--xs-space-sm {
    margin-left: -1.25px;
    margin-right: -1.25px;
  }
  .grid--xs-space-sm > .gcell {
    padding-left: 1.25px;
    padding-right: 1.25px;
  }
  .grid--xs-space-md {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--xs-space-md > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--xs-space-def {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--xs-space-def > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--xs-space-lg {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .grid--xs-space-lg > .gcell {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .grid--xs-space-xl {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--xs-space-xl > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--xs-space-mg {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--xs-space-mg > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--xs-nospace {
    margin: 0;
  }
  .grid--xs-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 480px) {
  .grid--sm-space-sm {
    margin-left: -1.25px;
    margin-right: -1.25px;
  }
  .grid--sm-space-sm > .gcell {
    padding-left: 1.25px;
    padding-right: 1.25px;
  }
  .grid--sm-space-md {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--sm-space-md > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--sm-space-def {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--sm-space-def > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--sm-space-lg {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .grid--sm-space-lg > .gcell {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .grid--sm-space-xl {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--sm-space-xl > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--sm-space-mg {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--sm-space-mg > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--sm-nospace {
    margin: 0;
  }
  .grid--sm-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 640px) {
  .grid--ms-space-sm {
    margin-left: -1.25px;
    margin-right: -1.25px;
  }
  .grid--ms-space-sm > .gcell {
    padding-left: 1.25px;
    padding-right: 1.25px;
  }
  .grid--ms-space-md {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--ms-space-md > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--ms-space-def {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--ms-space-def > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--ms-space-lg {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .grid--ms-space-lg > .gcell {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .grid--ms-space-xl {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--ms-space-xl > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--ms-space-mg {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--ms-space-mg > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--ms-nospace {
    margin: 0;
  }
  .grid--ms-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) {
  .grid--md-space-sm {
    margin-left: -1.25px;
    margin-right: -1.25px;
  }
  .grid--md-space-sm > .gcell {
    padding-left: 1.25px;
    padding-right: 1.25px;
  }
  .grid--md-space-md {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--md-space-md > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--md-space-def {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--md-space-def > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--md-space-lg {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .grid--md-space-lg > .gcell {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .grid--md-space-xl {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--md-space-xl > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--md-space-mg {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--md-space-mg > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--md-nospace {
    margin: 0;
  }
  .grid--md-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .grid--defp-space-sm {
    margin-left: -1.25px;
    margin-right: -1.25px;
  }
  .grid--defp-space-sm > .gcell {
    padding-left: 1.25px;
    padding-right: 1.25px;
  }
  .grid--defp-space-md {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--defp-space-md > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--defp-space-def {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--defp-space-def > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--defp-space-lg {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .grid--defp-space-lg > .gcell {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .grid--defp-space-xl {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--defp-space-xl > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--defp-space-mg {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--defp-space-mg > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--defp-nospace {
    margin: 0;
  }
  .grid--defp-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 1025px) {
  .grid--def-space-sm {
    margin-left: -1.25px;
    margin-right: -1.25px;
  }
  .grid--def-space-sm > .gcell {
    padding-left: 1.25px;
    padding-right: 1.25px;
  }
  .grid--def-space-md {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--def-space-md > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--def-space-def {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--def-space-def > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--def-space-lg {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .grid--def-space-lg > .gcell {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .grid--def-space-xl {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--def-space-xl > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--def-space-mg {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--def-space-mg > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--def-nospace {
    margin: 0;
  }
  .grid--def-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 1280px) {
  .grid--lg-space-sm {
    margin-left: -1.25px;
    margin-right: -1.25px;
  }
  .grid--lg-space-sm > .gcell {
    padding-left: 1.25px;
    padding-right: 1.25px;
  }
  .grid--lg-space-md {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--lg-space-md > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--lg-space-def {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--lg-space-def > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--lg-space-lg {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .grid--lg-space-lg > .gcell {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .grid--lg-space-xl {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--lg-space-xl > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--lg-space-mg {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--lg-space-mg > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--lg-nospace {
    margin: 0;
  }
  .grid--lg-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .grid--xl-space-sm {
    margin-left: -1.25px;
    margin-right: -1.25px;
  }
  .grid--xl-space-sm > .gcell {
    padding-left: 1.25px;
    padding-right: 1.25px;
  }
  .grid--xl-space-md {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--xl-space-md > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--xl-space-def {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--xl-space-def > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--xl-space-lg {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .grid--xl-space-lg > .gcell {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .grid--xl-space-xl {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--xl-space-xl > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--xl-space-mg {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--xl-space-mg > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--xl-nospace {
    margin: 0;
  }
  .grid--xl-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 1540px) {
  .grid--mg-space-sm {
    margin-left: -1.25px;
    margin-right: -1.25px;
  }
  .grid--mg-space-sm > .gcell {
    padding-left: 1.25px;
    padding-right: 1.25px;
  }
  .grid--mg-space-md {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--mg-space-md > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--mg-space-def {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--mg-space-def > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--mg-space-lg {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .grid--mg-space-lg > .gcell {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .grid--mg-space-xl {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--mg-space-xl > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--mg-space-mg {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--mg-space-mg > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--mg-nospace {
    margin: 0;
  }
  .grid--mg-nospace > .gcell {
    padding: 0;
  }
}

._color-black {
  color: #000;
}

._color-gray90 {
  color: #1a1a1a;
}

._color-gray75 {
  color: #404040;
}

._color-gray50 {
  color: #807f7f;
}

._color-gray25 {
  color: #bfbfbf;
}

._color-gray10 {
  color: #e6e6e6;
}

._color-white {
  color: #fff;
}

._color-link {
  color: #43f;
}

._color-primary {
  color: #8074ff;
}

._color-secondary {
  color: #e652a6;
}

._color-success {
  color: #c1af7a;
}

._color-info {
  color: #00b3fe;
}

._color-warning {
  color: #c89600;
}

._color-danger {
  color: #ff6262;
}

._bgcolor-black {
  background-color: #000;
}

._bgcolor-gray90 {
  background-color: #1a1a1a;
}

._bgcolor-gray75 {
  background-color: #404040;
}

._bgcolor-gray50 {
  background-color: #807f7f;
}

._bgcolor-gray25 {
  background-color: #bfbfbf;
}

._bgcolor-gray10 {
  background-color: #e6e6e6;
}

._bgcolor-white {
  background-color: #fff;
}

._bgcolor-link {
  background-color: #43f;
}

._bgcolor-primary {
  background-color: #8074ff;
}

._bgcolor-secondary {
  background-color: #e652a6;
}

._bgcolor-success {
  background-color: #c1af7a;
}

._bgcolor-info {
  background-color: #00b3fe;
}

._bgcolor-warning {
  background-color: #c89600;
}

._bgcolor-danger {
  background-color: #ff6262;
}

._fill-black {
  fill: #000;
}

._fill-gray90 {
  fill: #1a1a1a;
}

._fill-gray75 {
  fill: #404040;
}

._fill-gray50 {
  fill: #807f7f;
}

._fill-gray25 {
  fill: #bfbfbf;
}

._fill-gray10 {
  fill: #e6e6e6;
}

._fill-white {
  fill: #fff;
}

._fill-link {
  fill: #43f;
}

._fill-primary {
  fill: #8074ff;
}

._fill-secondary {
  fill: #e652a6;
}

._fill-success {
  fill: #c1af7a;
}

._fill-info {
  fill: #00b3fe;
}

._fill-warning {
  fill: #c89600;
}

._fill-danger {
  fill: #ff6262;
}

._left {
  float: left;
}

._right {
  float: right;
}

._overflow {
  overflow: hidden;
}

._clear {
  clear: both;
}

._clear-after:after {
  content: '';
  clear: both;
  display: block;
  height: 0;
}

@media only screen and (min-width: 375px) {
  ._xs-left {
    float: left;
  }
  ._xs-right {
    float: right;
  }
  ._xs-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 480px) {
  ._sm-left {
    float: left;
  }
  ._sm-right {
    float: right;
  }
  ._sm-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 640px) {
  ._ms-left {
    float: left;
  }
  ._ms-right {
    float: right;
  }
  ._ms-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 768px) {
  ._md-left {
    float: left;
  }
  ._md-right {
    float: right;
  }
  ._md-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 1024px) {
  ._defp-left {
    float: left;
  }
  ._defp-right {
    float: right;
  }
  ._defp-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 1025px) {
  ._def-left {
    float: left;
  }
  ._def-right {
    float: right;
  }
  ._def-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 1280px) {
  ._lg-left {
    float: left;
  }
  ._lg-right {
    float: right;
  }
  ._lg-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 1440px) {
  ._xl-left {
    float: left;
  }
  ._xl-right {
    float: right;
  }
  ._xl-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 1540px) {
  ._mg-left {
    float: left;
  }
  ._mg-right {
    float: right;
  }
  ._mg-overflow {
    overflow: hidden;
  }
}

._text-left {
  text-align: left;
}

._text-right {
  text-align: right;
}

._text-center {
  text-align: center;
}

._ellipsis {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 375px) {
  ._xs-text-left {
    text-align: left;
  }
  ._xs-text-right {
    text-align: right;
  }
  ._xs-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 480px) {
  ._sm-text-left {
    text-align: left;
  }
  ._sm-text-right {
    text-align: right;
  }
  ._sm-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 640px) {
  ._ms-text-left {
    text-align: left;
  }
  ._ms-text-right {
    text-align: right;
  }
  ._ms-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  ._md-text-left {
    text-align: left;
  }
  ._md-text-right {
    text-align: right;
  }
  ._md-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 1024px) {
  ._defp-text-left {
    text-align: left;
  }
  ._defp-text-right {
    text-align: right;
  }
  ._defp-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 1025px) {
  ._def-text-left {
    text-align: left;
  }
  ._def-text-right {
    text-align: right;
  }
  ._def-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 1280px) {
  ._lg-text-left {
    text-align: left;
  }
  ._lg-text-right {
    text-align: right;
  }
  ._lg-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 1440px) {
  ._xl-text-left {
    text-align: left;
  }
  ._xl-text-right {
    text-align: right;
  }
  ._xl-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 1540px) {
  ._mg-text-left {
    text-align: left;
  }
  ._mg-text-right {
    text-align: right;
  }
  ._mg-text-center {
    text-align: center;
  }
}

._hide {
  display: none;
}

._hide\! {
  display: none !important;
}

@media print {
  ._noprint {
    display: none !important;
  }
}

@media only screen and (min-width: 375px) {
  ._xs-hide {
    display: none;
  }
  ._xs-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 374px) {
  ._xs-show {
    display: none;
  }
  ._xs-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 480px) {
  ._sm-hide {
    display: none;
  }
  ._sm-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 479px) {
  ._sm-show {
    display: none;
  }
  ._sm-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 640px) {
  ._ms-hide {
    display: none;
  }
  ._ms-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 639px) {
  ._ms-show {
    display: none;
  }
  ._ms-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  ._md-hide {
    display: none;
  }
  ._md-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  ._md-show {
    display: none;
  }
  ._md-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) {
  ._defp-hide {
    display: none;
  }
  ._defp-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1023px) {
  ._defp-show {
    display: none;
  }
  ._defp-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1025px) {
  ._def-hide {
    display: none;
  }
  ._def-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1024px) {
  ._def-show {
    display: none;
  }
  ._def-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1280px) {
  ._lg-hide {
    display: none;
  }
  ._lg-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1279px) {
  ._lg-show {
    display: none;
  }
  ._lg-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1440px) {
  ._xl-hide {
    display: none;
  }
  ._xl-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1439px) {
  ._xl-show {
    display: none;
  }
  ._xl-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1540px) {
  ._mg-hide {
    display: none;
  }
  ._mg-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1539px) {
  ._mg-show {
    display: none;
  }
  ._mg-show\! {
    display: none !important;
  }
}

._m-sm {
  margin: 0.2083333rem !important;
}

._m-md {
  margin: 0.4166667rem !important;
}

._m-def {
  margin: 0.8333333rem !important;
}

._m-lg {
  margin: 1.25rem !important;
}

._m-xl {
  margin: 1.6666667rem !important;
}

._m-mg {
  margin: 3.3333333rem !important;
}

._mt-sm {
  margin-top: 0.2083333rem !important;
}

._mt-md {
  margin-top: 0.4166667rem !important;
}

._mt-def {
  margin-top: 0.8333333rem !important;
}

._mt-lg {
  margin-top: 1.25rem !important;
}

._mt-xl {
  margin-top: 1.6666667rem !important;
}

._mt-mg {
  margin-top: 3.3333333rem !important;
}

._mt-none {
  margin-top: 0 !important;
}

._mr-sm {
  margin-right: 0.2083333rem !important;
}

._mr-md {
  margin-right: 0.4166667rem !important;
}

._mr-def {
  margin-right: 0.8333333rem !important;
}

._mr-lg {
  margin-right: 1.25rem !important;
}

._mr-xl {
  margin-right: 1.6666667rem !important;
}

._mr-mg {
  margin-right: 3.3333333rem !important;
}

._mr-none {
  margin-right: 0 !important;
}

._mb-sm {
  margin-bottom: 0.2083333rem !important;
}

._mb-md {
  margin-bottom: 0.4166667rem !important;
}

._mb-def {
  margin-bottom: 0.8333333rem !important;
}

._mb-lg {
  margin-bottom: 1.25rem !important;
}

._mb-xl {
  margin-bottom: 1.6666667rem !important;
}

._mb-mg {
  margin-bottom: 3.3333333rem !important;
}

._mb-none {
  margin-bottom: 0 !important;
}

._ml-sm {
  margin-left: 0.2083333rem !important;
}

._ml-md {
  margin-left: 0.4166667rem !important;
}

._ml-def {
  margin-left: 0.8333333rem !important;
}

._ml-lg {
  margin-left: 1.25rem !important;
}

._ml-xl {
  margin-left: 1.6666667rem !important;
}

._ml-mg {
  margin-left: 3.3333333rem !important;
}

._ml-none {
  margin-left: 0 !important;
}

._m-none {
  margin: 0 !important;
}

._p-sm {
  padding: 0.2083333rem !important;
}

._p-md {
  padding: 0.4166667rem !important;
}

._p-def {
  padding: 0.8333333rem !important;
}

._p-lg {
  padding: 1.25rem !important;
}

._p-xl {
  padding: 1.6666667rem !important;
}

._p-mg {
  padding: 3.3333333rem !important;
}

._pt-sm {
  padding-top: 0.2083333rem !important;
}

._pt-md {
  padding-top: 0.4166667rem !important;
}

._pt-def {
  padding-top: 0.8333333rem !important;
}

._pt-lg {
  padding-top: 1.25rem !important;
}

._pt-xl {
  padding-top: 1.6666667rem !important;
}

._pt-mg {
  padding-top: 3.3333333rem !important;
}

._pt-none {
  padding-top: 0 !important;
}

._pr-sm {
  padding-right: 0.2083333rem !important;
}

._pr-md {
  padding-right: 0.4166667rem !important;
}

._pr-def {
  padding-right: 0.8333333rem !important;
}

._pr-lg {
  padding-right: 1.25rem !important;
}

._pr-xl {
  padding-right: 1.6666667rem !important;
}

._pr-mg {
  padding-right: 3.3333333rem !important;
}

._pr-none {
  padding-right: 0 !important;
}

._pb-sm {
  padding-bottom: 0.2083333rem !important;
}

._pb-md {
  padding-bottom: 0.4166667rem !important;
}

._pb-def {
  padding-bottom: 0.8333333rem !important;
}

._pb-lg {
  padding-bottom: 1.25rem !important;
}

._pb-xl {
  padding-bottom: 1.6666667rem !important;
}

._pb-mg {
  padding-bottom: 3.3333333rem !important;
}

._pb-none {
  padding-bottom: 0 !important;
}

._pl-sm {
  padding-left: 0.2083333rem !important;
}

._pl-md {
  padding-left: 0.4166667rem !important;
}

._pl-def {
  padding-left: 0.8333333rem !important;
}

._pl-lg {
  padding-left: 1.25rem !important;
}

._pl-xl {
  padding-left: 1.6666667rem !important;
}

._pl-mg {
  padding-left: 3.3333333rem !important;
}

._pl-none {
  padding-left: 0 !important;
}

._p-none {
  padding: 0 !important;
}

._m-auto {
  margin: auto !important;
}

._ml-auto {
  margin-left: auto !important;
}

._mr-auto {
  margin-right: auto !important;
}

@media only screen and (min-width: 375px) {
  ._xs-m-sm {
    margin: 0.2083333rem !important;
  }
  ._xs-m-md {
    margin: 0.4166667rem !important;
  }
  ._xs-m-def {
    margin: 0.8333333rem !important;
  }
  ._xs-m-lg {
    margin: 1.25rem !important;
  }
  ._xs-m-xl {
    margin: 1.6666667rem !important;
  }
  ._xs-m-mg {
    margin: 3.3333333rem !important;
  }
  ._xs-mt-sm {
    margin-top: 0.2083333rem !important;
  }
  ._xs-mt-md {
    margin-top: 0.4166667rem !important;
  }
  ._xs-mt-def {
    margin-top: 0.8333333rem !important;
  }
  ._xs-mt-lg {
    margin-top: 1.25rem !important;
  }
  ._xs-mt-xl {
    margin-top: 1.6666667rem !important;
  }
  ._xs-mt-mg {
    margin-top: 3.3333333rem !important;
  }
  ._xs-mt-none {
    margin-top: 0 !important;
  }
  ._xs-mr-sm {
    margin-right: 0.2083333rem !important;
  }
  ._xs-mr-md {
    margin-right: 0.4166667rem !important;
  }
  ._xs-mr-def {
    margin-right: 0.8333333rem !important;
  }
  ._xs-mr-lg {
    margin-right: 1.25rem !important;
  }
  ._xs-mr-xl {
    margin-right: 1.6666667rem !important;
  }
  ._xs-mr-mg {
    margin-right: 3.3333333rem !important;
  }
  ._xs-mr-none {
    margin-right: 0 !important;
  }
  ._xs-mb-sm {
    margin-bottom: 0.2083333rem !important;
  }
  ._xs-mb-md {
    margin-bottom: 0.4166667rem !important;
  }
  ._xs-mb-def {
    margin-bottom: 0.8333333rem !important;
  }
  ._xs-mb-lg {
    margin-bottom: 1.25rem !important;
  }
  ._xs-mb-xl {
    margin-bottom: 1.6666667rem !important;
  }
  ._xs-mb-mg {
    margin-bottom: 3.3333333rem !important;
  }
  ._xs-mb-none {
    margin-bottom: 0 !important;
  }
  ._xs-ml-sm {
    margin-left: 0.2083333rem !important;
  }
  ._xs-ml-md {
    margin-left: 0.4166667rem !important;
  }
  ._xs-ml-def {
    margin-left: 0.8333333rem !important;
  }
  ._xs-ml-lg {
    margin-left: 1.25rem !important;
  }
  ._xs-ml-xl {
    margin-left: 1.6666667rem !important;
  }
  ._xs-ml-mg {
    margin-left: 3.3333333rem !important;
  }
  ._xs-ml-none {
    margin-left: 0 !important;
  }
  ._xs-m-none {
    margin: 0 !important;
  }
  ._xs-p-sm {
    padding: 0.2083333rem !important;
  }
  ._xs-p-md {
    padding: 0.4166667rem !important;
  }
  ._xs-p-def {
    padding: 0.8333333rem !important;
  }
  ._xs-p-lg {
    padding: 1.25rem !important;
  }
  ._xs-p-xl {
    padding: 1.6666667rem !important;
  }
  ._xs-p-mg {
    padding: 3.3333333rem !important;
  }
  ._xs-pt-sm {
    padding-top: 0.2083333rem !important;
  }
  ._xs-pt-md {
    padding-top: 0.4166667rem !important;
  }
  ._xs-pt-def {
    padding-top: 0.8333333rem !important;
  }
  ._xs-pt-lg {
    padding-top: 1.25rem !important;
  }
  ._xs-pt-xl {
    padding-top: 1.6666667rem !important;
  }
  ._xs-pt-mg {
    padding-top: 3.3333333rem !important;
  }
  ._xs-pt-none {
    padding-top: 0 !important;
  }
  ._xs-pr-sm {
    padding-right: 0.2083333rem !important;
  }
  ._xs-pr-md {
    padding-right: 0.4166667rem !important;
  }
  ._xs-pr-def {
    padding-right: 0.8333333rem !important;
  }
  ._xs-pr-lg {
    padding-right: 1.25rem !important;
  }
  ._xs-pr-xl {
    padding-right: 1.6666667rem !important;
  }
  ._xs-pr-mg {
    padding-right: 3.3333333rem !important;
  }
  ._xs-pr-none {
    padding-right: 0 !important;
  }
  ._xs-pb-sm {
    padding-bottom: 0.2083333rem !important;
  }
  ._xs-pb-md {
    padding-bottom: 0.4166667rem !important;
  }
  ._xs-pb-def {
    padding-bottom: 0.8333333rem !important;
  }
  ._xs-pb-lg {
    padding-bottom: 1.25rem !important;
  }
  ._xs-pb-xl {
    padding-bottom: 1.6666667rem !important;
  }
  ._xs-pb-mg {
    padding-bottom: 3.3333333rem !important;
  }
  ._xs-pb-none {
    padding-bottom: 0 !important;
  }
  ._xs-pl-sm {
    padding-left: 0.2083333rem !important;
  }
  ._xs-pl-md {
    padding-left: 0.4166667rem !important;
  }
  ._xs-pl-def {
    padding-left: 0.8333333rem !important;
  }
  ._xs-pl-lg {
    padding-left: 1.25rem !important;
  }
  ._xs-pl-xl {
    padding-left: 1.6666667rem !important;
  }
  ._xs-pl-mg {
    padding-left: 3.3333333rem !important;
  }
  ._xs-pl-none {
    padding-left: 0 !important;
  }
  ._xs-p-none {
    padding: 0 !important;
  }
  ._xs-m-auto {
    margin: auto !important;
  }
  ._xs-ml-auto {
    margin-left: auto !important;
  }
  ._xs-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 480px) {
  ._sm-m-sm {
    margin: 0.2083333rem !important;
  }
  ._sm-m-md {
    margin: 0.4166667rem !important;
  }
  ._sm-m-def {
    margin: 0.8333333rem !important;
  }
  ._sm-m-lg {
    margin: 1.25rem !important;
  }
  ._sm-m-xl {
    margin: 1.6666667rem !important;
  }
  ._sm-m-mg {
    margin: 3.3333333rem !important;
  }
  ._sm-mt-sm {
    margin-top: 0.2083333rem !important;
  }
  ._sm-mt-md {
    margin-top: 0.4166667rem !important;
  }
  ._sm-mt-def {
    margin-top: 0.8333333rem !important;
  }
  ._sm-mt-lg {
    margin-top: 1.25rem !important;
  }
  ._sm-mt-xl {
    margin-top: 1.6666667rem !important;
  }
  ._sm-mt-mg {
    margin-top: 3.3333333rem !important;
  }
  ._sm-mt-none {
    margin-top: 0 !important;
  }
  ._sm-mr-sm {
    margin-right: 0.2083333rem !important;
  }
  ._sm-mr-md {
    margin-right: 0.4166667rem !important;
  }
  ._sm-mr-def {
    margin-right: 0.8333333rem !important;
  }
  ._sm-mr-lg {
    margin-right: 1.25rem !important;
  }
  ._sm-mr-xl {
    margin-right: 1.6666667rem !important;
  }
  ._sm-mr-mg {
    margin-right: 3.3333333rem !important;
  }
  ._sm-mr-none {
    margin-right: 0 !important;
  }
  ._sm-mb-sm {
    margin-bottom: 0.2083333rem !important;
  }
  ._sm-mb-md {
    margin-bottom: 0.4166667rem !important;
  }
  ._sm-mb-def {
    margin-bottom: 0.8333333rem !important;
  }
  ._sm-mb-lg {
    margin-bottom: 1.25rem !important;
  }
  ._sm-mb-xl {
    margin-bottom: 1.6666667rem !important;
  }
  ._sm-mb-mg {
    margin-bottom: 3.3333333rem !important;
  }
  ._sm-mb-none {
    margin-bottom: 0 !important;
  }
  ._sm-ml-sm {
    margin-left: 0.2083333rem !important;
  }
  ._sm-ml-md {
    margin-left: 0.4166667rem !important;
  }
  ._sm-ml-def {
    margin-left: 0.8333333rem !important;
  }
  ._sm-ml-lg {
    margin-left: 1.25rem !important;
  }
  ._sm-ml-xl {
    margin-left: 1.6666667rem !important;
  }
  ._sm-ml-mg {
    margin-left: 3.3333333rem !important;
  }
  ._sm-ml-none {
    margin-left: 0 !important;
  }
  ._sm-m-none {
    margin: 0 !important;
  }
  ._sm-p-sm {
    padding: 0.2083333rem !important;
  }
  ._sm-p-md {
    padding: 0.4166667rem !important;
  }
  ._sm-p-def {
    padding: 0.8333333rem !important;
  }
  ._sm-p-lg {
    padding: 1.25rem !important;
  }
  ._sm-p-xl {
    padding: 1.6666667rem !important;
  }
  ._sm-p-mg {
    padding: 3.3333333rem !important;
  }
  ._sm-pt-sm {
    padding-top: 0.2083333rem !important;
  }
  ._sm-pt-md {
    padding-top: 0.4166667rem !important;
  }
  ._sm-pt-def {
    padding-top: 0.8333333rem !important;
  }
  ._sm-pt-lg {
    padding-top: 1.25rem !important;
  }
  ._sm-pt-xl {
    padding-top: 1.6666667rem !important;
  }
  ._sm-pt-mg {
    padding-top: 3.3333333rem !important;
  }
  ._sm-pt-none {
    padding-top: 0 !important;
  }
  ._sm-pr-sm {
    padding-right: 0.2083333rem !important;
  }
  ._sm-pr-md {
    padding-right: 0.4166667rem !important;
  }
  ._sm-pr-def {
    padding-right: 0.8333333rem !important;
  }
  ._sm-pr-lg {
    padding-right: 1.25rem !important;
  }
  ._sm-pr-xl {
    padding-right: 1.6666667rem !important;
  }
  ._sm-pr-mg {
    padding-right: 3.3333333rem !important;
  }
  ._sm-pr-none {
    padding-right: 0 !important;
  }
  ._sm-pb-sm {
    padding-bottom: 0.2083333rem !important;
  }
  ._sm-pb-md {
    padding-bottom: 0.4166667rem !important;
  }
  ._sm-pb-def {
    padding-bottom: 0.8333333rem !important;
  }
  ._sm-pb-lg {
    padding-bottom: 1.25rem !important;
  }
  ._sm-pb-xl {
    padding-bottom: 1.6666667rem !important;
  }
  ._sm-pb-mg {
    padding-bottom: 3.3333333rem !important;
  }
  ._sm-pb-none {
    padding-bottom: 0 !important;
  }
  ._sm-pl-sm {
    padding-left: 0.2083333rem !important;
  }
  ._sm-pl-md {
    padding-left: 0.4166667rem !important;
  }
  ._sm-pl-def {
    padding-left: 0.8333333rem !important;
  }
  ._sm-pl-lg {
    padding-left: 1.25rem !important;
  }
  ._sm-pl-xl {
    padding-left: 1.6666667rem !important;
  }
  ._sm-pl-mg {
    padding-left: 3.3333333rem !important;
  }
  ._sm-pl-none {
    padding-left: 0 !important;
  }
  ._sm-p-none {
    padding: 0 !important;
  }
  ._sm-m-auto {
    margin: auto !important;
  }
  ._sm-ml-auto {
    margin-left: auto !important;
  }
  ._sm-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 640px) {
  ._ms-m-sm {
    margin: 0.2083333rem !important;
  }
  ._ms-m-md {
    margin: 0.4166667rem !important;
  }
  ._ms-m-def {
    margin: 0.8333333rem !important;
  }
  ._ms-m-lg {
    margin: 1.25rem !important;
  }
  ._ms-m-xl {
    margin: 1.6666667rem !important;
  }
  ._ms-m-mg {
    margin: 3.3333333rem !important;
  }
  ._ms-mt-sm {
    margin-top: 0.2083333rem !important;
  }
  ._ms-mt-md {
    margin-top: 0.4166667rem !important;
  }
  ._ms-mt-def {
    margin-top: 0.8333333rem !important;
  }
  ._ms-mt-lg {
    margin-top: 1.25rem !important;
  }
  ._ms-mt-xl {
    margin-top: 1.6666667rem !important;
  }
  ._ms-mt-mg {
    margin-top: 3.3333333rem !important;
  }
  ._ms-mt-none {
    margin-top: 0 !important;
  }
  ._ms-mr-sm {
    margin-right: 0.2083333rem !important;
  }
  ._ms-mr-md {
    margin-right: 0.4166667rem !important;
  }
  ._ms-mr-def {
    margin-right: 0.8333333rem !important;
  }
  ._ms-mr-lg {
    margin-right: 1.25rem !important;
  }
  ._ms-mr-xl {
    margin-right: 1.6666667rem !important;
  }
  ._ms-mr-mg {
    margin-right: 3.3333333rem !important;
  }
  ._ms-mr-none {
    margin-right: 0 !important;
  }
  ._ms-mb-sm {
    margin-bottom: 0.2083333rem !important;
  }
  ._ms-mb-md {
    margin-bottom: 0.4166667rem !important;
  }
  ._ms-mb-def {
    margin-bottom: 0.8333333rem !important;
  }
  ._ms-mb-lg {
    margin-bottom: 1.25rem !important;
  }
  ._ms-mb-xl {
    margin-bottom: 1.6666667rem !important;
  }
  ._ms-mb-mg {
    margin-bottom: 3.3333333rem !important;
  }
  ._ms-mb-none {
    margin-bottom: 0 !important;
  }
  ._ms-ml-sm {
    margin-left: 0.2083333rem !important;
  }
  ._ms-ml-md {
    margin-left: 0.4166667rem !important;
  }
  ._ms-ml-def {
    margin-left: 0.8333333rem !important;
  }
  ._ms-ml-lg {
    margin-left: 1.25rem !important;
  }
  ._ms-ml-xl {
    margin-left: 1.6666667rem !important;
  }
  ._ms-ml-mg {
    margin-left: 3.3333333rem !important;
  }
  ._ms-ml-none {
    margin-left: 0 !important;
  }
  ._ms-m-none {
    margin: 0 !important;
  }
  ._ms-p-sm {
    padding: 0.2083333rem !important;
  }
  ._ms-p-md {
    padding: 0.4166667rem !important;
  }
  ._ms-p-def {
    padding: 0.8333333rem !important;
  }
  ._ms-p-lg {
    padding: 1.25rem !important;
  }
  ._ms-p-xl {
    padding: 1.6666667rem !important;
  }
  ._ms-p-mg {
    padding: 3.3333333rem !important;
  }
  ._ms-pt-sm {
    padding-top: 0.2083333rem !important;
  }
  ._ms-pt-md {
    padding-top: 0.4166667rem !important;
  }
  ._ms-pt-def {
    padding-top: 0.8333333rem !important;
  }
  ._ms-pt-lg {
    padding-top: 1.25rem !important;
  }
  ._ms-pt-xl {
    padding-top: 1.6666667rem !important;
  }
  ._ms-pt-mg {
    padding-top: 3.3333333rem !important;
  }
  ._ms-pt-none {
    padding-top: 0 !important;
  }
  ._ms-pr-sm {
    padding-right: 0.2083333rem !important;
  }
  ._ms-pr-md {
    padding-right: 0.4166667rem !important;
  }
  ._ms-pr-def {
    padding-right: 0.8333333rem !important;
  }
  ._ms-pr-lg {
    padding-right: 1.25rem !important;
  }
  ._ms-pr-xl {
    padding-right: 1.6666667rem !important;
  }
  ._ms-pr-mg {
    padding-right: 3.3333333rem !important;
  }
  ._ms-pr-none {
    padding-right: 0 !important;
  }
  ._ms-pb-sm {
    padding-bottom: 0.2083333rem !important;
  }
  ._ms-pb-md {
    padding-bottom: 0.4166667rem !important;
  }
  ._ms-pb-def {
    padding-bottom: 0.8333333rem !important;
  }
  ._ms-pb-lg {
    padding-bottom: 1.25rem !important;
  }
  ._ms-pb-xl {
    padding-bottom: 1.6666667rem !important;
  }
  ._ms-pb-mg {
    padding-bottom: 3.3333333rem !important;
  }
  ._ms-pb-none {
    padding-bottom: 0 !important;
  }
  ._ms-pl-sm {
    padding-left: 0.2083333rem !important;
  }
  ._ms-pl-md {
    padding-left: 0.4166667rem !important;
  }
  ._ms-pl-def {
    padding-left: 0.8333333rem !important;
  }
  ._ms-pl-lg {
    padding-left: 1.25rem !important;
  }
  ._ms-pl-xl {
    padding-left: 1.6666667rem !important;
  }
  ._ms-pl-mg {
    padding-left: 3.3333333rem !important;
  }
  ._ms-pl-none {
    padding-left: 0 !important;
  }
  ._ms-p-none {
    padding: 0 !important;
  }
  ._ms-m-auto {
    margin: auto !important;
  }
  ._ms-ml-auto {
    margin-left: auto !important;
  }
  ._ms-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 768px) {
  ._md-m-sm {
    margin: 0.2083333rem !important;
  }
  ._md-m-md {
    margin: 0.4166667rem !important;
  }
  ._md-m-def {
    margin: 0.8333333rem !important;
  }
  ._md-m-lg {
    margin: 1.25rem !important;
  }
  ._md-m-xl {
    margin: 1.6666667rem !important;
  }
  ._md-m-mg {
    margin: 3.3333333rem !important;
  }
  ._md-mt-sm {
    margin-top: 0.2083333rem !important;
  }
  ._md-mt-md {
    margin-top: 0.4166667rem !important;
  }
  ._md-mt-def {
    margin-top: 0.8333333rem !important;
  }
  ._md-mt-lg {
    margin-top: 1.25rem !important;
  }
  ._md-mt-xl {
    margin-top: 1.6666667rem !important;
  }
  ._md-mt-mg {
    margin-top: 3.3333333rem !important;
  }
  ._md-mt-none {
    margin-top: 0 !important;
  }
  ._md-mr-sm {
    margin-right: 0.2083333rem !important;
  }
  ._md-mr-md {
    margin-right: 0.4166667rem !important;
  }
  ._md-mr-def {
    margin-right: 0.8333333rem !important;
  }
  ._md-mr-lg {
    margin-right: 1.25rem !important;
  }
  ._md-mr-xl {
    margin-right: 1.6666667rem !important;
  }
  ._md-mr-mg {
    margin-right: 3.3333333rem !important;
  }
  ._md-mr-none {
    margin-right: 0 !important;
  }
  ._md-mb-sm {
    margin-bottom: 0.2083333rem !important;
  }
  ._md-mb-md {
    margin-bottom: 0.4166667rem !important;
  }
  ._md-mb-def {
    margin-bottom: 0.8333333rem !important;
  }
  ._md-mb-lg {
    margin-bottom: 1.25rem !important;
  }
  ._md-mb-xl {
    margin-bottom: 1.6666667rem !important;
  }
  ._md-mb-mg {
    margin-bottom: 3.3333333rem !important;
  }
  ._md-mb-none {
    margin-bottom: 0 !important;
  }
  ._md-ml-sm {
    margin-left: 0.2083333rem !important;
  }
  ._md-ml-md {
    margin-left: 0.4166667rem !important;
  }
  ._md-ml-def {
    margin-left: 0.8333333rem !important;
  }
  ._md-ml-lg {
    margin-left: 1.25rem !important;
  }
  ._md-ml-xl {
    margin-left: 1.6666667rem !important;
  }
  ._md-ml-mg {
    margin-left: 3.3333333rem !important;
  }
  ._md-ml-none {
    margin-left: 0 !important;
  }
  ._md-m-none {
    margin: 0 !important;
  }
  ._md-p-sm {
    padding: 0.2083333rem !important;
  }
  ._md-p-md {
    padding: 0.4166667rem !important;
  }
  ._md-p-def {
    padding: 0.8333333rem !important;
  }
  ._md-p-lg {
    padding: 1.25rem !important;
  }
  ._md-p-xl {
    padding: 1.6666667rem !important;
  }
  ._md-p-mg {
    padding: 3.3333333rem !important;
  }
  ._md-pt-sm {
    padding-top: 0.2083333rem !important;
  }
  ._md-pt-md {
    padding-top: 0.4166667rem !important;
  }
  ._md-pt-def {
    padding-top: 0.8333333rem !important;
  }
  ._md-pt-lg {
    padding-top: 1.25rem !important;
  }
  ._md-pt-xl {
    padding-top: 1.6666667rem !important;
  }
  ._md-pt-mg {
    padding-top: 3.3333333rem !important;
  }
  ._md-pt-none {
    padding-top: 0 !important;
  }
  ._md-pr-sm {
    padding-right: 0.2083333rem !important;
  }
  ._md-pr-md {
    padding-right: 0.4166667rem !important;
  }
  ._md-pr-def {
    padding-right: 0.8333333rem !important;
  }
  ._md-pr-lg {
    padding-right: 1.25rem !important;
  }
  ._md-pr-xl {
    padding-right: 1.6666667rem !important;
  }
  ._md-pr-mg {
    padding-right: 3.3333333rem !important;
  }
  ._md-pr-none {
    padding-right: 0 !important;
  }
  ._md-pb-sm {
    padding-bottom: 0.2083333rem !important;
  }
  ._md-pb-md {
    padding-bottom: 0.4166667rem !important;
  }
  ._md-pb-def {
    padding-bottom: 0.8333333rem !important;
  }
  ._md-pb-lg {
    padding-bottom: 1.25rem !important;
  }
  ._md-pb-xl {
    padding-bottom: 1.6666667rem !important;
  }
  ._md-pb-mg {
    padding-bottom: 3.3333333rem !important;
  }
  ._md-pb-none {
    padding-bottom: 0 !important;
  }
  ._md-pl-sm {
    padding-left: 0.2083333rem !important;
  }
  ._md-pl-md {
    padding-left: 0.4166667rem !important;
  }
  ._md-pl-def {
    padding-left: 0.8333333rem !important;
  }
  ._md-pl-lg {
    padding-left: 1.25rem !important;
  }
  ._md-pl-xl {
    padding-left: 1.6666667rem !important;
  }
  ._md-pl-mg {
    padding-left: 3.3333333rem !important;
  }
  ._md-pl-none {
    padding-left: 0 !important;
  }
  ._md-p-none {
    padding: 0 !important;
  }
  ._md-m-auto {
    margin: auto !important;
  }
  ._md-ml-auto {
    margin-left: auto !important;
  }
  ._md-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1024px) {
  ._defp-m-sm {
    margin: 0.2083333rem !important;
  }
  ._defp-m-md {
    margin: 0.4166667rem !important;
  }
  ._defp-m-def {
    margin: 0.8333333rem !important;
  }
  ._defp-m-lg {
    margin: 1.25rem !important;
  }
  ._defp-m-xl {
    margin: 1.6666667rem !important;
  }
  ._defp-m-mg {
    margin: 3.3333333rem !important;
  }
  ._defp-mt-sm {
    margin-top: 0.2083333rem !important;
  }
  ._defp-mt-md {
    margin-top: 0.4166667rem !important;
  }
  ._defp-mt-def {
    margin-top: 0.8333333rem !important;
  }
  ._defp-mt-lg {
    margin-top: 1.25rem !important;
  }
  ._defp-mt-xl {
    margin-top: 1.6666667rem !important;
  }
  ._defp-mt-mg {
    margin-top: 3.3333333rem !important;
  }
  ._defp-mt-none {
    margin-top: 0 !important;
  }
  ._defp-mr-sm {
    margin-right: 0.2083333rem !important;
  }
  ._defp-mr-md {
    margin-right: 0.4166667rem !important;
  }
  ._defp-mr-def {
    margin-right: 0.8333333rem !important;
  }
  ._defp-mr-lg {
    margin-right: 1.25rem !important;
  }
  ._defp-mr-xl {
    margin-right: 1.6666667rem !important;
  }
  ._defp-mr-mg {
    margin-right: 3.3333333rem !important;
  }
  ._defp-mr-none {
    margin-right: 0 !important;
  }
  ._defp-mb-sm {
    margin-bottom: 0.2083333rem !important;
  }
  ._defp-mb-md {
    margin-bottom: 0.4166667rem !important;
  }
  ._defp-mb-def {
    margin-bottom: 0.8333333rem !important;
  }
  ._defp-mb-lg {
    margin-bottom: 1.25rem !important;
  }
  ._defp-mb-xl {
    margin-bottom: 1.6666667rem !important;
  }
  ._defp-mb-mg {
    margin-bottom: 3.3333333rem !important;
  }
  ._defp-mb-none {
    margin-bottom: 0 !important;
  }
  ._defp-ml-sm {
    margin-left: 0.2083333rem !important;
  }
  ._defp-ml-md {
    margin-left: 0.4166667rem !important;
  }
  ._defp-ml-def {
    margin-left: 0.8333333rem !important;
  }
  ._defp-ml-lg {
    margin-left: 1.25rem !important;
  }
  ._defp-ml-xl {
    margin-left: 1.6666667rem !important;
  }
  ._defp-ml-mg {
    margin-left: 3.3333333rem !important;
  }
  ._defp-ml-none {
    margin-left: 0 !important;
  }
  ._defp-m-none {
    margin: 0 !important;
  }
  ._defp-p-sm {
    padding: 0.2083333rem !important;
  }
  ._defp-p-md {
    padding: 0.4166667rem !important;
  }
  ._defp-p-def {
    padding: 0.8333333rem !important;
  }
  ._defp-p-lg {
    padding: 1.25rem !important;
  }
  ._defp-p-xl {
    padding: 1.6666667rem !important;
  }
  ._defp-p-mg {
    padding: 3.3333333rem !important;
  }
  ._defp-pt-sm {
    padding-top: 0.2083333rem !important;
  }
  ._defp-pt-md {
    padding-top: 0.4166667rem !important;
  }
  ._defp-pt-def {
    padding-top: 0.8333333rem !important;
  }
  ._defp-pt-lg {
    padding-top: 1.25rem !important;
  }
  ._defp-pt-xl {
    padding-top: 1.6666667rem !important;
  }
  ._defp-pt-mg {
    padding-top: 3.3333333rem !important;
  }
  ._defp-pt-none {
    padding-top: 0 !important;
  }
  ._defp-pr-sm {
    padding-right: 0.2083333rem !important;
  }
  ._defp-pr-md {
    padding-right: 0.4166667rem !important;
  }
  ._defp-pr-def {
    padding-right: 0.8333333rem !important;
  }
  ._defp-pr-lg {
    padding-right: 1.25rem !important;
  }
  ._defp-pr-xl {
    padding-right: 1.6666667rem !important;
  }
  ._defp-pr-mg {
    padding-right: 3.3333333rem !important;
  }
  ._defp-pr-none {
    padding-right: 0 !important;
  }
  ._defp-pb-sm {
    padding-bottom: 0.2083333rem !important;
  }
  ._defp-pb-md {
    padding-bottom: 0.4166667rem !important;
  }
  ._defp-pb-def {
    padding-bottom: 0.8333333rem !important;
  }
  ._defp-pb-lg {
    padding-bottom: 1.25rem !important;
  }
  ._defp-pb-xl {
    padding-bottom: 1.6666667rem !important;
  }
  ._defp-pb-mg {
    padding-bottom: 3.3333333rem !important;
  }
  ._defp-pb-none {
    padding-bottom: 0 !important;
  }
  ._defp-pl-sm {
    padding-left: 0.2083333rem !important;
  }
  ._defp-pl-md {
    padding-left: 0.4166667rem !important;
  }
  ._defp-pl-def {
    padding-left: 0.8333333rem !important;
  }
  ._defp-pl-lg {
    padding-left: 1.25rem !important;
  }
  ._defp-pl-xl {
    padding-left: 1.6666667rem !important;
  }
  ._defp-pl-mg {
    padding-left: 3.3333333rem !important;
  }
  ._defp-pl-none {
    padding-left: 0 !important;
  }
  ._defp-p-none {
    padding: 0 !important;
  }
  ._defp-m-auto {
    margin: auto !important;
  }
  ._defp-ml-auto {
    margin-left: auto !important;
  }
  ._defp-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1025px) {
  ._def-m-sm {
    margin: 0.2083333rem !important;
  }
  ._def-m-md {
    margin: 0.4166667rem !important;
  }
  ._def-m-def {
    margin: 0.8333333rem !important;
  }
  ._def-m-lg {
    margin: 1.25rem !important;
  }
  ._def-m-xl {
    margin: 1.6666667rem !important;
  }
  ._def-m-mg {
    margin: 3.3333333rem !important;
  }
  ._def-mt-sm {
    margin-top: 0.2083333rem !important;
  }
  ._def-mt-md {
    margin-top: 0.4166667rem !important;
  }
  ._def-mt-def {
    margin-top: 0.8333333rem !important;
  }
  ._def-mt-lg {
    margin-top: 1.25rem !important;
  }
  ._def-mt-xl {
    margin-top: 1.6666667rem !important;
  }
  ._def-mt-mg {
    margin-top: 3.3333333rem !important;
  }
  ._def-mt-none {
    margin-top: 0 !important;
  }
  ._def-mr-sm {
    margin-right: 0.2083333rem !important;
  }
  ._def-mr-md {
    margin-right: 0.4166667rem !important;
  }
  ._def-mr-def {
    margin-right: 0.8333333rem !important;
  }
  ._def-mr-lg {
    margin-right: 1.25rem !important;
  }
  ._def-mr-xl {
    margin-right: 1.6666667rem !important;
  }
  ._def-mr-mg {
    margin-right: 3.3333333rem !important;
  }
  ._def-mr-none {
    margin-right: 0 !important;
  }
  ._def-mb-sm {
    margin-bottom: 0.2083333rem !important;
  }
  ._def-mb-md {
    margin-bottom: 0.4166667rem !important;
  }
  ._def-mb-def {
    margin-bottom: 0.8333333rem !important;
  }
  ._def-mb-lg {
    margin-bottom: 1.25rem !important;
  }
  ._def-mb-xl {
    margin-bottom: 1.6666667rem !important;
  }
  ._def-mb-mg {
    margin-bottom: 3.3333333rem !important;
  }
  ._def-mb-none {
    margin-bottom: 0 !important;
  }
  ._def-ml-sm {
    margin-left: 0.2083333rem !important;
  }
  ._def-ml-md {
    margin-left: 0.4166667rem !important;
  }
  ._def-ml-def {
    margin-left: 0.8333333rem !important;
  }
  ._def-ml-lg {
    margin-left: 1.25rem !important;
  }
  ._def-ml-xl {
    margin-left: 1.6666667rem !important;
  }
  ._def-ml-mg {
    margin-left: 3.3333333rem !important;
  }
  ._def-ml-none {
    margin-left: 0 !important;
  }
  ._def-m-none {
    margin: 0 !important;
  }
  ._def-p-sm {
    padding: 0.2083333rem !important;
  }
  ._def-p-md {
    padding: 0.4166667rem !important;
  }
  ._def-p-def {
    padding: 0.8333333rem !important;
  }
  ._def-p-lg {
    padding: 1.25rem !important;
  }
  ._def-p-xl {
    padding: 1.6666667rem !important;
  }
  ._def-p-mg {
    padding: 3.3333333rem !important;
  }
  ._def-pt-sm {
    padding-top: 0.2083333rem !important;
  }
  ._def-pt-md {
    padding-top: 0.4166667rem !important;
  }
  ._def-pt-def {
    padding-top: 0.8333333rem !important;
  }
  ._def-pt-lg {
    padding-top: 1.25rem !important;
  }
  ._def-pt-xl {
    padding-top: 1.6666667rem !important;
  }
  ._def-pt-mg {
    padding-top: 3.3333333rem !important;
  }
  ._def-pt-none {
    padding-top: 0 !important;
  }
  ._def-pr-sm {
    padding-right: 0.2083333rem !important;
  }
  ._def-pr-md {
    padding-right: 0.4166667rem !important;
  }
  ._def-pr-def {
    padding-right: 0.8333333rem !important;
  }
  ._def-pr-lg {
    padding-right: 1.25rem !important;
  }
  ._def-pr-xl {
    padding-right: 1.6666667rem !important;
  }
  ._def-pr-mg {
    padding-right: 3.3333333rem !important;
  }
  ._def-pr-none {
    padding-right: 0 !important;
  }
  ._def-pb-sm {
    padding-bottom: 0.2083333rem !important;
  }
  ._def-pb-md {
    padding-bottom: 0.4166667rem !important;
  }
  ._def-pb-def {
    padding-bottom: 0.8333333rem !important;
  }
  ._def-pb-lg {
    padding-bottom: 1.25rem !important;
  }
  ._def-pb-xl {
    padding-bottom: 1.6666667rem !important;
  }
  ._def-pb-mg {
    padding-bottom: 3.3333333rem !important;
  }
  ._def-pb-none {
    padding-bottom: 0 !important;
  }
  ._def-pl-sm {
    padding-left: 0.2083333rem !important;
  }
  ._def-pl-md {
    padding-left: 0.4166667rem !important;
  }
  ._def-pl-def {
    padding-left: 0.8333333rem !important;
  }
  ._def-pl-lg {
    padding-left: 1.25rem !important;
  }
  ._def-pl-xl {
    padding-left: 1.6666667rem !important;
  }
  ._def-pl-mg {
    padding-left: 3.3333333rem !important;
  }
  ._def-pl-none {
    padding-left: 0 !important;
  }
  ._def-p-none {
    padding: 0 !important;
  }
  ._def-m-auto {
    margin: auto !important;
  }
  ._def-ml-auto {
    margin-left: auto !important;
  }
  ._def-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1280px) {
  ._lg-m-sm {
    margin: 0.2083333rem !important;
  }
  ._lg-m-md {
    margin: 0.4166667rem !important;
  }
  ._lg-m-def {
    margin: 0.8333333rem !important;
  }
  ._lg-m-lg {
    margin: 1.25rem !important;
  }
  ._lg-m-xl {
    margin: 1.6666667rem !important;
  }
  ._lg-m-mg {
    margin: 3.3333333rem !important;
  }
  ._lg-mt-sm {
    margin-top: 0.2083333rem !important;
  }
  ._lg-mt-md {
    margin-top: 0.4166667rem !important;
  }
  ._lg-mt-def {
    margin-top: 0.8333333rem !important;
  }
  ._lg-mt-lg {
    margin-top: 1.25rem !important;
  }
  ._lg-mt-xl {
    margin-top: 1.6666667rem !important;
  }
  ._lg-mt-mg {
    margin-top: 3.3333333rem !important;
  }
  ._lg-mt-none {
    margin-top: 0 !important;
  }
  ._lg-mr-sm {
    margin-right: 0.2083333rem !important;
  }
  ._lg-mr-md {
    margin-right: 0.4166667rem !important;
  }
  ._lg-mr-def {
    margin-right: 0.8333333rem !important;
  }
  ._lg-mr-lg {
    margin-right: 1.25rem !important;
  }
  ._lg-mr-xl {
    margin-right: 1.6666667rem !important;
  }
  ._lg-mr-mg {
    margin-right: 3.3333333rem !important;
  }
  ._lg-mr-none {
    margin-right: 0 !important;
  }
  ._lg-mb-sm {
    margin-bottom: 0.2083333rem !important;
  }
  ._lg-mb-md {
    margin-bottom: 0.4166667rem !important;
  }
  ._lg-mb-def {
    margin-bottom: 0.8333333rem !important;
  }
  ._lg-mb-lg {
    margin-bottom: 1.25rem !important;
  }
  ._lg-mb-xl {
    margin-bottom: 1.6666667rem !important;
  }
  ._lg-mb-mg {
    margin-bottom: 3.3333333rem !important;
  }
  ._lg-mb-none {
    margin-bottom: 0 !important;
  }
  ._lg-ml-sm {
    margin-left: 0.2083333rem !important;
  }
  ._lg-ml-md {
    margin-left: 0.4166667rem !important;
  }
  ._lg-ml-def {
    margin-left: 0.8333333rem !important;
  }
  ._lg-ml-lg {
    margin-left: 1.25rem !important;
  }
  ._lg-ml-xl {
    margin-left: 1.6666667rem !important;
  }
  ._lg-ml-mg {
    margin-left: 3.3333333rem !important;
  }
  ._lg-ml-none {
    margin-left: 0 !important;
  }
  ._lg-m-none {
    margin: 0 !important;
  }
  ._lg-p-sm {
    padding: 0.2083333rem !important;
  }
  ._lg-p-md {
    padding: 0.4166667rem !important;
  }
  ._lg-p-def {
    padding: 0.8333333rem !important;
  }
  ._lg-p-lg {
    padding: 1.25rem !important;
  }
  ._lg-p-xl {
    padding: 1.6666667rem !important;
  }
  ._lg-p-mg {
    padding: 3.3333333rem !important;
  }
  ._lg-pt-sm {
    padding-top: 0.2083333rem !important;
  }
  ._lg-pt-md {
    padding-top: 0.4166667rem !important;
  }
  ._lg-pt-def {
    padding-top: 0.8333333rem !important;
  }
  ._lg-pt-lg {
    padding-top: 1.25rem !important;
  }
  ._lg-pt-xl {
    padding-top: 1.6666667rem !important;
  }
  ._lg-pt-mg {
    padding-top: 3.3333333rem !important;
  }
  ._lg-pt-none {
    padding-top: 0 !important;
  }
  ._lg-pr-sm {
    padding-right: 0.2083333rem !important;
  }
  ._lg-pr-md {
    padding-right: 0.4166667rem !important;
  }
  ._lg-pr-def {
    padding-right: 0.8333333rem !important;
  }
  ._lg-pr-lg {
    padding-right: 1.25rem !important;
  }
  ._lg-pr-xl {
    padding-right: 1.6666667rem !important;
  }
  ._lg-pr-mg {
    padding-right: 3.3333333rem !important;
  }
  ._lg-pr-none {
    padding-right: 0 !important;
  }
  ._lg-pb-sm {
    padding-bottom: 0.2083333rem !important;
  }
  ._lg-pb-md {
    padding-bottom: 0.4166667rem !important;
  }
  ._lg-pb-def {
    padding-bottom: 0.8333333rem !important;
  }
  ._lg-pb-lg {
    padding-bottom: 1.25rem !important;
  }
  ._lg-pb-xl {
    padding-bottom: 1.6666667rem !important;
  }
  ._lg-pb-mg {
    padding-bottom: 3.3333333rem !important;
  }
  ._lg-pb-none {
    padding-bottom: 0 !important;
  }
  ._lg-pl-sm {
    padding-left: 0.2083333rem !important;
  }
  ._lg-pl-md {
    padding-left: 0.4166667rem !important;
  }
  ._lg-pl-def {
    padding-left: 0.8333333rem !important;
  }
  ._lg-pl-lg {
    padding-left: 1.25rem !important;
  }
  ._lg-pl-xl {
    padding-left: 1.6666667rem !important;
  }
  ._lg-pl-mg {
    padding-left: 3.3333333rem !important;
  }
  ._lg-pl-none {
    padding-left: 0 !important;
  }
  ._lg-p-none {
    padding: 0 !important;
  }
  ._lg-m-auto {
    margin: auto !important;
  }
  ._lg-ml-auto {
    margin-left: auto !important;
  }
  ._lg-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1440px) {
  ._xl-m-sm {
    margin: 0.2083333rem !important;
  }
  ._xl-m-md {
    margin: 0.4166667rem !important;
  }
  ._xl-m-def {
    margin: 0.8333333rem !important;
  }
  ._xl-m-lg {
    margin: 1.25rem !important;
  }
  ._xl-m-xl {
    margin: 1.6666667rem !important;
  }
  ._xl-m-mg {
    margin: 3.3333333rem !important;
  }
  ._xl-mt-sm {
    margin-top: 0.2083333rem !important;
  }
  ._xl-mt-md {
    margin-top: 0.4166667rem !important;
  }
  ._xl-mt-def {
    margin-top: 0.8333333rem !important;
  }
  ._xl-mt-lg {
    margin-top: 1.25rem !important;
  }
  ._xl-mt-xl {
    margin-top: 1.6666667rem !important;
  }
  ._xl-mt-mg {
    margin-top: 3.3333333rem !important;
  }
  ._xl-mt-none {
    margin-top: 0 !important;
  }
  ._xl-mr-sm {
    margin-right: 0.2083333rem !important;
  }
  ._xl-mr-md {
    margin-right: 0.4166667rem !important;
  }
  ._xl-mr-def {
    margin-right: 0.8333333rem !important;
  }
  ._xl-mr-lg {
    margin-right: 1.25rem !important;
  }
  ._xl-mr-xl {
    margin-right: 1.6666667rem !important;
  }
  ._xl-mr-mg {
    margin-right: 3.3333333rem !important;
  }
  ._xl-mr-none {
    margin-right: 0 !important;
  }
  ._xl-mb-sm {
    margin-bottom: 0.2083333rem !important;
  }
  ._xl-mb-md {
    margin-bottom: 0.4166667rem !important;
  }
  ._xl-mb-def {
    margin-bottom: 0.8333333rem !important;
  }
  ._xl-mb-lg {
    margin-bottom: 1.25rem !important;
  }
  ._xl-mb-xl {
    margin-bottom: 1.6666667rem !important;
  }
  ._xl-mb-mg {
    margin-bottom: 3.3333333rem !important;
  }
  ._xl-mb-none {
    margin-bottom: 0 !important;
  }
  ._xl-ml-sm {
    margin-left: 0.2083333rem !important;
  }
  ._xl-ml-md {
    margin-left: 0.4166667rem !important;
  }
  ._xl-ml-def {
    margin-left: 0.8333333rem !important;
  }
  ._xl-ml-lg {
    margin-left: 1.25rem !important;
  }
  ._xl-ml-xl {
    margin-left: 1.6666667rem !important;
  }
  ._xl-ml-mg {
    margin-left: 3.3333333rem !important;
  }
  ._xl-ml-none {
    margin-left: 0 !important;
  }
  ._xl-m-none {
    margin: 0 !important;
  }
  ._xl-p-sm {
    padding: 0.2083333rem !important;
  }
  ._xl-p-md {
    padding: 0.4166667rem !important;
  }
  ._xl-p-def {
    padding: 0.8333333rem !important;
  }
  ._xl-p-lg {
    padding: 1.25rem !important;
  }
  ._xl-p-xl {
    padding: 1.6666667rem !important;
  }
  ._xl-p-mg {
    padding: 3.3333333rem !important;
  }
  ._xl-pt-sm {
    padding-top: 0.2083333rem !important;
  }
  ._xl-pt-md {
    padding-top: 0.4166667rem !important;
  }
  ._xl-pt-def {
    padding-top: 0.8333333rem !important;
  }
  ._xl-pt-lg {
    padding-top: 1.25rem !important;
  }
  ._xl-pt-xl {
    padding-top: 1.6666667rem !important;
  }
  ._xl-pt-mg {
    padding-top: 3.3333333rem !important;
  }
  ._xl-pt-none {
    padding-top: 0 !important;
  }
  ._xl-pr-sm {
    padding-right: 0.2083333rem !important;
  }
  ._xl-pr-md {
    padding-right: 0.4166667rem !important;
  }
  ._xl-pr-def {
    padding-right: 0.8333333rem !important;
  }
  ._xl-pr-lg {
    padding-right: 1.25rem !important;
  }
  ._xl-pr-xl {
    padding-right: 1.6666667rem !important;
  }
  ._xl-pr-mg {
    padding-right: 3.3333333rem !important;
  }
  ._xl-pr-none {
    padding-right: 0 !important;
  }
  ._xl-pb-sm {
    padding-bottom: 0.2083333rem !important;
  }
  ._xl-pb-md {
    padding-bottom: 0.4166667rem !important;
  }
  ._xl-pb-def {
    padding-bottom: 0.8333333rem !important;
  }
  ._xl-pb-lg {
    padding-bottom: 1.25rem !important;
  }
  ._xl-pb-xl {
    padding-bottom: 1.6666667rem !important;
  }
  ._xl-pb-mg {
    padding-bottom: 3.3333333rem !important;
  }
  ._xl-pb-none {
    padding-bottom: 0 !important;
  }
  ._xl-pl-sm {
    padding-left: 0.2083333rem !important;
  }
  ._xl-pl-md {
    padding-left: 0.4166667rem !important;
  }
  ._xl-pl-def {
    padding-left: 0.8333333rem !important;
  }
  ._xl-pl-lg {
    padding-left: 1.25rem !important;
  }
  ._xl-pl-xl {
    padding-left: 1.6666667rem !important;
  }
  ._xl-pl-mg {
    padding-left: 3.3333333rem !important;
  }
  ._xl-pl-none {
    padding-left: 0 !important;
  }
  ._xl-p-none {
    padding: 0 !important;
  }
  ._xl-m-auto {
    margin: auto !important;
  }
  ._xl-ml-auto {
    margin-left: auto !important;
  }
  ._xl-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1540px) {
  ._mg-m-sm {
    margin: 0.2083333rem !important;
  }
  ._mg-m-md {
    margin: 0.4166667rem !important;
  }
  ._mg-m-def {
    margin: 0.8333333rem !important;
  }
  ._mg-m-lg {
    margin: 1.25rem !important;
  }
  ._mg-m-xl {
    margin: 1.6666667rem !important;
  }
  ._mg-m-mg {
    margin: 3.3333333rem !important;
  }
  ._mg-mt-sm {
    margin-top: 0.2083333rem !important;
  }
  ._mg-mt-md {
    margin-top: 0.4166667rem !important;
  }
  ._mg-mt-def {
    margin-top: 0.8333333rem !important;
  }
  ._mg-mt-lg {
    margin-top: 1.25rem !important;
  }
  ._mg-mt-xl {
    margin-top: 1.6666667rem !important;
  }
  ._mg-mt-mg {
    margin-top: 3.3333333rem !important;
  }
  ._mg-mt-none {
    margin-top: 0 !important;
  }
  ._mg-mr-sm {
    margin-right: 0.2083333rem !important;
  }
  ._mg-mr-md {
    margin-right: 0.4166667rem !important;
  }
  ._mg-mr-def {
    margin-right: 0.8333333rem !important;
  }
  ._mg-mr-lg {
    margin-right: 1.25rem !important;
  }
  ._mg-mr-xl {
    margin-right: 1.6666667rem !important;
  }
  ._mg-mr-mg {
    margin-right: 3.3333333rem !important;
  }
  ._mg-mr-none {
    margin-right: 0 !important;
  }
  ._mg-mb-sm {
    margin-bottom: 0.2083333rem !important;
  }
  ._mg-mb-md {
    margin-bottom: 0.4166667rem !important;
  }
  ._mg-mb-def {
    margin-bottom: 0.8333333rem !important;
  }
  ._mg-mb-lg {
    margin-bottom: 1.25rem !important;
  }
  ._mg-mb-xl {
    margin-bottom: 1.6666667rem !important;
  }
  ._mg-mb-mg {
    margin-bottom: 3.3333333rem !important;
  }
  ._mg-mb-none {
    margin-bottom: 0 !important;
  }
  ._mg-ml-sm {
    margin-left: 0.2083333rem !important;
  }
  ._mg-ml-md {
    margin-left: 0.4166667rem !important;
  }
  ._mg-ml-def {
    margin-left: 0.8333333rem !important;
  }
  ._mg-ml-lg {
    margin-left: 1.25rem !important;
  }
  ._mg-ml-xl {
    margin-left: 1.6666667rem !important;
  }
  ._mg-ml-mg {
    margin-left: 3.3333333rem !important;
  }
  ._mg-ml-none {
    margin-left: 0 !important;
  }
  ._mg-m-none {
    margin: 0 !important;
  }
  ._mg-p-sm {
    padding: 0.2083333rem !important;
  }
  ._mg-p-md {
    padding: 0.4166667rem !important;
  }
  ._mg-p-def {
    padding: 0.8333333rem !important;
  }
  ._mg-p-lg {
    padding: 1.25rem !important;
  }
  ._mg-p-xl {
    padding: 1.6666667rem !important;
  }
  ._mg-p-mg {
    padding: 3.3333333rem !important;
  }
  ._mg-pt-sm {
    padding-top: 0.2083333rem !important;
  }
  ._mg-pt-md {
    padding-top: 0.4166667rem !important;
  }
  ._mg-pt-def {
    padding-top: 0.8333333rem !important;
  }
  ._mg-pt-lg {
    padding-top: 1.25rem !important;
  }
  ._mg-pt-xl {
    padding-top: 1.6666667rem !important;
  }
  ._mg-pt-mg {
    padding-top: 3.3333333rem !important;
  }
  ._mg-pt-none {
    padding-top: 0 !important;
  }
  ._mg-pr-sm {
    padding-right: 0.2083333rem !important;
  }
  ._mg-pr-md {
    padding-right: 0.4166667rem !important;
  }
  ._mg-pr-def {
    padding-right: 0.8333333rem !important;
  }
  ._mg-pr-lg {
    padding-right: 1.25rem !important;
  }
  ._mg-pr-xl {
    padding-right: 1.6666667rem !important;
  }
  ._mg-pr-mg {
    padding-right: 3.3333333rem !important;
  }
  ._mg-pr-none {
    padding-right: 0 !important;
  }
  ._mg-pb-sm {
    padding-bottom: 0.2083333rem !important;
  }
  ._mg-pb-md {
    padding-bottom: 0.4166667rem !important;
  }
  ._mg-pb-def {
    padding-bottom: 0.8333333rem !important;
  }
  ._mg-pb-lg {
    padding-bottom: 1.25rem !important;
  }
  ._mg-pb-xl {
    padding-bottom: 1.6666667rem !important;
  }
  ._mg-pb-mg {
    padding-bottom: 3.3333333rem !important;
  }
  ._mg-pb-none {
    padding-bottom: 0 !important;
  }
  ._mg-pl-sm {
    padding-left: 0.2083333rem !important;
  }
  ._mg-pl-md {
    padding-left: 0.4166667rem !important;
  }
  ._mg-pl-def {
    padding-left: 0.8333333rem !important;
  }
  ._mg-pl-lg {
    padding-left: 1.25rem !important;
  }
  ._mg-pl-xl {
    padding-left: 1.6666667rem !important;
  }
  ._mg-pl-mg {
    padding-left: 3.3333333rem !important;
  }
  ._mg-pl-none {
    padding-left: 0 !important;
  }
  ._mg-p-none {
    padding: 0 !important;
  }
  ._mg-m-auto {
    margin: auto !important;
  }
  ._mg-ml-auto {
    margin-left: auto !important;
  }
  ._mg-mr-auto {
    margin-right: auto !important;
  }
}

.m-menu {
  position: fixed;
  left: -100%;
  top: 60px;
  height: calc(100vh - 60px);
  z-index: 9999;
  transition: left 0.3s ease;
  width: 80vw;
  max-width: 300px;
  min-width: 280px;
  background-color: #fff;
  display: none;
}

.m-menu.is-inited {
  display: block;
}

@media only screen and (min-width: 640px) {
  .m-menu {
    top: 80px;
    height: calc(100vh - 80px);
  }
}

.m-menu__decor {
  width: 100%;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media only screen and (min-width: 640px) {
  .m-menu__decor {
    display: none;
  }
}

.m-menu__decor svg {
  height: 56px;
}

.m-menu__list-item {
  color: #333;
  background-color: transparen;
  display: flex;
  width: 100%;
  align-items: center;
}

.m-menu__title {
  font-size: 18px;
  padding: 4px 40px;
  justify-content: center;
  text-align: center;
  display: none;
  position: relative;
  min-height: 40px;
  align-items: center;
}

@media only screen and (min-width: 1025px) {
  .m-menu__title {
    font-size: 20px;
    padding: 8px 40px;
  }
}

.m-menu__title:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 90%;
  height: 1px;
  background-color: #333;
  content: "";
}

.m-menu__sub-list-item {
  color: #333;
  background-color: transparen;
  display: flex;
  align-items: center;
}

.m-menu__list-link {
  padding: 6px 4px 6px 20px;
}

.m-menu__sub-list-link {
  padding: 6px 10px 6px 20px;
}

.m-menu__list-link, .m-menu__sub-list-link {
  min-height: 30px;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 14px;
  color: currentColor;
  line-height: 16px;
  transition: background-color 0.1s ease, color 0.2s ease;
  width: 100%;
}

@media only screen and (min-width: 1025px) {
  .m-menu__list-link, .m-menu__sub-list-link {
    min-height: 30px;
    font-size: 14px;
    line-height: 16px;
    padding: 4px 4px 4px 20px;
  }
}

@media only screen and (min-width: 1540px) {
  .m-menu__list-link, .m-menu__sub-list-link {
    min-height: 50px;
    font-size: 16px;
    line-height: 20px;
    padding: 6px 4px 6px 40px;
  }
}

.m-menu__list-link.is-quostil-for-client, .m-menu__sub-list-link.is-quostil-for-client {
  margin-top: 18px;
}

.m-menu__list, .m-menu__sub-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  overflow: auto;
  max-height: calc(100vh - 360px);
  min-height: 200px;
}

@media only screen and (min-width: 640px) {
  .m-menu__list, .m-menu__sub-list {
    max-height: calc(100vh - 280px);
  }
}

@media only screen and (min-width: 1025px) {
  .m-menu__list, .m-menu__sub-list {
    max-height: calc(100vh - 380px);
  }
}

.m-menu__list::-webkit-scrollbar, .m-menu__sub-list::-webkit-scrollbar {
  width: 0;
  transition: width 0.2s ease;
  z-index: -1;
}

.m-menu__list:hover::-webkit-scrollbar, .m-menu__sub-list:hover::-webkit-scrollbar {
  width: 6px;
}

.m-menu__list:hover .m-menu__list-link,
.m-menu__list:hover .m-menu__sub-list-link, .m-menu__sub-list:hover .m-menu__list-link,
.m-menu__sub-list:hover .m-menu__sub-list-link {
  padding-right: 4px;
}

.m-menu__sub-list {
  height: 100%;
  width: 426px;
  min-width: 270px;
  top: 0;
}

.m-menu__bottom-widgets {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 25;
  background-color: #fff;
}

.m-menu__nav-wrapper {
  margin-bottom: 20px;
  min-height: 200px;
}

.m-menu__next-arrow, .m-menu__prev-arrow {
  display: none;
}

.m-menu.is-active {
  left: 0;
}

.m-menu.is-resp {
  overflow-y: auto;
}

.m-menu.is-resp .m-menu__nav-wrapper {
  position: relative;
  height: auto;
}

.m-menu.is-resp .m-menu__title {
  display: flex;
}

.m-menu.is-resp .m-menu__list-link, .m-menu.is-resp .m-menu__sub-list-link {
  min-height: 40px;
}

.m-menu.is-resp .m-menu__list-link:hover, .m-menu.is-resp .m-menu__sub-list-link:hover {
  color: #fff;
  background-color: #ed1a3b;
}

.m-menu.is-resp .m-menu__bottom-widgets {
  padding-bottom: 40px;
}

.m-menu.is-resp .m-menu__next-arrow {
  display: block;
  position: relative;
  margin-left: auto;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease;
}

.m-menu.is-resp .m-menu__next-arrow svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.m-menu.is-resp .m-menu__next-arrow:hover {
  background-color: #ed1a3b;
}

.m-menu.is-resp .m-menu__prev-arrow {
  display: block;
  position: absolute;
  margin-left: auto;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease;
  left: 0;
  top: 0;
  bottom: 0;
}

.m-menu.is-resp .m-menu__prev-arrow svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.m-menu.is-resp .m-menu__prev-arrow:hover {
  background-color: #ed1a3b;
}

.m-menu.is-resp .m-menu__sub-list {
  display: none;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
}

.m-menu.is-resp .m-menu__sub-list.is-active {
  display: block;
  overflow-y: auto;
}

.m-menu.is-resp .m-menu__sub-list.is-prev {
  overflow: hidden;
}

.m-menu.is-desktop {
  width: 15.7vw;
  min-width: 220px;
}

.m-menu.is-desktop .m-menu__list {
  position: relative;
  z-index: 25;
  background-color: #fff;
}

.m-menu.is-desktop .m-menu__sub-list {
  position: absolute;
  width: 280px;
  left: 100%;
  visibility: hidden;
  background-color: #edebe9;
  background: linear-gradient(to right, #aaafb1 0, #efefef 10px, #ffffff 18px, #ffffff 100%);
  max-height: calc(100vh - 80px);
  opacity: 0;
}

@media only screen and (min-width: 1280px) {
  .m-menu.is-desktop .m-menu__sub-list {
    width: 300px;
  }
}

@media only screen and (min-width: 1540px) {
  .m-menu.is-desktop .m-menu__sub-list {
    width: 424px;
  }
}

.m-menu.is-desktop .m-menu__sub-list.is-open {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s 0s ease, visibility 0s 0s ease, transform 0.2s ease;
  transform: translateX(0);
}

.m-menu.is-desktop .m-menu__sub-list.level1 {
  left: calc(100% + 280px);
}

@media only screen and (min-width: 1280px) {
  .m-menu.is-desktop .m-menu__sub-list.level1 {
    left: calc(100% + 300px);
  }
}

@media only screen and (min-width: 1540px) {
  .m-menu.is-desktop .m-menu__sub-list.level1 {
    left: calc(100% + 424px);
  }
}

.m-menu.is-desktop .m-menu__sub-list.level2 {
  left: calc(100% + 560px);
}

@media only screen and (min-width: 1280px) {
  .m-menu.is-desktop .m-menu__sub-list.level2 {
    left: calc(100% + 600px);
  }
}

@media only screen and (min-width: 1540px) {
  .m-menu.is-desktop .m-menu__sub-list.level2 {
    left: calc(100% + 848px);
  }
}

.m-menu.is-desktop .m-menu__sub-list-link {
  width: calc(100% - 6px);
}

.m-menu.is-desktop .m-menu__list-item:hover, .m-menu.is-desktop .m-menu__sub-list-item:hover {
  background-color: #ed1a3b;
}

.m-menu.is-desktop .m-menu__list-item.is-hover, .m-menu.is-desktop .m-menu__sub-list-item.is-hover {
  background-color: #ed1a3b;
}

.m-menu.is-desktop .m-menu__list-item.is-hover .m-menu__list-link,
.m-menu.is-desktop .m-menu__list-item.is-hover .m-menu__sub-list-link, .m-menu.is-desktop .m-menu__sub-list-item.is-hover .m-menu__list-link,
.m-menu.is-desktop .m-menu__sub-list-item.is-hover .m-menu__sub-list-link {
  background-color: #ed1a3b;
  color: #fff;
}

.m-menu.is-desktop .m-menu__list-item .m-menu__list-link:hover,
.m-menu.is-desktop .m-menu__list-item .m-menu__sub-list-link:hover, .m-menu.is-desktop .m-menu__sub-list-item .m-menu__list-link:hover,
.m-menu.is-desktop .m-menu__sub-list-item .m-menu__sub-list-link:hover {
  background-color: #ed1a3b;
  color: #fff;
}

.m-menu__phones-widget {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 14px 0;
  border-bottom: 1px solid #ccd3dd;
  border-top: 1px solid #ccd3dd;
  margin-bottom: 8px;
  align-items: center;
}

@media only screen and (min-width: 1540px) {
  .m-menu__phones-widget {
    padding: 34px 0;
    margin-bottom: 28px;
  }
}

.m-menu__socs-widget {
  display: inline-flex;
  padding: 0 10px;
  justify-content: center;
}

@media only screen and (min-width: 1540px) {
  .m-menu__socs-widget {
    padding: 0 40px;
  }
}

.menu-overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s ease, opacity 0.3s 0s ease, z-index 0s 0.3s ease;
}

.menu-overlay.is-active {
  opacity: 0.5;
  z-index: 900;
  visibility: visible;
  transition: visibility 0s 0s ease, opacity 0.3s 0.1s ease, z-index 0s 0s ease;
}

.no-touchevents body.scroll-disabled {
  overflow: hidden;
  padding-right: 6px;
}

.no-touchevents body.scroll-disabled .view-header {
  padding: 4px 46px 4px 40px;
}

.no-touchevents body.scroll-disabled .ask-button {
  margin-right: 6px;
}

.touchevents .m-menu__sub-list::-webkit-scrollbar,
.touchevents .m-menu__list::-webkit-scrollbar {
  width: 6px;
  transition: width 0.2s ease;
  z-index: -1;
}

.burger {
  position: relative;
  width: 40px;
  height: 28px;
  cursor: pointer;
}

.burger__decor {
  width: 100%;
  height: 3px;
  position: absolute;
  left: 0;
  margin: auto;
}

.burger__decor:nth-child(1):before, .burger__decor:nth-child(3):before {
  left: 0;
}

.burger__decor:nth-child(1):after, .burger__decor:nth-child(3):after {
  right: 0;
}

.burger__decor:nth-child(1):before, .burger__decor:nth-child(1):after, .burger__decor:nth-child(3):before, .burger__decor:nth-child(3):after {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  content: "";
  background-color: #000;
  transition: background-color 0.1s ease, transform 0.3s ease;
}

.burger__decor:nth-child(1) {
  transform-origin: center;
  top: 0;
  right: auto;
  bottom: auto;
  transition: transform 0.3s ease, top 0.3s ease, left 0.3s ease, right 0.3s ease, bottom 0.3s ease, margin 0.3s ease;
}

.burger__decor:nth-child(3) {
  bottom: 0;
  right: auto;
  transform-origin: center;
  transition: transform 0.3s ease, top 0.3s ease, left 0.3s ease, right 0.3s ease, bottom 0.3s ease, margin 0.3s ease;
}

.burger__decor:nth-child(2) {
  background-color: #000;
  transition: background-color 0.1s ease, transform 0.3s 0.1s ease, opacity 0.3s 0.2s ease;
  opacity: 1;
  top: 0;
  bottom: 0;
  right: auto;
  transform: translateX(0px);
}

.burger:hover .burger__decor:nth-child(2) {
  background-color: #0c4da2;
  transition: background-color 0.3s ease, transform 0.3s 0s ease, opacity 0.3s 0.1s ease;
}

.burger:hover .burger__decor:nth-child(1):before, .burger:hover .burger__decor:nth-child(1):after, .burger:hover .burger__decor:nth-child(3):before, .burger:hover .burger__decor:nth-child(3):after {
  background-color: #0c4da2;
  transition: background-color 0.3s ease, transform 0.3s 0s ease;
}

.burger.is-active {
  height: 40px;
}

.burger.is-active .burger__decor:nth-child(1) {
  transform: rotate(45deg);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.burger.is-active .burger__decor:nth-child(1):before {
  transform: translateX(-5px);
}

.burger.is-active .burger__decor:nth-child(1):after {
  transform: translateX(5px);
}

.burger.is-active .burger__decor:nth-child(1):before, .burger.is-active .burger__decor:nth-child(1):after {
  transition: transform 0.3s 0.2s ease, top 0.3s ease, left 0.3s ease, right 0.3s ease, bottom 0.3s ease, margin 0.3s ease;
}

.burger.is-active .burger__decor:nth-child(3) {
  transform: rotate(-45deg);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.burger.is-active .burger__decor:nth-child(3):before {
  transform: translateX(-5px);
}

.burger.is-active .burger__decor:nth-child(3):after {
  transform: translateX(5px);
}

.burger.is-active .burger__decor:nth-child(3):before, .burger.is-active .burger__decor:nth-child(3):after {
  transition: transform 0.3s 0.2s ease, top 0.3s ease, left 0.3s ease, right 0.3s ease, bottom 0.3s ease, margin 0.3s ease;
}

.burger.is-active .burger__decor:nth-child(2) {
  transform: translateX(-30px);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.categories-item {
  margin: 0 auto;
  width: 100%;
  height: 82px;
  position: relative;
  margin-bottom: 2px;
  max-height: initial;
  backface-visibility: hidden;
  min-height: initial;
}

@media only screen and (min-width: 640px) {
  .categories-item {
    height: calc(50vh - 30px);
    min-height: 180px;
    max-height: 280px;
  }
}

@media only screen and (min-width: 768px) {
  .categories-item {
    height: 240px;
  }
}

@media only screen and (min-width: 1025px) {
  .categories-item {
    height: 280px;
  }
}

.categories-item__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  z-index: 1;
  overflow: hidden;
  background-color: #000;
}

.categories-item__image img {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: auto;
  transition: transform 0.4s ease, opacity 0.3s ease;
  max-width: initial;
  max-height: initial;
  transform: translate(-50%, -50%);
  min-width: 100%;
  opacity: 0.5;
}

.categories-item__descr {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 6px;
  display: flex;
}

@media only screen and (min-width: 640px) {
  .categories-item__descr {
    padding: 10% 5% 9% 8.2%;
    display: block;
  }
}

.categories-item__num {
  font-size: 24px;
  color: #fff;
  opacity: 0.2;
  font-weight: bold;
  margin-bottom: 14px;
  position: relative;
  transition: opacity 0.6s ease;
}

.categories-item__text {
  font-size: 20px;
  line-height: 24px;
  font-family: 'Open Sans';
  color: #fff;
  padding-top: 0;
  overflow: hidden;
  position: relative;
  margin-left: 6px;
  margin-top: 2px;
}

@media only screen and (min-width: 640px) {
  .categories-item__text {
    padding-top: 20px;
    margin-top: 0;
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) {
  .categories-item__text {
    font-size: 24px;
    line-height: 26px;
  }
}

@media only screen and (min-width: 1540px) {
  .categories-item__text {
    font-size: 30px;
    line-height: 36px;
  }
}

.categories-item__text:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 2px;
  width: 24px;
  background-color: #fff;
  content: "";
  display: none;
}

@media only screen and (min-width: 640px) {
  .categories-item__text:before {
    display: block;
  }
}

.categories-item:hover .categories-item__image:after {
  opacity: 0.7;
  transition: opacity 0.6s ease;
}

.categories-item:hover .categories-item__image img {
  transform: scale(1.05) translate(-50%, -50%);
  transition: transform 0.8s ease;
  opacity: 0.3;
}

.categories-item:hover .categories-item__num {
  opacity: 1;
}

.touchevents .categories-item__image:after {
  opacity: 0.7;
  transition: opacity 0.6s ease;
}

.touchevents .categories-item__image img {
  transform: scale(1.05) translate(-50%, -50%);
  transition: transform 0.8s ease;
}

.touchevents .categories-item__num {
  opacity: 1;
}

.semiblock-widget {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media only screen and (min-width: 1280px) {
  .semiblock-widget {
    flex-direction: row;
  }
}

.semiblock-widget-descr {
  width: 100%;
  min-width: 100%;
  position: relative;
  padding: 20px 1.8%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (min-width: 1280px) {
  .semiblock-widget-descr {
    width: 25%;
    min-width: 475px;
    padding: 44px 1.8%;
  }
}

.semiblock-widget-descr__decor {
  width: 97px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0.3;
}

.semiblock-widget-descr__title {
  position: relative;
  z-index: 3;
  font-size: 28px;
  width: 100%;
  font-weight: bold;
  line-height: 100%;
  color: #fff;
  margin-bottom: 30px;
}

@media only screen and (min-width: 640px) {
  .semiblock-widget-descr__title {
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) {
  .semiblock-widget-descr__title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 1025px) {
  .semiblock-widget-descr__title {
    font-size: 48px;
  }
}

@media only screen and (min-width: 1280px) {
  .semiblock-widget-descr__title {
    margin-bottom: auto;
  }
}

.semiblock-widget-descr__bg {
  width: 100%;
  height: 100%;
  max-height: 700px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.1;
}

.semiblock-widget-descr__bg svg {
  height: 614px;
  width: 650px;
  stroke: #fff;
  fill: transparent;
  position: absolute;
  right: -6px;
  bottom: 6px;
  stroke-width: 0.3px;
}

.semiblock-widget-descr__bg-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.semiblock-widget-descr__button {
  font-size: 12px;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 300;
  font-family: 'Open Sans';
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 10px 70px 10px 10px;
}

.semiblock-widget-descr__button svg {
  width: 48px;
  height: 10px;
  fill: #fff;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  transition: right 0.3s ease;
}

.semiblock-widget-descr__button span {
  position: relative;
}

.semiblock-widget-descr__button span:before {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  width: 0;
  height: 1px;
  background-color: #fff;
  transition: width 0.3s ease;
}

.semiblock-widget-descr__button:hover span:before {
  width: 100%;
}

.semiblock-widget-descr__button:hover svg {
  right: 8px;
}

.semiblock-widget-descr--bg-red {
  background-color: #ed1a3b;
}

.semiblock-widget-descr--bg-blue {
  background-color: #0c4da2;
}

.semiblock-widget-descr--bg-lblue {
  background-color: #4295d1;
}

.semiblock-widget-descr--bg-grey {
  background-color: #68737a;
}

.semiblock-widget-grid {
  width: 100%;
}

@media only screen and (min-width: 1280px) {
  .semiblock-widget-grid {
    width: 75%;
  }
}

.service-item {
  width: 100%;
  height: 132px;
  overflow: hidden;
  margin-bottom: 2px;
  border: 1px solid #d0d0d0;
  position: relative;
  background-color: #fff;
  text-decoration: none;
  transition: border-color 0.3s ease;
  max-height: 340px;
  min-height: 132px;
  backface-visibility: hidden;
}

@media only screen and (min-width: 640px) {
  .service-item {
    height: 280px;
    min-height: 200px;
  }
}

@media only screen and (min-width: 768px) {
  .service-item {
    height: 300px;
  }
}

@media only screen and (min-width: 1025px) {
  .service-item {
    height: 340px;
  }
}

.service-item__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.3s ease, visibility 0s 0.3s ease;
  visibility: hidden;
}

.service-item__image img {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  min-width: 100%;
  max-width: initial;
  z-index: 1;
}

.service-item__image:after {
  background-color: #000;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  content: "";
  opacity: 0.5;
  z-index: 2;
  position: absolute;
}

.service-item__text {
  position: absolute;
  z-index: 4;
  color: #333;
  font-family: 'Open Sans';
  transition: color 0.3s ease;
  font-size: 18px;
  line-height: 24px;
  bottom: 35px;
  left: 15px;
}

@media only screen and (min-width: 768px) {
  .service-item__text {
    font-size: 22px;
    line-height: 30px;
    bottom: 55px;
    left: 35px;
  }
}

.service-item__decor-button {
  width: 35px;
  height: 35px;
  background-color: #ed1a3b;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
}

@media only screen and (min-width: 768px) {
  .service-item__decor-button {
    width: 50px;
    height: 50px;
  }
}

.service-item__decor-button svg {
  width: 10px;
  height: 10px;
  fill: #fff;
}

.service-item__decor {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.service-item__decor:after, .service-item__decor:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  opacity: 0;
  transition: opacity 0.3s 0.2s ease, transform 0.3s 0.2s ease;
}

.service-item__decor:after {
  left: 35px;
  top: 35px;
  border-top: 1px solid #ed1a3b;
  border-left: 1px solid #ed1a3b;
  transform: translate(-25px, -25px);
}

@media only screen and (min-width: 768px) {
  .service-item__decor:after {
    left: 50px;
    top: 50px;
  }
}

.service-item__decor:before {
  right: 35px;
  bottom: 35px;
  border-bottom: 1px solid #ed1a3b;
  border-right: 1px solid #ed1a3b;
  transform: translate(25px, 25px);
}

@media only screen and (min-width: 768px) {
  .service-item__decor:before {
    right: 50px;
    bottom: 50px;
  }
}

.service-item__decor-image {
  position: absolute;
  right: 6px;
  top: 6px;
  z-index: 3;
}

@media only screen and (min-width: 640px) {
  .service-item__decor-image {
    right: 36px;
    top: 36px;
  }
}

.service-item__decor-image svg {
  width: 40px;
  height: 40px;
  fill: #b3b9bc;
  transition: fill 0.3s 0.2s ease;
}

@media only screen and (min-width: 640px) {
  .service-item__decor-image svg {
    width: 60px;
    height: 60px;
  }
}

@media only screen and (min-width: 768px) {
  .service-item__decor-image svg {
    width: 80px;
    height: 80px;
  }
}

.service-item:hover .service-item {
  border-color: transparent;
}

.service-item:hover .service-item__image {
  opacity: 1;
  transition: opacity 0.5s 0.01s ease, visibility 0s 0s ease;
  visibility: visible;
}

.service-item:hover .service-item__text {
  color: #fff;
  transition: color 0.3s 0.2s ease;
}

.service-item:hover .service-item__decor:after, .service-item:hover .service-item__decor:before {
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.service-item:hover .service-item__decor-image svg {
  fill: #fff;
  transition: fill 0.3s 0.2s ease;
}

.service-item:hover .service-item__decor-button {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s 0.2s ease;
}

.touchevents .service-item {
  border-color: transparent;
}

.touchevents .service-item__image {
  opacity: 1;
  transition: opacity 0.5s 0.01s ease, visibility 0s 0s ease;
  visibility: visible;
}

.touchevents .service-item__text {
  color: #fff;
  transition: color 0.3s 0.2s ease;
}

.touchevents .service-item__decor:after, .touchevents .service-item__decor:before {
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.touchevents .service-item__decor-image svg {
  fill: #fff;
  transition: fill 0.3s 0.2s ease;
}

.touchevents .service-item__decor-button {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s 0.2s ease;
}

.solutions-item {
  width: 100%;
  height: 130px;
  display: flex;
  border-bottom: 1px solid #e6edf6;
  text-decoration: none;
  backface-visibility: hidden;
  transition: border-color 0.3s ease;
}

@media only screen and (min-width: 640px) {
  .solutions-item {
    height: 162px;
  }
}

.solutions-item.is-active {
  pointer-events: none;
  border-color: #0c4da2;
}

.solutions-item.is-active .solutions-item__num {
  color: #fff;
}

.solutions-item.is-active .solutions-item__descr {
  background-color: #e6edf6;
}

.solutions-item.is-active .solutions-item__image:after {
  opacity: 0.5;
}

.solutions-item.is-active .solutions-item__image img {
  transform: scale(1.05) translate(-50%, -50%);
}

.solutions-item__image {
  min-width: 134px;
  width: 43.8%;
  max-width: 208px;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  background-color: #000;
}

.solutions-item__image img {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  min-width: 100%;
  max-width: initial;
  z-index: 1;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.solutions-item__descr {
  padding: 10px 14px;
  border-top: 2px solid #e6edf6;
  background-color: #fff;
  width: 56.2%;
  transition: background-color 0.3s ease;
}

@media only screen and (min-width: 1025px) {
  .solutions-item__descr {
    width: 100%;
  }
}

.solutions-item__num {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 4px;
  color: #e6edf6;
  transition: color 0.3s ease;
}

@media only screen and (min-width: 640px) {
  .solutions-item__num {
    margin-bottom: 10px;
  }
}

.solutions-item__text {
  font-size: 18px;
  color: #333;
  line-height: 22px;
  font-family: 'Open Sans';
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (min-width: 640px) {
  .solutions-item__text {
    font-size: 22px;
    line-height: 25px;
  }
}

.solutions-item:hover {
  border-color: #0c4da2;
}

.solutions-item:hover .solutions-item__num {
  color: #fff;
}

.solutions-item:hover .solutions-item__descr {
  background-color: #e6edf6;
}

.solutions-item:hover .solutions-item__image img {
  transform: scale(1.05) translate(-50%, -50%);
  opacity: 0.5;
}

.inner-solutions-img-num {
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 0.2;
  font-size: 60px;
  font-weight: bold;
  font-family: 'DIN Pro';
  line-height: 100%;
  z-index: 2;
  color: #fff;
}

@media only screen and (min-width: 1025px) {
  .inner-solutions-img-num {
    font-size: 120px;
  }
}

.news-item {
  width: 100%;
  text-decoration: none;
  will-change: transform;
  backface-visibility: hidden;
}

.news-item__image {
  height: 180px;
  position: relative;
  overflow: hidden;
  background-color: #000;
}

@media only screen and (min-width: 768px) {
  .news-item__image {
    height: 240px;
  }
}

@media only screen and (min-width: 1025px) {
  .news-item__image {
    height: 280px;
  }
}

@media only screen and (min-width: 1280px) {
  .news-item__image {
    height: 340px;
  }
}

.news-item__image img {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  min-width: 100%;
  max-width: initial;
  z-index: 1;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0.7;
}

.news-item__button-text {
  font-size: 12px;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 300;
  font-family: 'Open Sans';
  text-transform: uppercase;
  padding-left: 36px;
}

.news-item__button-svg-wrapper {
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  transition: background-color 0.3s ease;
  align-items: center;
}

.news-item__button-decor {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 3;
  align-items: center;
}

.news-item__button-decor svg {
  width: 10px;
  height: 15px;
  fill: #fff;
  position: relative;
  z-index: 2;
}

.news-item__text {
  font-size: 18px;
  color: #000;
  line-height: 24px;
  font-family: 'Open Sans';
  margin-bottom: 12px;
  transition: color 0.3s ease;
}

@media only screen and (min-width: 768px) {
  .news-item__text {
    font-size: 22px;
    line-height: 30px;
  }
}

.news-item__date {
  font-weight: 300;
  font-size: 12px;
  color: #68737a;
  font-family: 'Open Sans';
  letter-spacing: 1px;
}

.news-item__descr {
  height: auto;
  border-left: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
  padding: 8% 7.5%;
}

@media only screen and (min-width: 768px) {
  .news-item__descr {
    height: 200px;
  }
}

.news-item__decor {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.news-item__decor:after, .news-item__decor:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  opacity: 0;
  transition: opacity 0.3s 0.2s ease, transform 0.3s 0.2s ease;
}

.news-item__decor:after {
  left: 35px;
  top: 35px;
  border-top: 1px solid #ed1a3b;
  border-left: 1px solid #ed1a3b;
  transform: translate(-25px, -25px);
}

@media only screen and (min-width: 768px) {
  .news-item__decor:after {
    left: 50px;
    top: 50px;
  }
}

.news-item__decor:before {
  right: 35px;
  bottom: 35px;
  border-bottom: 1px solid #ed1a3b;
  border-right: 1px solid #ed1a3b;
  transform: translate(25px, 25px);
}

@media only screen and (min-width: 768px) {
  .news-item__decor:before {
    right: 50px;
    bottom: 50px;
  }
}

.news-item:hover .news-item__text {
  color: #ed1a3b;
}

.news-item:hover .news-item__button-svg-wrapper {
  background-color: #ed1a3b;
}

.news-item:hover .news-item__decor:after, .news-item:hover .news-item__decor:before {
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.news-item:hover .news-item__image img {
  transform: scale(1.05) translate(-50%, -50%);
  opacity: 0.5;
}

.news-list {
  margin-bottom: 20px;
}

@media only screen and (min-width: 1025px) {
  .news-list {
    margin-bottom: 50px;
  }
}

.innernews__date {
  font-size: 12px;
  letter-spacing: 1px;
  color: #68737a;
  font-family: 'DIN Pro';
  font-weight: 300;
}

.seo-block__title {
  font-size: 32px;
  line-height: 100%;
  font-weight: bold;
  color: #333;
  margin-bottom: 28px;
}

@media only screen and (min-width: 768px) {
  .seo-block__title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 1025px) {
  .seo-block__title {
    font-size: 48px;
  }
}

.seo-block--innerpage {
  margin-bottom: 0;
}

.seo-block--innerpage .view-text {
  margin-bottom: 0;
}

.seo-block--innerpage .view-text p {
  font-size: 14px;
  line-height: 110%;
  margin: 20px 0;
}

.wezom {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 302px;
}

@media only screen and (min-width: 640px) {
  .wezom {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 1025px) {
  .wezom {
    margin-right: 0;
  }
}

@media only screen and (min-width: 1280px) {
  .wezom {
    margin-right: 20%;
  }
}

.wezom__text {
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  color: currentColor;
  font-family: 'Open Sans';
  max-width: 236px;
  margin-left: 16px;
  transition: color 0.3s 0s ease, margin-left 0.3s ease;
  color: #fff;
}

.wezom__logo {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ed1a3b;
  border-radius: 0;
  transition: border-radius 0.3s ease;
}

.wezom__logo svg {
  fill: #fff;
  width: 34px;
  height: 12px;
}

.wezom:hover {
  color: #ed1a3b;
}

.wezom:hover .wezom__logo {
  border-radius: 100%;
}

.wezom:hover .wezom__text {
  color: #ed1a3b;
  margin-left: 4px;
}

.alphabet-block {
  width: 100%;
}

.alphabet-nav {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 14px;
  margin-bottom: 16px;
  border-bottom: 1px solid #ed1a3b;
  font-family: 'DIN Pro';
}

@media only screen and (min-width: 768px) {
  .alphabet-nav {
    padding-bottom: 26px;
    margin-bottom: 24px;
  }
}

.alphabet-nav__item {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 10px;
  position: relative;
  color: #333;
  margin-right: 10px;
  cursor: pointer;
  transition: color 0.3s ease;
  min-width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .alphabet-nav__item {
    font-size: 30px;
    margin-bottom: 16px;
    margin-right: 15px;
  }
}

.alphabet-nav__item:after {
  position: absolute;
  left: 0;
  bottom: -5px;
  right: 0;
  height: 2px;
  background-color: #ed1a3b;
  content: "";
  opacity: 0;
  transition: opacity 0.3s ease;
}

@media only screen and (min-width: 768px) {
  .alphabet-nav__item:after {
    bottom: -16px;
  }
}

.alphabet-nav__item.is-active:after {
  opacity: 1;
}

.alphabet-nav__item:hover {
  color: #ed1a3b;
}

.alphabet-item {
  overflow: hidden;
  page-break-inside: avoid;
  margin-bottom: 26px;
}

.alphabet-item__title {
  font-size: 24px;
  font-family: 'DIN Pro';
  font-weight: bold;
  color: #333;
  padding-bottom: 20px;
  margin-bottom: 24px;
  border-bottom: 1px solid #e6edf6;
  position: relative;
  transition: color 0.3s 0s ease;
}

.alphabet-item__title:after {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 24px;
  height: 2px;
  background-color: #ed1a3b;
  content: "";
  transition: width 0.3s 0s ease;
}

.alphabet-item__list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  padding-right: 10px;
}

.alphabet-item__list-item {
  padding: 0;
  margin-bottom: 4px;
}

.alphabet-item__list-link {
  font-size: 16px;
  line-height: 22px;
  color: #333;
  text-decoration: none;
}

@media only screen and (min-width: 1280px) {
  .alphabet-item__list-link {
    font-size: 18px;
    line-height: 30px;
  }
}

.alphabet-item__list-link:hover {
  color: #ed1a3b;
  text-decoration: underline;
}

.alphabet-item.is-active .alphabet-item__title {
  color: #ed1a3b;
  transition: color 0.3s 0.3s ease;
}

.alphabet-item.is-active .alphabet-item__title:after {
  width: 100%;
  transition: width 0.3s 0.3s ease;
}

.alphabet-grid {
  columns: 1;
}

@media only screen and (min-width: 640px) {
  .alphabet-grid {
    columns: 2;
  }
}

@media only screen and (min-width: 1024px) {
  .alphabet-grid {
    columns: 3;
  }
}

.brend-item {
  height: 240px;
  position: relative;
  box-sizing: border-box;
  backface-visibility: hidden;
  transition: border-color 0.3s ease;
}

@media only screen and (min-width: 1025px) {
  .brend-item {
    height: 240px;
  }
}

@media only screen and (min-width: 1540px) {
  .brend-item {
    height: 338px;
  }
}

.brend-item__image {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease, opacity 0.3s ease;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.brend-item__image img {
  position: absolute;
  max-width: calc(100% - 100px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.brend-item__name-row {
  position: absolute;
  bottom: 0;
  left: 50px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.brend-item__name {
  font-size: 24px;
  font-weight: 300;
  color: #ed1a3b;
  font-family: 'Open Sans';
  margin-bottom: 10px;
}

.brend-item__text {
  font-size: 12px;
  font-weight: 300;
  font-family: 'Open Sans';
  color: #000;
  letter-spacing: 1px;
  transition: color 0.3s ease;
  margin-bottom: 14px;
}

.brend-item__decor {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.brend-item__decor:after, .brend-item__decor:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  opacity: 0;
  transition: opacity 0.3s 0.2s ease, transform 0.3s 0.2s ease;
}

.brend-item__decor:after {
  left: 35px;
  top: 35px;
  border-top: 1px solid #ed1a3b;
  border-left: 1px solid #ed1a3b;
  transform: translate(-25px, -25px);
}

@media only screen and (min-width: 768px) {
  .brend-item__decor:after {
    left: 50px;
    top: 50px;
  }
}

.brend-item__decor:before {
  right: 35px;
  bottom: 35px;
  border-bottom: 1px solid #ed1a3b;
  border-right: 1px solid #ed1a3b;
  transform: translate(25px, 25px);
}

@media only screen and (min-width: 768px) {
  .brend-item__decor:before {
    right: 50px;
    bottom: 50px;
  }
}

.brend-item__decor-button {
  width: 50px;
  height: 50px;
  right: 0;
  bottom: 0;
  background-color: #ed1a3b;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease, background-color 0.3s ease;
}

.brend-item__decor-button svg {
  fill: #fff;
  width: 8px;
  height: 12px;
}

.brend-item:hover .brend-item__image {
  transform: scale(1.06) translate(-50%, -50%);
  opacity: 0.2;
}

.brend-item:hover .brend-item__name-row {
  opacity: 1;
}

.brend-item:hover .brend-item__decor:after, .brend-item:hover .brend-item__decor:before {
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.brend-item:hover .brend-item__decor-button {
  opacity: 1;
}

.brend-item--list {
  border: 1px solid #d0d0d0;
  width: 100%;
  margin-bottom: 2px;
}

.brend-item--list .brend-item__name-row {
  opacity: 1;
}

.brend-item--list .brend-item__name {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.brend-item--list .brend-item__decor-button {
  opacity: 1;
  background-color: #7f7f7f;
}

.brend-item--list:hover {
  border-color: #ed1a3b;
}

.brend-item--list:hover .brend-item__name-row {
  opacity: 1;
}

.brend-item--list:hover .brend-item__text {
  color: #ed1a3b;
}

.brend-item--list:hover .brend-item__name {
  opacity: 1;
}

.brend-item--list:hover .brend-item__decor-button {
  background-color: #ed1a3b;
}

.brends-list {
  margin-bottom: 20px;
}

@media only screen and (min-width: 1025px) {
  .brends-list {
    margin-bottom: 50px;
  }
}

.inner-page {
  margin-bottom: 26px;
  width: 100%;
}

@media only screen and (min-width: 1025px) {
  .inner-page {
    margin-bottom: 56px;
  }
}

.inner-page--page404 {
  padding-top: 20px;
}

@media only screen and (min-width: 768px) {
  .inner-page--page404 {
    padding-top: 50px;
  }
}

.inner-page--page404 .inner-page-doubletitle {
  margin-bottom: 0;
}

.inner-page--item-page .socs-widget {
  margin-bottom: 10px;
}

.inner-page--item-page .inner-page-content--double {
  margin-bottom: 0;
}

.inner-page-doubletitle {
  width: 100%;
  margin-bottom: 20px;
}

@media only screen and (min-width: 640px) {
  .inner-page-doubletitle {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 1025px) {
  .inner-page-doubletitle {
    margin-bottom: 60px;
  }
}

.inner-page-doubletitle__title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  line-height: 100%;
  word-break: break-word;
  word-wrap: break-word;
  font-family: 'DIN Pro';
  margin-bottom: 16px;
}

@media only screen and (min-width: 640px) {
  .inner-page-doubletitle__title {
    font-size: 34px;
  }
}

@media only screen and (min-width: 1024px) {
  .inner-page-doubletitle__title {
    font-size: 38px;
  }
}

@media only screen and (min-width: 1280px) {
  .inner-page-doubletitle__title {
    font-size: 48px;
  }
}

.inner-page-doubletitle__text {
  color: #333;
  font-size: 16px;
  line-height: 22px;
}

@media only screen and (min-width: 1025px) {
  .inner-page-doubletitle__text {
    font-size: 18px;
    line-height: 30px;
  }
}

.inner-page-content-aside {
  width: 100%;
  margin-right: 0;
  display: inline-block;
  text-align: center;
  margin-bottom: 10px;
}

@media only screen and (min-width: 1025px) {
  .inner-page-content-aside {
    width: 31%;
    margin-right: 2%;
    text-align: left;
    margin-bottom: 0;
  }
}

.inner-page-content-aside img {
  max-height: 90vh;
}

.inner-page-content {
  display: inline-block;
  width: 100%;
}

.inner-page-content--double {
  width: 100%;
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .inner-page-content--double {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 1025px) {
  .inner-page-content--double {
    width: 67%;
    margin-bottom: 90px;
  }
}

.semi-slider-block {
  display: flex;
  width: 100%;
  flex-direction: column;
}

@media only screen and (min-width: 1280px) {
  .semi-slider-block {
    flex-direction: row;
  }
}

.semi-slider-block__descr {
  width: 100%;
  min-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #d0d0d0;
  margin-right: 0;
  padding: 20px 2%;
  background-color: #e2e2e2;
  justify-content: space-between;
}

@media only screen and (min-width: 640px) {
  .semi-slider-block__descr {
    background-color: transparent;
  }
}

@media only screen and (min-width: 1280px) {
  .semi-slider-block__descr {
    width: calc(25% - 2px);
    margin-right: 2px;
    min-width: 478px;
    padding: 40px 2%;
  }
}

.semi-slider-block__descr-title {
  font-size: 32px;
  line-height: 100%;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .semi-slider-block__descr-title {
    font-size: 48px;
  }
}

.semi-slider-block__button {
  font-size: 12px;
  color: #333;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 300;
  font-family: 'Open Sans';
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 10px 70px 10px 10px;
}

.semi-slider-block__button svg {
  width: 48px;
  height: 10px;
  fill: #333;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  transition: right 0.3s ease;
}

.semi-slider-block__button span {
  position: relative;
}

.semi-slider-block__button span:before {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  width: 0;
  height: 1px;
  background-color: #333;
  transition: width 0.3s ease;
}

.semi-slider-block__button:hover span:before {
  width: 100%;
}

.semi-slider-block__button:hover svg {
  right: 8px;
}

.semi-slider-block__slider {
  width: 100%;
  border: 1px solid #d0d0d0;
  position: relative;
}

@media only screen and (min-width: 1280px) {
  .semi-slider-block__slider {
    width: calc(100% - 470px);
  }
}

.no-touch .main-slider a:hover {
  border-bottom: 2px solid rgba(76, 92, 98, 0.6);
}

.main-slider a.selected {
  color: #00A7E1;
  border-bottom: 2px solid rgba(0, 167, 225, 0.4);
}

.no-touch .main-slider a.selected:hover {
  border-bottom: 2px solid rgba(0, 167, 225, 0.4);
}

.main-slider-wrapper {
  position: relative;
  width: 100%;
  margin: 0;
  height: 300px;
  margin-bottom: 78px;
}

@media only screen and (min-width: 768px) {
  .main-slider-wrapper {
    height: 400px;
  }
}

@media only screen and (min-width: 1025px) {
  .main-slider-wrapper {
    height: 520px;
  }
}

@media only screen and (min-width: 1280px) {
  .main-slider-wrapper {
    height: 600px;
  }
}

@media only screen and (min-width: 1540px) {
  .main-slider-wrapper {
    height: 672px;
  }
}

.main-slider-wrapper:hover .main-slider-navigation {
  opacity: 1;
}

.main-slider {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.main-slider-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  overflow: hidden;
  height: 100%;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.8);
}

.main-slider-item.is-animating {
  z-index: 3;
  opacity: 1;
}

.main-slider-item.is-animating .main-slider-item__content {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.main-slider-item.is-visible {
  position: relative;
  z-index: 2;
  opacity: 1;
}

.main-slider-item.is-visible .main-slider-item__content {
  transition: opacity 0.4s ease;
  opacity: 1;
}

.main-slider-item__content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px 14px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  opacity: 0;
  transition: opacity 0.2s ease;
}

@media only screen and (min-width: 768px) {
  .main-slider-item__content {
    padding: 40px 34px;
  }
}

.main-slider-item__button {
  padding: 6px 20px;
  height: 40px;
  background-color: #ed1a3b;
  text-decoration: none;
  display: flex;
  font-family: 'Open Sans';
  align-items: center;
  transition: background-color 0.3s ease;
}

@media only screen and (min-width: 768px) {
  .main-slider-item__button {
    height: 50px;
  }
}

.main-slider-item__button:hover {
  transition: background-color 0.3s ease;
  background-color: #4295d1;
}

.main-slider-item__button span {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 300;
}

.main-slider-item__button svg {
  fill: #fff;
  width: 50px;
  height: 12px;
  margin-left: 38px;
}

.main-slider-item__text {
  font-size: 32px;
  line-height: 100%;
  color: #fff;
  font-weight: bold;
  font-family: 'DIN Pro';
  max-width: 868px;
  margin-bottom: 28px;
}

@media only screen and (min-width: 640px) {
  .main-slider-item__text {
    font-size: 44px;
  }
}

@media only screen and (min-width: 1025px) {
  .main-slider-item__text {
    font-size: 60px;
  }
}

.main-slider-item__image {
  height: 100%;
  width: 100%;
  position: absolute;
  width: 1914px;
  margin: 0;
  height: 300px;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 768px) {
  .main-slider-item__image {
    height: 400px;
  }
}

@media only screen and (min-width: 1025px) {
  .main-slider-item__image {
    height: 520px;
  }
}

@media only screen and (min-width: 1280px) {
  .main-slider-item__image {
    height: 600px;
  }
}

@media only screen and (min-width: 1540px) {
  .main-slider-item__image {
    height: 672px;
  }
}

.main-slider-item__image svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main-slider-item__image:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 35%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 50%, rgba(0, 0, 0, 0.7) 80%, rgba(0, 0, 0, 0.8) 100%);
}

.main-slider-navigation {
  opacity: 0;
  z-index: 15;
  transition: opacity 0.3s ease;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  width: 100%;
  list-style-type: none;
}

.main-slider-navigation__item {
  position: absolute;
  z-index: 3;
  bottom: 80px;
  margin: auto 0;
  top: 0;
  height: 40px;
  width: 30px;
  background-color: #68737a;
  user-select: none;
  transition: background-color	 0.3s ease;
}

@media only screen and (min-width: 768px) {
  .main-slider-navigation__item {
    height: 100px;
    width: 50px;
  }
}

.main-slider-navigation__item svg {
  width: 20px;
  height: 30px;
  fill: #fff;
}

.main-slider-navigation__item--left {
  left: 0;
}

.main-slider-navigation__item--right {
  right: 0;
}

.main-slider-navigation__item:hover {
  background-color: #ed1a3b;
}

.main-slider-navigation__link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  color: transparent;
  -webkit-transition: -webkit-transform 0.2s;
  -moz-transition: -moz-transform 0.2s;
  transition: transform 0.2s;
}

.main-slider-controls {
  position: absolute;
  bottom: -25px;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 3;
  text-align: center;
  width: 90%;
  margin: 0;
}

.main-slider-controls__item {
  display: inline-block;
  margin-right: 12px;
  color: #68737a;
  cursor: pointer;
}

.main-slider-controls__item:last-of-type {
  margin-right: 0;
}

.main-slider-controls__item.selected {
  color: #ed1a3b;
  cursor: default;
}

.main-slider-controls__link {
  display: block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  height: 5px;
  width: 48px;
  background-color: currentColor;
}

.main-slider-controls::after {
  clear: both;
  content: "";
  display: table;
}

.no-touch .main-slider-controls a:hover {
  background-color: #ffffff;
}

.to-top {
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 0;
  height: 50px;
}

.to-top__text {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  font-family: 'Open Sans';
  margin-right: 10px;
  color: #ed1a3b;
  display: none;
  transition: opacity 0.3s ease, color 0.3s ease;
}

@media only screen and (min-width: 640px) {
  .to-top__text {
    display: block;
  }
}

.to-top__button {
  width: 50px;
  height: 50px;
  background-color: #ed1a3b;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  transition: left 0.3s ease, background-color 0.3s ease;
}

.to-top__button svg {
  width: 12px;
  height: 8px;
  fill: #fff;
}

.to-top:hover .to-top__text {
  color: #4295d1;
}

.to-top:hover .to-top__button {
  background-color: #4295d1;
}

.touchevents:hover .to-top__text {
  color: #ed1a3b;
}

.touchevents:hover .to-top__button {
  background-color: #ed1a3b;
}

.preheader {
  background-color: #000;
  position: relative;
  height: 140px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;
  display: none;
}

@media only screen and (min-width: 768px) {
  .preheader {
    height: 180px;
    display: block;
  }
}

@media only screen and (min-width: 1025px) {
  .preheader {
    height: 220px;
  }
}

.preheader__decor {
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
}

.preheader__decor svg {
  width: 590px;
  stroke: #fff;
  fill: transparent;
  opacity: 0.1;
  height: 470px;
  stroke-width: 0.3px;
}

.preheader__image {
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.preheader__image img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 1920px;
  min-width: 1920px;
  width: 1920px;
}

.brends-catalog-widget {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.brends-catalog-widget__item {
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 100px;
  padding: 10px 14px;
  position: relative;
  margin-bottom: 2px;
}

@media only screen and (min-width: 640px) {
  .brends-catalog-widget__item {
    padding: 30px 38px;
  }
}

.brends-catalog-widget__item:hover .brends-catalog-widget__item-num span {
  opacity: 1;
}

.brends-catalog-widget__item:hover .brends-catalog-widget__image img {
  opacity: 0.3;
  transform: scale(1.05) translate(-50%, -50%);
}

.brends-catalog-widget__item-num {
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
  color: #fff;
  position: relative;
  padding-bottom: 6px;
  z-index: 2;
  margin-right: 12px;
}

@media only screen and (min-width: 640px) {
  .brends-catalog-widget__item-num {
    margin-right: 34px;
    line-height: 30px;
    font-size: 24px;
  }
}

.brends-catalog-widget__item-num span {
  transition: opacity 0.3s ease;
  opacity: 0.2;
}

.brends-catalog-widget__item-num:after {
  position: absolute;
  background-color: #fff;
  content: "";
  width: 100%;
  bottom: 2px;
  left: 0;
  height: 2px;
}

.brends-catalog-widget__title {
  font-size: 20px;
  color: #fff;
  line-height: 24px;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 640px) {
  .brends-catalog-widget__title {
    line-height: 36px;
    font-size: 30px;
  }
}

.brends-catalog-widget__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: hidden;
}

.brends-catalog-widget__image img {
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0.5;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translate(-50%, -50%);
  max-width: initial;
  max-height: initial;
  min-height: 100%;
}

.socs-widget {
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 640px) {
  .socs-widget {
    flex-direction: row;
  }
}

.socs-widget__text {
  font-weight: bold;
  font-size: 20px;
  color: #333;
  margin-right: 0;
  font-family: 'DIN Pro';
  margin-bottom: 10px;
}

@media only screen and (min-width: 640px) {
  .socs-widget__text {
    margin-bottom: 0;
    font-size: 24px;
    margin-right: 20px;
  }
}

.subnav-list {
  background-color: #edebe9;
  padding: 0;
  margin: 0 0 18px 0;
  list-style-type: none;
  display: none;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .subnav-list {
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .subnav-list {
    margin: 0;
  }
}

.subnav-list__item {
  width: 100%;
}

.subnav-list__link {
  padding: 8px 18px 8px 18px;
  line-height: 22px;
  font-size: 16px;
  color: #333;
  transition: color 0.3s ease, background-color 0.3s ease;
  text-decoration: none;
  display: block;
}

@media only screen and (min-width: 1280px) {
  .subnav-list__link {
    padding: 14px 36px 14px 36px;
    line-height: 30px;
    font-size: 18px;
  }
}

.subnav-list__link.is-active {
  background-color: #ed1a3b;
  color: #fff;
}

.subnav-list__link:hover {
  color: #fff;
  background-color: #ed1a3b;
}

.subnav-items-list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.subnav-item {
  display: flex;
  min-height: 98px;
  text-decoration: none;
  border: 1px solid #d0d0d0;
  transition: border-color 0.3s ease;
  margin-bottom: 2px;
}

.subnav-item__num {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  font-family: 'DIN Pro';
}

.subnav-item__text {
  font-size: 16px;
  transition: color 0.3s ease;
  color: #333;
  align-items: center;
  display: flex;
  position: relative;
  line-height: 22px;
}

@media only screen and (min-width: 768px) {
  .subnav-item__text {
    font-size: 18px;
    line-height: 30px;
  }
}

.subnav-item__text:after {
  position: absolute;
  content: "";
  width: 24px;
  bottom: 0;
  height: 2px;
  background-color: #ed1a3b;
  left: 0;
}

.subnav-item__image {
  margin-right: 8px;
  position: relative;
  width: 66px;
  overflow: hidden;
  height: 96px;
  background-color: #000;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #d0d0d0;
}

@media only screen and (min-width: 640px) {
  .subnav-item__image {
    width: 174px;
    margin-right: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .subnav-item__image {
    margin-right: 38px;
  }
}

.subnav-item__image img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  transition: transform 0.3s ease;
  max-width: initial;
  min-height: 100%;
}

@media only screen and (min-width: 640px) {
  .subnav-item__image img {
    max-width: 100%;
    min-height: initial;
  }
}

.subnav-item__decor {
  width: 118px;
  position: relative;
  display: flex;
  align-items: center;
}

.subnav-item__decor span {
  margin-right: auto;
  width: 53px;
  height: 1px;
  background-color: #fff;
  display: block;
  opacity: 0;
  transform: scaleX(0.2);
  transition: opacity 0.3s ease, transform 0.3s 0.22s ease;
  transform-origin: left;
}

.subnav-item__decor svg {
  width: 48px;
  height: 12px;
  fill: #fff;
  margin-left: auto;
  opacity: 0;
  transform: scaleX(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform-origin: left;
}

.subnav-item:hover {
  border-color: #ed1a3b;
}

.subnav-item:hover .subnav-item__decor span {
  opacity: 1;
  transform: scaleX(1);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.subnav-item:hover .subnav-item__decor svg {
  opacity: 1;
  transform: scaleX(1);
  transition: opacity 0.3s ease, transform 0.3s 0.22s ease;
}

.subnav-item:hover .subnav-item__image img {
  transform: scale(1.05) translate(-50%, -50%);
}

.subnav-item:hover .subnav-item__text {
  color: #ed1a3b;
}

.subnav-widget {
  margin-bottom: 20px;
}

@media only screen and (min-width: 1024px) {
  .subnav-widget {
    margin-bottom: 50px;
  }
}

.map-widget {
  width: 100%;
  margin-bottom: 12px;
}

.map-widget__descr {
  width: 100%;
  position: relative;
  padding: 64px 20px 64px 8.9%;
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1025px) {
  .map-widget__descr {
    font-size: 18px;
    line-height: 20px;
  }
}

.map-widget__decor-bg {
  width: 100%;
  height: 100%;
  max-height: 700px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.1;
}

.map-widget__decor-bg svg {
  height: 614px;
  width: 650px;
  stroke: #fff;
  fill: transparent;
  position: absolute;
  right: -6px;
  bottom: 6px;
  stroke-width: 0.3px;
}

.map-widget__city {
  font-size: 36px;
  font-family: 'DIN Pro';
  font-weight: bold;
  padding-bottom: 18px;
  margin-bottom: 30px;
  position: relative;
}

@media only screen and (min-width: 1025px) {
  .map-widget__city {
    margin-bottom: 46px;
    padding-bottom: 24px;
  }
}

.map-widget__city:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 24px;
  height: 2px;
  background-color: #fff;
  content: "";
}

.map-widget__addres-text {
  font-size: 20px;
  font-weight: bold;
  font-family: 'DIN Pro';
  line-height: 114%;
}

@media only screen and (min-width: 1025px) {
  .map-widget__addres-text {
    font-size: 24px;
  }
}

.map-widget__phones-wrapper {
  display: flex;
  flex-direction: column;
}

.map-widget__fax {
  display: flex;
  flex-direction: column;
}

.map-widget__fax-text {
  margin-bottom: 4px;
}

.map-widget__link {
  display: flex;
  color: currentColor;
  align-items: center;
}

.map-widget__work-text {
  display: flex;
  flex-wrap: wrap;
  max-width: 210px;
  width: 100%;
}

.map-widget__work-text span {
  width: 50%;
  display: inline-flex;
  margin-bottom: 6px;
}

.map-widget__work-text span:nth-child(odd):after {
  position: relative;
  content: ":";
  margin-left: auto;
  font-size: 18px;
  display: inline-block;
  margin-right: 14px;
  color: currentColor;
}

.map-widget__addres {
  display: flex;
  margin-bottom: 20px;
  z-index: 1;
  position: relative;
}

@media only screen and (min-width: 1025px) {
  .map-widget__addres {
    margin-bottom: 30px;
  }
}

.map-widget__phone {
  font-size: 20px;
  line-height: 100%;
  font-weight: bold;
  color: currentColor;
  font-family: 'DIN Pro';
  text-decoration: none;
  margin-bottom: 4px;
}

@media only screen and (min-width: 1025px) {
  .map-widget__phone {
    font-size: 24px;
  }
}

.map-widget__phones {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.map-widget__phones-block {
  display: flex;
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
}

@media only screen and (min-width: 1025px) {
  .map-widget__phones-block {
    margin-bottom: 50px;
  }
}

.map-widget__work-data {
  display: flex;
  margin-bottom: 10px;
  z-index: 1;
  position: relative;
}

@media only screen and (min-width: 1025px) {
  .map-widget__work-data {
    margin-bottom: 20px;
  }
}

.map-widget__skype {
  display: flex;
  margin-bottom: 10px;
  z-index: 1;
  position: relative;
}

@media only screen and (min-width: 1025px) {
  .map-widget__skype {
    margin-bottom: 20px;
  }
}

.map-widget__mail {
  display: flex;
  margin-bottom: 10px;
  z-index: 1;
  position: relative;
}

@media only screen and (min-width: 1025px) {
  .map-widget__mail {
    margin-bottom: 20px;
  }
}

.map-widget__map {
  width: 100%;
  border: 1px solid;
  position: relative;
  min-height: 300px;
}

.map-widget__decor {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.map-widget__decor-image {
  position: absolute;
  width: 97px;
  height: 100px;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  opacity: 0.2;
}

.map-widget__image {
  width: 28px;
  margin-right: 12px;
  opacity: 0.55;
}

@media only screen and (min-width: 1280px) {
  .map-widget__image {
    margin-right: 22px;
    width: 34px;
  }
}

.map-widget__image svg {
  max-width: 28px;
  max-height: 28px;
  fill: #fff;
}

@media only screen and (min-width: 1280px) {
  .map-widget__image svg {
    max-width: 32px;
    max-height: 32px;
  }
}

.map-widget--red a:hover {
  color: #b3b9bc;
}

.map-widget--red .map-widget__descr {
  background-color: #ed1a3b;
}

.map-widget--red .map-widget__map {
  border-color: #ed1a3b;
}

.map-widget--blue a:hover {
  color: #a7a7a7;
}

.map-widget--blue .map-widget__descr {
  background-color: #0c4da2;
}

.map-widget--blue .map-widget__map {
  border-color: #0c4da2;
}

.map-widget--lblue a:hover {
  color: #b3b9bc;
}

.map-widget--lblue .map-widget__descr {
  background-color: #4295d1;
}

.map-widget--lblue .map-widget__map {
  border-color: #4295d1;
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.inview {
  opacity: 0;
  transition: opacity 0.2s 0.1s ease-out;
  pointer-events: none;
  will-change: transform;
}

.inview.is-inviewed {
  opacity: 1;
  pointer-events: auto;
}

.inview--from-left {
  transform: translate(-15%, -2%);
  transition: opacity 0.4s 0.1s ease-out, transform 0.2s 0.1s ease-out;
}

.inview--from-left.is-inviewed {
  transform: translate(0, 0);
}

.inview--from-bottom {
  transform: translate(0, 60px);
  transition: opacity 0.4s 0.1s ease-out, transform 0.2s 0.1s ease-out;
}

.inview--from-bottom.is-inviewed {
  transform: translate(0, 0);
}

.inview--from-top {
  transform: translate(0, -60px);
  transition: opacity 0.4s 0.1s ease-out, transform 0.2s 0.1s ease-out;
}

.inview--from-top.is-inviewed {
  transform: translate(0, 0);
}

.inview--from-right {
  transform: translate(15%, -2%);
  transition: opacity 0.4s 0.1s ease-out, transform 0.2s 0.1s ease-out;
}

.inview--from-right.is-inviewed {
  transform: translate(0, 0);
}

.page404-content {
  max-width: 530px;
  width: 100%;
  margin-bottom: 20px;
  order: 2;
}

@media only screen and (min-width: 1024px) {
  .page404-content {
    order: 1;
  }
}

@media only screen and (min-width: 1025px) {
  .page404-content {
    margin-bottom: 115px;
  }
}

.page404-image {
  order: 1;
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .page404-image {
    order: 2;
  }
}

.faq {
  width: 100%;
}

.faq__item {
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 20px;
  color: #333;
}

@media only screen and (min-width: 640px) {
  .faq__item {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}

.faq__question {
  padding: 16px 8px 16px 2.5%;
  display: flex;
  width: 100%;
  flex-direction: column;
}

@media only screen and (min-width: 640px) {
  .faq__question {
    flex-direction: row;
  }
}

.faq__answer {
  background-color: #edebe9;
  padding: 16px 8px 16px 2.5%;
  display: flex;
  width: 100%;
  flex-direction: column;
}

@media only screen and (min-width: 640px) {
  .faq__answer {
    flex-direction: row;
  }
}

.faq__left {
  flex-shrink: 0;
  min-width: 125px;
  width: 100%;
  margin-bottom: 10px;
}

@media only screen and (min-width: 640px) {
  .faq__left {
    width: 26%;
    margin-bottom: 0;
  }
}

.faq__right {
  width: 100%;
}

@media only screen and (min-width: 640px) {
  .faq__right {
    width: calc(100% - 240px);
  }
}

.catalog-grid {
  display: flex;
  width: 100%;
}

.items-grid {
  display: flex;
  flex-direction: column;
  margin-bottom: 54px;
}

.items-grid-wrapper {
  width: 100%;
}

@media only screen and (min-width: 1025px) {
  .items-grid-wrapper {
    width: 82%;
  }
}

@media only screen and (min-width: 1540px) {
  .items-grid-wrapper {
    width: 76%;
  }
}

.catalog-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2px;
  transition: border-color 0.3s ease, opacity 1.8s 0.1s ease-out, transform 0.8s 0.1s ease-out;
  border: 1px solid #d0d0d0;
}

.catalog-item__top {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  flex-wrap: wrap;
}

@media only screen and (min-width: 1280px) {
  .catalog-item__top {
    flex-wrap: nowrap;
  }
}

.catalog-item__descr-block {
  padding-top: 10px;
  max-width: 636px;
  min-width: 300px;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
}

@media only screen and (min-width: 640px) {
  .catalog-item__descr-block {
    width: calc(100% - 250px);
    padding-left: 0;
    padding-right: 20px;
    padding-top: 40px;
  }
}

@media only screen and (min-width: 1280px) {
  .catalog-item__descr-block {
    width: 50%;
  }
}

.catalog-item__stats {
  display: flex;
  align-items: center;
  padding-top: 20px;
  width: 100%;
  flex-direction: column;
}

@media only screen and (min-width: 480px) {
  .catalog-item__stats {
    flex-direction: row;
  }
}

@media only screen and (min-width: 1280px) {
  .catalog-item__stats {
    width: 38%;
    padding-top: 90px;
  }
}

.catalog-item__bottom {
  display: flex;
  padding-bottom: 24px;
  align-items: center;
  flex-direction: column;
}

@media only screen and (min-width: 640px) {
  .catalog-item__bottom {
    align-items: flex-end;
    flex-direction: row;
  }
}

.catalog-item__bottom .stats-widget__item {
  padding-left: 0;
  max-width: 100%;
  margin-right: 0;
  padding-right: 0;
  margin-bottom: 14px;
  border: none;
}

@media only screen and (min-width: 640px) {
  .catalog-item__bottom .stats-widget__item {
    padding-left: 34px;
    margin-bottom: 0;
    max-width: 230px;
    margin-right: 14px;
  }
}

.catalog-item__bottom .stats-widget__item .stats-widget__title {
  font-size: 14px;
  font-weight: 300;
  padding-bottom: 0;
  margin-bottom: 16px;
  line-height: 18px;
}

.catalog-item__bottom .buy-widget {
  margin-bottom: 0;
  margin-right: 0;
  padding: 0 10px;
}

@media only screen and (min-width: 640px) {
  .catalog-item__bottom .buy-widget {
    padding: 0;
  }
}

.catalog-item__bottom .buy-widget__form .form__group {
  margin-bottom: 14px;
}

@media only screen and (min-width: 640px) {
  .catalog-item__bottom .buy-widget__form .form__group {
    margin-bottom: 0;
  }
}

.catalog-item__bottom .buy-widget__form .button {
  margin-bottom: 0;
}

.catalog-item__text {
  font-size: 16px;
  line-height: 18px;
  color: #333;
  overflow: hidden;
  max-height: 126px;
}

@media only screen and (min-width: 1024px) {
  .catalog-item__text {
    font-size: 18px;
    line-height: 24px;
    max-height: 118px;
  }
}

.catalog-item__title {
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 10px;
  padding-bottom: 8px;
  position: relative;
  text-decoration: none;
  color: #333;
  display: block;
}

@media only screen and (min-width: 1024px) {
  .catalog-item__title {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 16px;
    padding-bottom: 15px;
  }
}

.catalog-item__title:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 24px;
  height: 2px;
  background-color: #ed1a3b;
  content: "";
}

.catalog-item__title:hover {
  color: #ed1a3b;
}

.catalog-item__image {
  flex-shrink: 0;
  width: 210px;
  height: 180px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

@media only screen and (min-width: 640px) {
  .catalog-item__image {
    margin: 0 14px 0 0;
    width: 230px;
    height: 210px;
  }
}

.catalog-item__image img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.catalog-item:hover {
  border-color: #ed1a3b;
}

.search-block {
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .search-block {
    margin-bottom: 50px;
  }
}

.search-block__title {
  margin-bottom: 20px;
  font-size: 18px;
  color: #333;
}

@media only screen and (min-width: 768px) {
  .search-block__title {
    margin-bottom: 40px;
    font-size: 22px;
  }
}

.search-block__form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media only screen and (min-width: 640px) {
  .search-block__form {
    justify-content: space-between;
  }
}

.search-block__form .form__group {
  width: 100%;
}

@media only screen and (min-width: 640px) {
  .search-block__form .form__group {
    width: calc(100% - 220px);
  }
}

.search-block__form .button {
  margin-bottom: 18px;
}

@media only screen and (min-width: 768px) {
  .search-block__form .button {
    margin-bottom: 28px;
  }
}

.search-block__grid .search-item:nth-child(1) {
  border-top: 1px solid #ccd3dd;
}

.search-item {
  width: 100%;
  padding: 36px 0 30px;
  border-bottom: 1px solid #ccd3dd;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #333;
  transition: border 0.3s ease, color 0.3s ease, opacity 0.3s 0.1s ease;
}

.search-item__title {
  font-size: 18px;
  line-height: 22px;
  transition: color 0.3s ease;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 12px;
}

.search-item__title:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 24px;
  height: 2px;
  content: "";
  background-color: #ed1a3b;
}

@media only screen and (min-width: 768px) {
  .search-item__title {
    font-size: 22px;
    line-height: 28px;
  }
}

.search-item__chart {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 1px;
  margin-bottom: 16px;
}

.search-item__text {
  font-size: 16px;
  line-height: 20px;
}

@media only screen and (min-width: 768px) {
  .search-item__text {
    font-size: 18px;
    line-height: 24px;
  }
}

.search-item:hover {
  border-bottom: 1px solid #ed1a3b;
}

.search-item:hover .search-item__title {
  color: #ed1a3b;
}

.button {
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  outline: none;
  background: transparent;
  text-decoration: none;
}

.button--main .button__content {
  color: #fff;
  background-color: #ed1a3b;
  letter-spacing: 1px;
  font-weight: 300;
  text-transform: uppercase;
  padding: 0 20px;
  transition: background-color 0.3s ease;
}

.button--main .button__text {
  margin-left: 0;
  transition: margin 0.3s ease;
}

.button--main .button__icon {
  margin-right: 0;
  transition: margin 0.3s ease;
}

.button--main:hover .button__content {
  background-color: #0c4da2;
}

.button--main:hover .button__text {
  margin-left: 10px;
}

.button--main:hover .button__icon {
  margin-right: 10px;
}

.button--main:active .button__content {
  background-color: #4295d1;
  transition: background-color 0s ease;
}

.button__content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
}

.button--disabled {
  pointer-events: none;
}

.button__icon {
  width: 50px;
  height: 12px;
  margin-left: auto;
  fill: currentColor;
}

.button--w100 {
  width: 100%;
}

.button--w100 .button__content {
  width: 100%;
}

.button--sub .button__content {
  color: #ed1a3b;
  background-color: transparent;
  border: 1px solid #ed1a3b;
  letter-spacing: 1px;
  font-weight: 300;
  text-transform: uppercase;
  padding: 0 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.button--sub .button__text {
  margin-left: 0;
  transition: margin 0.3s ease;
}

.button--sub .button__icon {
  margin-right: 0;
  transition: margin 0.3s ease;
}

.button--sub:hover .button__content {
  background-color: #ed1a3b;
  color: #fff;
}

.button--sub:hover .button__text {
  margin-left: 10px;
}

.button--sub:hover .button__icon {
  margin-right: 10px;
}

.button--sub:active .button__content {
  background-color: #fff;
  transition: background-color 0s ease;
  color: #ed1a3b;
}

.button--sm {
  width: 174px;
  height: 50px;
}

.button--sm .button__content {
  height: 50px;
  font-size: 16px;
  width: 174px;
}

.button--md {
  width: 210px;
  height: 40px;
}

.button--md .button__content {
  height: 40px;
  font-size: 12px;
  width: 210px;
}

.button--mdb {
  width: 210px;
  height: 50px;
}

.button--mdb .button__content {
  height: 50px;
  font-size: 12px;
  width: 210px;
}

.buy-widget {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100%;
}

@media only screen and (min-width: 640px) {
  .buy-widget {
    width: auto;
    width: initial;
  }
}

.buy-widget__form {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media only screen and (min-width: 640px) {
  .buy-widget__form {
    width: 464px;
  }
}

@media only screen and (min-width: 640px) {
  .buy-widget__form {
    flex-direction: row;
    align-items: flex-end;
  }
}

.buy-widget__form .form__group {
  width: 100%;
}

@media only screen and (min-width: 640px) {
  .buy-widget__form .form__group {
    width: calc(100% - 194px);
  }
}

.buy-widget__form .button {
  margin-bottom: 18px;
}

@media only screen and (min-width: 768px) {
  .buy-widget__form .button {
    margin-bottom: 28px;
  }
}

.thumbs-slider-wrapper {
  margin-bottom: 20px;
  position: relative;
}

.thumbs-slider-wrapper:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 105px;
  width: 100%;
  z-index: 2;
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

@media only screen and (min-width: 768px) {
  .thumbs-slider-wrapper:after {
    height: 130px;
  }
}

.thumbs-slider-wrapper.is-nichego-net:after {
  display: none;
}

@media only screen and (min-width: 640px) {
  .thumbs-slider-wrapper {
    margin-bottom: 36px;
  }
}

.thumbs-slider-wrapper--item .thumbs-slider__item {
  width: 123px;
  height: 148px;
}

.thumbs-slider-wrapper--item .thumbs-slider {
  position: relative;
  bottom: 0;
  width: calc(100% - 86px);
  left: 0;
  margin: 0 auto;
}

@media only screen and (min-width: 640px) {
  .thumbs-slider-wrapper--item .thumbs-slider {
    width: calc(100% - 48px);
  }
}

.thumbs-slider-wrapper--item .thumbs-slider-main-image {
  margin-bottom: 18px;
  max-width: 430px;
  height: 380px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 768px) {
  .thumbs-slider-wrapper--item .thumbs-slider-main-image {
    margin-bottom: 38px;
    height: 420px;
  }
}

@media only screen and (min-width: 1025px) {
  .thumbs-slider-wrapper--item .thumbs-slider-main-image {
    height: 520px;
  }
}

.thumbs-slider-wrapper--item .slider-custom-arrow {
  width: 20px;
  height: 32px;
  color: #333;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.thumbs-slider-wrapper--item .slider-custom-arrow--left {
  left: 0 !important;
  top: auto !important;
  bottom: 62px !important;
  right: auto !important;
}

.thumbs-slider-wrapper--item .slider-custom-arrow--right {
  right: 0 !important;
  top: auto !important;
  bottom: 52px !important;
  left: auto !important;
}

.thumbs-slider-wrapper--item .slider-custom-arrow svg {
  width: 14px;
  height: 22px;
  fill: currentColor !important;
}

.thumbs-slider-wrapper--item .slider-custom-arrow:hover {
  background-color: transparent;
  color: #ed1a3b;
}

.thumbs-slider-wrapper .slider-custom-arrow--left {
  bottom: 50px;
  right: 0;
  top: auto;
  left: auto;
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

@media only screen and (min-width: 480px) {
  .thumbs-slider-wrapper .slider-custom-arrow--left {
    bottom: 60px;
    right: 10px;
  }
}

@media only screen and (min-width: 1540px) {
  .thumbs-slider-wrapper .slider-custom-arrow--left {
    bottom: 10px;
    right: 110px;
  }
}

.thumbs-slider-wrapper .slider-custom-arrow--right {
  bottom: 0px;
  right: 0;
  top: auto;
  left: auto;
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

@media only screen and (min-width: 480px) {
  .thumbs-slider-wrapper .slider-custom-arrow--right {
    bottom: 10px;
    right: 10px;
  }
}

.thumbs-slider-wrapper:hover .thumbs-slider,
.thumbs-slider-wrapper:hover .slider-custom-arrow--right,
.thumbs-slider-wrapper:hover .slider-custom-arrow--left, .thumbs-slider-wrapper:hover:after {
  opacity: 1;
  transform: translateY(0);
}

.thumbs-slider-main-image {
  width: 100%;
  height: 320px;
  overflow: hidden;
  position: relative;
  background-color: transparent;
  display: block;
  outline: none;
}

@media only screen and (min-width: 480px) {
  .thumbs-slider-main-image {
    height: 486px;
  }
}

@media only screen and (min-width: 768px) {
  .thumbs-slider-main-image {
    height: 686px;
  }
}

.thumbs-slider-main-image img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: initial;
  max-height: 100%;
}

.thumbs-slider {
  bottom: 10px;
  left: 0;
  width: calc(100% - 56px);
  position: relative;
  margin-right: auto;
  margin-left: 0;
  z-index: 5;
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

@media only screen and (min-width: 480px) {
  .thumbs-slider {
    position: absolute;
    width: calc(100% - 70px);
  }
}

@media only screen and (min-width: 1540px) {
  .thumbs-slider {
    width: calc(100% - 212px);
  }
}

.thumbs-slider__item {
  cursor: pointer;
  width: 92px;
  height: 66px;
  margin-right: 6px;
  border: 1px solid transparent;
}

@media only screen and (min-width: 480px) {
  .thumbs-slider__item {
    width: 136px;
    height: 97px;
  }
}

.thumbs-slider__item.is-active {
  cursor: default;
  border-color: #ed1a3b;
}

.stats-widget {
  width: 100%;
  border-left: 1px solid #e6edf6;
  border-right: 1px solid #e6edf6;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .stats-widget {
    margin-bottom: 35px;
  }
}

.stats-widget__item {
  width: 100%;
  border-left: 1px solid #e6edf6;
  border-right: 1px solid #e6edf6;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  flex-direction: column;
}

.stats-widget__title {
  margin-bottom: auto;
  font-size: 16px;
  line-height: 18px;
  color: #333;
  padding-bottom: 24px;
  width: 100%;
}

@media only screen and (min-width: 1540px) {
  .stats-widget__title {
    font-size: 18px;
    line-height: 24px;
  }
}

.stats-widget__text {
  font-weight: bold;
  color: #333;
  font-size: 18px;
  padding-bottom: 14px;
  width: 100%;
}

.stats-widget__text--icon {
  font-weight: normal;
  display: flex;
  text-decoration: none;
  align-items: center;
  padding-bottom: 4px;
}

.stats-widget__text--icon svg {
  width: 38px;
  margin-right: 12px;
  height: 40px;
  fill: #333;
}

.stats-widget__text--icon:hover {
  color: #ed1a3b;
}

.stats-widget__text--icon:hover svg {
  fill: #ed1a3b;
}

.filter-submenu {
  list-style-type: none;
  padding: 0 0 20px 0;
  margin: 0 0 44px;
  display: none;
}

@media only screen and (min-width: 1025px) {
  .filter-submenu {
    display: block;
  }
}

.filter-submenu__prev-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.filter-submenu__prev-list .filter-submenu__item .filter-submenu__item .filter-submenu__link, .filter-submenu__prev-list .filter-submenu__prev .filter-submenu__item .filter-submenu__link {
  padding-left: 50px;
}

.filter-submenu__prev-list .filter-submenu__item .filter-submenu__prev .filter-submenu__link, .filter-submenu__prev-list .filter-submenu__prev .filter-submenu__prev .filter-submenu__link {
  padding-left: 22px;
}

.filter-submenu__prev-list .filter-submenu__item .filter-submenu__prev .filter-submenu__item .filter-submenu__link, .filter-submenu__prev-list .filter-submenu__prev .filter-submenu__prev .filter-submenu__item .filter-submenu__link {
  padding-left: 72px;
}

.filter-submenu__prev-list .filter-submenu__item .filter-submenu__prev .filter-submenu__prev .filter-submenu__link, .filter-submenu__prev-list .filter-submenu__prev .filter-submenu__prev .filter-submenu__prev .filter-submenu__link {
  padding-left: 44px;
}

.filter-submenu__link {
  color: #000;
  text-decoration: none;
  font-size: 18px;
  line-height: 100%;
  padding: 14px 0;
  display: block;
}

.filter-submenu__link svg {
  width: 10px;
  height: 14px;
  fill: #ed1a3b;
  margin-right: 14px;
}

.filter-submenu__link:hover {
  color: #ed1a3b;
}

.filter-submenu__link.is-active {
  color: #ed1a3b;
  pointer-events: none;
}

.filter-submenu__prev {
  border-top: 1px solid #e6edf6;
  border-bottom: 1px solid #e6edf6;
}

.filter {
  min-width: 260px;
  width: 300px;
  position: fixed;
  margin-right: 10px;
  flex-shrink: 0;
  background-color: #fff;
  z-index: 15;
  transform: translateX(-400%) scale(0.9);
  opacity: 0.5;
  top: 60px;
  font-family: "Open Sans";
  color: #333;
  left: 0;
  padding: 10px;
  transition: transform 0.1s ease, z-index 0s 0.1s, opacity 0.1s;
  height: calc(100% - 60px);
  overflow: overlay;
  padding-bottom: 30px;
  min-height: calc(100% - 60px);
  display: block;
}

@media only screen and (min-width: 640px) {
  .filter {
    top: 80px;
  }
}

@media only screen and (min-width: 1025px) {
  .filter {
    width: 350px;
  }
}

@media only screen and (min-width: 1540px) {
  .filter {
    width: 450px;
  }
}

@media only screen and (min-width: 1025px) {
  .filter {
    top: 0;
    position: relative;
    transform: translate(0);
    height: auto;
    opacity: 1;
    padding: 0;
  }
}

.filter__main-title {
  font-size: 30px;
  font-weight: bold;
  line-height: 100%;
  margin-bottom: 32px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6edf6;
  font-family: 'DIN Pro';
}

.filter__items-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 158px;
  overflow-x: hidden;
  overflow-y: auto;
}

.filter__items-list.is-active {
  max-height: 1200px;
}

.filter__show-more {
  color: #333;
  font-size: 12px;
  transition: color 0.3s ease;
  cursor: pointer;
  margin-top: 18px;
}

.filter__show-more:hover {
  color: #ed1a3b;
}

.filter__show-more.is-disabled {
  display: none;
}

.filter__show-more.is-active .filter__show-more-icon {
  transform: scale(-1);
}

.filter__show-more-text {
  letter-spacing: 1px;
  font-weight: 300;
  text-transform: uppercase;
  margin-left: 10px;
  color: currentColor;
}

.filter__show-more-icon {
  display: inline-flex;
  justify-content: flex-end;
  width: 22px;
}

.filter__show-more-icon svg {
  width: 12px;
  height: 10px;
  fill: currentColor;
}

.filter.is-active {
  transform: translateY(0);
  z-index: 125;
  opacity: 1;
  transition: transform 0.1s ease, z-index 0s 0s, opacity 0.1s;
}

.filter__reset-button {
  display: flex;
  align-items: center;
  color: currentColor;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 300;
  text-decoration: none;
}

.filter__reset-button svg {
  width: 32px;
  height: 32px;
  fill: currentColor;
  margin-right: 10px;
}

.filter__reset-button:hover {
  color: #ed1a3b;
}

.filter__reset-button.js-loading svg {
  animation: spin 5s infinite;
}

.filter__item-link {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  color: currentColor;
}

.filter__item-input {
  display: none;
}

.filter__item-text {
  transition: color 0.1s ease;
  text-decoration: none;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 110%;
}

.filter__item-ins {
  width: 24px;
  height: 24px;
  border-radius: 0;
  border: 1px solid #d0d0d0;
  margin-right: 10px;
  display: block;
  transition: border-color 0.1s ease;
  flex-shrink: 0;
}

.filter__group {
  margin-bottom: 32px;
}

.filter__groups-wrapper {
  border-bottom: 1px solid #e6edf6;
  margin-bottom: 30px;
}

.filter__title {
  margin-bottom: 10px;
  color: #333;
  padding-bottom: 10px;
  border-bottom: 1px solid #e6edf6;
  display: block;
  font-size: 22px;
  line-height: 110%;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .filter__title {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.filter__title:after {
  position: absolute;
  left: 0;
  top: 100%;
  width: 24px;
  height: 2px;
  content: "";
  background-color: #ed1a3b;
}

.filter__item {
  margin-bottom: 6px;
}

.filter__item.is-active .filter__item-text {
  color: #ed1a3b;
}

.filter__item.is-active .filter__item-ins {
  background: url("pic/okay_check.png");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: 50%;
  background-color: #ed1a3b;
  border-color: #ed1a3b;
}

.filter__item:hover a .filter__item-text {
  color: #ed1a3b;
}

.filter__item:hover a .filter__item-ins {
  border-color: #ed1a3b;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(720deg);
  }
}

.ask-button {
  position: fixed;
  bottom: 0;
  top: auto;
  margin: 0;
  right: -180px;
  width: 46px;
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;
  color: #fff;
  letter-spacing: 0.40px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0c4da2;
  transition: background-color 0.3s ease;
  z-index: 25;
  text-align: center;
  justify-content: center;
  padding: 4px 6px;
  transform: rotate(0);
  opacity: 1;
  transition: right 0.5s ease-out, opacity 0.3s ease;
}

@media only screen and (min-width: 1025px) {
  .ask-button {
    width: 260px;
    height: 46px;
    transform: rotate(-90deg);
    top: 20%;
    bottom: 0;
    margin: auto 0;
  }
}

.ask-button span {
  display: none;
}

@media only screen and (min-width: 1025px) {
  .ask-button span {
    display: block;
  }
}

.ask-button svg {
  width: 28px;
  height: 28px;
  fill: #fff;
  display: block;
}

@media only screen and (min-width: 1025px) {
  .ask-button svg {
    display: none;
  }
}

@media only screen and (min-width: 1025px) {
  .ask-button {
    top: 50%;
  }
}

.ask-button:hover {
  background-color: #ed1a3b;
}

.ask-button.is-active {
  opacity: 1;
  right: 0;
}

@media only screen and (min-width: 1025px) {
  .ask-button.is-active {
    right: -108px;
  }
}

.page-title {
  font-size: 24px;
  line-height: 100%;
  font-weight: bold;
  color: #333;
  font-family: 'DIN Pro';
}

@media only screen and (min-width: 640px) {
  .page-title {
    font-size: 38px;
  }
}

@media only screen and (min-width: 768px) {
  .page-title {
    font-size: 38px;
  }
}

@media only screen and (min-width: 1025px) {
  .page-title {
    font-size: 48px;
  }
}

.page-title--inner {
  margin-bottom: 26px;
}

@media only screen and (min-width: 1025px) {
  .page-title--inner {
    margin-bottom: 56px;
  }
}

.page-subtitle {
  font-size: 24px;
  line-height: 26px;
  color: #333;
  font-weight: bold;
  margin-bottom: 54px;
}

@media only screen and (min-width: 768px) {
  .page-subtitle {
    font-size: 30px;
    line-height: 36px;
  }
}

.logo {
  margin: 0 auto 0 40px;
  display: block;
}

.logo--footer {
  margin: 0 0 60px 0;
}

.logo svg {
  width: 254px;
  height: 60px;
}

.copyright {
  font-size: 12px;
  color: #fff;
  line-height: 14px;
  font-weight: 300;
  max-width: 200px;
  display: flex;
  align-items: center;
  margin: 0 auto 20px auto;
}

@media only screen and (min-width: 640px) {
  .copyright {
    margin: 0;
  }
}

.tools-widgets {
  display: flex;
  justify-content: center;
  margin-left: auto;
}

h1 {
  margin: 0;
}

.tools-widget {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-color: #0c4da2;
  margin: 1px;
  cursor: pointer;
  font-weight: 300;
  transition: background-color 0.1s ease;
  position: relative;
  color: #fff;
  backface-visibility: hidden;
}

.tools-widget__lngs {
  position: absolute;
  top: 100%;
  margin-top: 1px;
  left: 0;
  width: 100%;
  opacity: 1;
  transition: transform 0.3s ease;
  transform: scaleY(0);
  transform-origin: top;
}

.tools-widget__lng {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-color: #0c4da2;
  text-decoration: none;
  cursor: pointer;
  font-weight: 300;
  margin-bottom: 1px;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
  color: #fff;
}

.tools-widget__lng:hover {
  background-color: #cedbec;
  color: #0c4da2;
}

.tools-widget--filter {
  display: flex;
}

@media only screen and (min-width: 1025px) {
  .tools-widget--filter {
    display: none;
  }
}

.tools-widget--filter .tools-widget__content svg {
  width: 32px;
  height: 32px;
  fill: #ed1a3b;
}

.tools-widget__content {
  font-size: 18px;
  color: currentColor;
  line-height: 100%;
  z-index: 2;
  position: relative;
}

.tools-widget__content svg {
  fill: currentColor;
  width: 24px;
  height: 16px;
}

.tools-widget__decor {
  width: 100%;
  height: 3px;
  position: absolute;
  left: 0;
  z-index: 3;
  margin: auto;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tools-widget__decor:nth-child(2):before, .tools-widget__decor:nth-child(4):before {
  left: 0;
}

.tools-widget__decor:nth-child(2):after, .tools-widget__decor:nth-child(4):after {
  right: 0;
}

.tools-widget__decor:nth-child(2):before, .tools-widget__decor:nth-child(2):after, .tools-widget__decor:nth-child(4):before, .tools-widget__decor:nth-child(4):after {
  position: absolute;
  top: 0;
  width: 45%;
  height: 100%;
  content: "";
  background-color: #0c4da2;
  transition: background-color 0.1s ease, transform 0.3s ease;
}

.tools-widget__decor:nth-child(2) {
  transform-origin: center;
  top: 0;
  right: auto;
  bottom: 0;
  transition: transform 0.3s ease, top 0.3s ease, left 0.3s ease, right 0.3s ease, bottom 0.3s ease, margin 0.3s ease;
}

.tools-widget__decor:nth-child(4) {
  top: 0;
  bottom: 0;
  right: auto;
  transform-origin: center;
  transition: transform 0.3s ease, top 0.3s ease, left 0.3s ease, right 0.3s ease, bottom 0.3s ease, margin 0.3s ease;
}

.tools-widget__decor:nth-child(3) {
  display: none;
}

.tools-widget:hover {
  background-color: #cedbec;
  transition: background-color 0.3s ease;
  color: #0c4da2;
}

.tools-widget:hover .tools-widget__lngs {
  transform: scaleY(1);
}

.tools-widget:after {
  position: absolute;
  transition: top 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  left: 0;
  height: 16px;
  background-color: #cedbec;
  content: "";
  width: 100%;
  top: calc(100% - 16px);
}

.tools-widget.js-close-mfp, .tools-widget.is-active {
  background-color: #cedbec;
}

.tools-widget.js-close-mfp .tools-widget__content, .tools-widget.is-active .tools-widget__content {
  opacity: 0;
}

.tools-widget.js-close-mfp .tools-widget__lngs, .tools-widget.is-active .tools-widget__lngs {
  transform: scaleY(1);
}

.tools-widget.js-close-mfp .tools-widget__decor, .tools-widget.is-active .tools-widget__decor {
  opacity: 1;
}

.tools-widget.js-close-mfp .tools-widget__decor:nth-child(2), .tools-widget.is-active .tools-widget__decor:nth-child(2) {
  transform: rotate(45deg);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.tools-widget.js-close-mfp .tools-widget__decor:nth-child(2):before, .tools-widget.is-active .tools-widget__decor:nth-child(2):before {
  transform: translateX(-3px);
}

.tools-widget.js-close-mfp .tools-widget__decor:nth-child(2):after, .tools-widget.is-active .tools-widget__decor:nth-child(2):after {
  transform: translateX(3px);
}

.tools-widget.js-close-mfp .tools-widget__decor:nth-child(2):before, .tools-widget.js-close-mfp .tools-widget__decor:nth-child(2):after, .tools-widget.is-active .tools-widget__decor:nth-child(2):before, .tools-widget.is-active .tools-widget__decor:nth-child(2):after {
  transition: transform 0.3s 0.2s ease, top 0.3s ease, left 0.3s ease, right 0.3s ease, bottom 0.3s ease, margin 0.3s ease;
}

.tools-widget.js-close-mfp .tools-widget__decor:nth-child(4), .tools-widget.is-active .tools-widget__decor:nth-child(4) {
  transform: rotate(-45deg);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.tools-widget.js-close-mfp .tools-widget__decor:nth-child(4):before, .tools-widget.is-active .tools-widget__decor:nth-child(4):before {
  transform: translateX(-3px);
}

.tools-widget.js-close-mfp .tools-widget__decor:nth-child(4):after, .tools-widget.is-active .tools-widget__decor:nth-child(4):after {
  transform: translateX(3px);
}

.tools-widget.js-close-mfp .tools-widget__decor:nth-child(4):before, .tools-widget.js-close-mfp .tools-widget__decor:nth-child(4):after, .tools-widget.is-active .tools-widget__decor:nth-child(4):before, .tools-widget.is-active .tools-widget__decor:nth-child(4):after {
  transition: transform 0.3s 0.2s ease, top 0.3s ease, left 0.3s ease, right 0.3s ease, bottom 0.3s ease, margin 0.3s ease;
}

.tools-widget.js-close-mfp .tools-widget__decor:nth-child(3), .tools-widget.is-active .tools-widget__decor:nth-child(3) {
  transform: translateX(-30px);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.tools-widget.js-close-mfp:after, .tools-widget.is-active:after {
  top: 100%;
  opacity: 1;
}

.tools-widget.is-disabled {
  pointer-events: none;
}

.footer-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  columns: 2;
  padding-left: 0;
  width: 100%;
}

@media only screen and (min-width: 1280px) {
  .footer-nav {
    padding-left: 14%;
  }
}

.footer-nav__item {
  margin-bottom: 10px;
  padding: 0 10px;
}

.footer-nav__link {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Open Sans';
}

.footer-nav__link:hover {
  text-decoration: underline;
}

.mfp-wrap {
  transition: top 0.2s ease;
}

.item-page__text {
  font-size: 16px;
  line-height: 20px;
  color: #333;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .item-page__text {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 30px;
  }
}

.page-minititle {
  font-size: 22px;
  color: #333;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .page-minititle {
    margin-bottom: 28px;
  }
}

.hide-in-normal {
  display: flex;
}

@media only screen and (min-width: 1025px) {
  .hide-in-normal {
    display: none;
  }
}

.m-menu__sub-list-item:hover >
a svg {
  fill: #fff;
}

.m-menu__sub-list-item >
a svg {
  width: 40px;
  height: 26px;
  margin-left: 20px;
  fill: #333;
}

.show-in-normal {
  display: none;
  margin-top: 14px;
}

@media only screen and (min-width: 1025px) {
  .show-in-normal {
    display: flex;
  }
}

.phone {
  margin: 4px 0;
}

.phone--menu {
  color: #333;
  font-size: 16px;
}

@media only screen and (min-width: 1540px) {
  .phone--menu {
    font-size: 24px;
  }
}

.phone--footer {
  color: #fff;
  font-size: 18px;
}

@media only screen and (min-width: 1025px) {
  .phone--footer {
    font-size: 24px;
  }
}

.phone__link {
  font-family: 'DIN Pro';
  line-height: 110%;
  font-weight: bold;
  color: currentColor;
  text-decoration: none;
  outline: none;
}

.phone__link:hover {
  color: #ed1a3b;
}

.footer-socs {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.soc-item {
  text-decoration: none;
  display: flex;
  width: 22px;
}

.soc-item--menu {
  color: #68737a;
  margin-right: 10px;
}

@media only screen and (min-width: 1540px) {
  .soc-item--menu {
    margin-right: 20px;
  }
}

.soc-item--menu svg {
  fill: #68737a;
}

.soc-item--menu:hover svg {
  fill: #ed1a3b;
}

.soc-item--footer {
  color: #fff;
  margin-right: 20px;
}

.soc-item--footer svg {
  fill: #fff;
}

.soc-item--footer:hover svg {
  fill: #ed1a3b;
}

.soc-item__image {
  max-width: 22px;
  max-height: 23px;
  width: 100%;
  height: 100%;
}

@media (max-width: 1025px) {
  ._def-dn {
    display: none !important;
  }
}

@media (max-width: 641px) {
  ._ms-dn {
    display: none !important;
  }
}

.slider-custom-arrow {
  position: absolute;
  right: 10px;
  width: 50px;
  height: 50px;
  background-color: #fde8eb;
  transform: translateY(-50%);
  z-index: 25;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  user-select: none;
}

@media only screen and (min-width: 1540px) {
  .slider-custom-arrow {
    width: 100px;
    height: 100px;
    right: 0;
  }
}

.slider-custom-arrow svg {
  width: 22px;
  height: 22px;
  fill: #ed1a3b;
  transition: fill 0.3s ease;
}

.slider-custom-arrow:hover {
  background-color: #ed1a3b;
}

.slider-custom-arrow:hover svg {
  fill: #fff;
}

.slider-custom-arrow--resp {
  display: flex;
}

@media only screen and (min-width: 768px) {
  .slider-custom-arrow--resp {
    display: none;
  }
}

.slider-custom-arrow--right {
  top: calc(50% - 25px);
}

@media only screen and (min-width: 1540px) {
  .slider-custom-arrow--right {
    top: calc(50% - 50px);
  }
}

.slider-custom-arrow--left {
  top: calc(50% + 25px);
}

@media only screen and (min-width: 1540px) {
  .slider-custom-arrow--left {
    top: calc(50% + 50px);
  }
}

.characteristics {
  width: 100%;
  border: none;
  border-spacing: 0;
  border-bottom: 1px solid #edebe9;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .characteristics {
    margin-bottom: 40px;
  }
}

.characteristics__content {
  display: flex;
  align-items: center;
}

.characteristics__row:nth-child(odd) {
  background-color: #edebe9;
}

.characteristics__row .characteristics__cell {
  padding: 8px 6px;
}

@media only screen and (min-width: 640px) {
  .characteristics__row .characteristics__cell {
    padding: 12px 26px;
  }
}

.characteristics__row .characteristics__cell:nth-child(1) {
  width: 38%;
  min-width: 230px;
}

.characteristics__row .characteristics__cell:nth-child(1) .characteristics__text {
  margin-right: 10px;
}

.characteristics__row .characteristics__cell:nth-child(1) .characteristics__content {
  display: flex;
}

.characteristics__row .characteristics__cell:nth-child(1) .characteristics__content:after {
  content: ":";
  margin-left: 4px;
  font-size: 16px;
  color: #333;
  padding-bottom: 2px;
}

@media only screen and (min-width: 640px) {
  .characteristics__row .characteristics__cell:nth-child(1) .characteristics__content:after {
    margin-left: auto;
    font-size: 18px;
    padding-bottom: 0;
  }
}

.characteristics__text {
  font-size: 14px;
  color: #333;
}

@media only screen and (min-width: 640px) {
  .characteristics__text {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) {
  .characteristics__text {
    font-size: 18px;
  }
}

@media (max-width: 520px) {
  .characteristics__row {
    display: flex;
    flex-direction: column;
  }
}

.partners-item {
  height: 120px;
}

@media only screen and (min-width: 1025px) {
  .partners-item {
    height: 160px;
  }
}

@media only screen and (min-width: 1540px) {
  .partners-item {
    height: 200px;
  }
}

.partners-item__image {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease, opacity 0.3s ease;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.partners-item__image img {
  position: absolute;
  max-width: calc(100% - 100px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

._mgb50 {
  margin-bottom: 26px;
}

@media only screen and (min-width: 768px) {
  ._mgb50 {
    margin-bottom: 36px;
  }
}

@media only screen and (min-width: 1025px) {
  ._mgb50 {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 1280px) {
  ._mgb50 {
    margin-bottom: 50px;
  }
}

._mgb30 {
  margin-bottom: 20px;
}

@media only screen and (min-width: 640px) {
  ._mgb30 {
    margin-bottom: 30px;
  }
}

._mgb20 {
  margin-bottom: 20px;
}

._mgb10 {
  margin-bottom: 10px;
}

._df {
  display: flex;
}

._aic {
  align-items: center;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .button--main .button__icon {
    margin-right: 20px;
  }
  .m-menu__list-link, .m-menu__sub-list-link {
    padding: 14px 4px 14px 40px;
    min-height: 36px;
  }
  .button--sub .button__icon {
    right: 35px;
    position: relative;
  }
  .alphabet-item.inview {
    opacity: 1;
  }
}

@media screen and (-ms-high-contrast: active) and (max-height: 1540px), screen and (-ms-high-contrast: none) and (max-height: 1540px) {
  .m-menu__list-link, .m-menu__sub-list-link {
    padding: 6px 4px 6px 20px;
    min-height: 16px;
  }
}

body::-webkit-scrollbar-track,
body *::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar,
body *::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(224, 224, 224, 0.4);
}

body::-webkit-scrollbar-thumb,
body *::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 4px;
}

.disabled-filter-input {
  opacity: 0.7;
  cursor: no-drop;
}

.breadcrumbs-dummy {
  width: 100%;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.breadcrumb-mega-wrapper {
  position: relative;
}

*.no-image {
  background-color: #031c3c;
}

.gcell.swiper-slide {
  height: initial;
}

.js-resp-slider .swiper-wrapper {
  box-sizing: border-box;
}

.swiper-container-horizontal .swiper-wrapper {
  box-sizing: content-box;
}

.swiper-container-horizontal .swiper-wrapper.grid {
  flex-wrap: nowrap;
}

.swiper-container-horizontal .swiper-wrapper .gcell.swiper-slide {
  height: 100%;
  padding: 0;
}
