.service-item{
	width: 100%;
	height: 132px;
	overflow: hidden;
	margin-bottom: 2px;
	border: 1px solid $c-lgrey2;
	position: relative;
	background-color: $c-white;
	text-decoration: none;
	transition: border-color 0.3s ease;
	max-height: 340px;
	min-height: 132px;
	backface-visibility: hidden;
	
	@include media($ms){
		height: 280px;
	min-height: 200px;
	}

	@include media($md){
		height: 300px;
	}

	@include media($def){
		height: 340px;
	}

	&__image{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		z-index: 1;
		transition: opacity 0.3s ease, visibility 0s 0.3s ease;
		visibility: hidden;

		img{
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			position: absolute;
			min-width: 100%;
			max-width: initial;
			z-index: 1;
		}

		&:after{
			background-color: $c-black;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			content: "";
			opacity: 0.5;
			z-index: 2;
			position: absolute;
		}
	}
	&__text{
		position: absolute;
		z-index: 4;
		color: $c-dark;
		font-family: 'Open Sans';
		transition: color 0.3s ease;
		font-size: 18px;
		line-height: 24px;
		bottom: 35px;
		left: 15px;

		@include media($md){
			font-size: 22px;
			line-height: 30px;
			bottom: 55px;
			left: 35px;
		}
	}
	&__decor-button{
		width: 35px;
		height: 35px;
		background-color: $c-red;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 0;
		bottom: 0;
		opacity: 0;
		transition: opacity 0.3s ease;
		z-index: 2;

		@include media($md){
			width: 50px;
			height: 50px;
		}

		svg{
			width: 10px;
			height: 10px;
			fill: $c-white;
		}
	}
	&__decor{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		
		&:after,
		&:before{
			content: "";
			position: absolute;
			width: 20px;
			height: 20px;
			opacity: 0;
			transition: opacity 0.3s 0.2s ease, transform 0.3s 0.2s ease;
		}
		&:after{
			left: 35px;
			top: 35px;
			border-top: 1px solid $c-red;
			border-left: 1px solid $c-red;
			transform: translate(-25px, -25px);

			@include media($md){
				left: 50px;
				top: 50px;
			}
		}
		&:before{
			right: 35px;
			bottom: 35px;
			border-bottom: 1px solid $c-red;
			border-right: 1px solid $c-red;
			transform: translate(25px, 25px);

			@include media($md){
				right: 50px;
				bottom: 50px;
			}
		}
	}
	&__decor-image{
		position: absolute;
		right: 6px;
		top: 6px;
		z-index: 3;

		@include media($ms){
			right: 36px;
			top: 36px;
		}

		svg{
			width: 40px;
			height: 40px;
			fill: $c-grey-lblue2;
			transition: fill 0.3s 0.2s ease;

			@include media($ms){
				width: 60px;
				height: 60px;
			}

			@include media($md){
				width: 80px;
				height: 80px;
			}
		}
	}
	
	&:hover{
		.service-item{
			border-color: transparent;

			&__image{
				opacity: 1;
				transition: opacity 0.5s 0.01s ease, visibility 0s 0s ease;
				visibility: visible;
			}
			&__text{
				color: $c-white;
				transition: color 0.3s 0.2s ease;
			}
			&__decor{
				&:after,
				&:before{
					opacity: 1;
					transform: translate(0, 0);
					transition: opacity 0.3s ease, transform 0.3s ease;
				}
			}
			&__decor-image{
				svg{
					fill: $c-white;
					transition: fill 0.3s 0.2s ease;
				}
			}
			&__decor-button{
				opacity: 1;
				transform: scale(1);
				transition: opacity 0.3s 0.2s ease;
			}
		}
	}
}

.touchevents{
	.service-item{
		border-color: transparent;

		&__image{
			opacity: 1;
			transition: opacity 0.5s 0.01s ease, visibility 0s 0s ease;
			visibility: visible;
		}
		&__text{
			color: $c-white;
			transition: color 0.3s 0.2s ease;
		}
		&__decor{
			&:after,
			&:before{
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.3s ease, transform 0.3s ease;
			}
		}
		&__decor-image{
			svg{
				fill: $c-white;
				transition: fill 0.3s 0.2s ease;
			}
		}
		&__decor-button{
			opacity: 1;
			transform: scale(1);
			transition: opacity 0.3s 0.2s ease;
		}
	}
}