.faq{
	width: 100%;

	&__item{
		margin-bottom: 40px;
		width: 100%;
		display: flex;
		flex-direction: column;
		font-size: 16px;
		line-height: 20px;
		color: $c-dark;

		@include media($ms){
			font-size: 18px;
			line-height: 24px;
			margin-bottom: 20px;
		}
	}
	&__question{
		padding: 16px 8px 16px 2.5%;
		display: flex;
		width: 100%;
		flex-direction: column;

		@include media($ms){
			flex-direction: row;
		}
	}
	&__answer{
		background-color: #edebe9;
		padding: 16px 8px 16px 2.5%;
		display: flex;
		width: 100%;
		flex-direction: column;

		@include media($ms){
			flex-direction: row;
		}
	}
	&__left{
		flex-shrink: 0;
		min-width: 125px;
		width: 100%;
		margin-bottom: 10px;

		@include media($ms){
			width: 26%;
			margin-bottom: 0;
		}
	}
	&__right{
		width: 100%;

		@include media($ms){
			width: calc(100% - 240px);
		}
	}
}