.subnav-list{
	background-color: #edebe9;
	padding: 0;
	margin: 0 0 18px 0;
	list-style-type: none;
	display: none;
	width: 100%;

	@include media($md){
		display: block;
	}

	@include media(defp){
		margin: 0;
	}

	&__item{
		width: 100%;
	}

	&__link{
		padding: 8px 18px 8px 18px;
		line-height: 22px;
		font-size: 16px;
		color: $c-dark;
		transition: color 0.3s ease, background-color 0.3s ease;
		text-decoration: none;
		display: block;

		@include media($lg){
			padding: 14px 36px 14px 36px;
			line-height: 30px;
			font-size: 18px;
		}

		&.is-active{
			background-color: $c-red;
			color: $c-white;
		}

		&:hover{
			color: $c-white;
			background-color: $c-red;
		}
	}
}
.subnav-items-list{
	width: 100%;
	display: flex;
	flex-direction: column;
}
.subnav-item{
	display: flex;
	min-height: 98px;
	text-decoration: none;
	border: 1px solid $c-lgrey2;
	transition: border-color 0.3s ease;
	margin-bottom: 2px;

	&__num{
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		color: $c-white;
		font-weight: bold;
		font-size: 24px;
		font-family: 'DIN Pro';
	}
	&__text{
		font-size: 16px;
		transition: color 0.3s ease;
		color: $c-dark;
		align-items: center;
		display: flex;
		position: relative;
		line-height: 22px;

		@include media($md){
			font-size: 18px;
			line-height: 30px;
		}

		&:after{
			position: absolute;
			content: "";
			width: 24px;
			bottom: 0;
			height: 2px;
			background-color: $c-red;
			left: 0;
		}
	}
	&__image{
		margin-right: 8px;
		position: relative;
		width: 66px;
		overflow: hidden;
		height: 96px;
		background-color: $c-black;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		border-right: 1px solid $c-lgrey2;

		@include media($ms){
			width: 174px;
			margin-right: 18px;
		}

		@include media($md){
			margin-right: 38px;
		}

		img{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			opacity: 0.5;
			transition: transform 0.3s ease;
			max-width: initial;
			min-height: 100%;

			@include media($ms){
				max-width: 100%;
				min-height: initial;
			}
		}
	}

	&__decor{
		width: 118px;
		position: relative;
		display: flex;
		align-items: center;

		span{
			margin-right: auto;
			width: 53px;
			height: 1px;
			background-color: $c-white;
			display: block;
			opacity: 0;
			transform: scaleX(0.2);
			transition: opacity 0.3s ease, transform 0.3s 0.22s ease;
			transform-origin: left;
		}
		
		svg{
			width: 48px;
			height: 12px;
			fill: $c-white;
			margin-left: auto;
			opacity: 0;
			transform: scaleX(0);
			transition: opacity 0.3s ease, transform 0.3s ease;
			transform-origin: left;
		}
	}

	&:hover{
		border-color: $c-red;
		.subnav-item{
			&__decor{
				span{
					opacity: 1;
					transform: scaleX(1);
					transition: opacity 0.3s ease, transform 0.3s ease;
				}
				svg{
					opacity: 1;
					transform: scaleX(1);
					transition: opacity 0.3s ease, transform 0.3s 0.22s ease;
				}
			}

			&__image{
				img{
					transform: scale(1.05) translate(-50%, -50%);
				}
			}

			&__text{
				color: $c-red;
			}
		}
	}
}

.subnav-widget{
	margin-bottom: 20px;

	@include media($defp){
		margin-bottom: 50px;
	}
}