.socs-widget{
	align-items: center;
	display: flex;
	flex-direction: column;

	@include media($ms){
		flex-direction: row;
	}

	&__text{
		font-weight: bold;
		font-size: 20px;
		color: $c-dark;
		margin-right: 0;
		font-family: 'DIN Pro';
		margin-bottom: 10px;

		@include media($ms){
			margin-bottom: 0;
			font-size: 24px;
			margin-right: 20px;
		}
	}
}