.wezom{
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: center;
	text-decoration: none;
	width: 302px;

	@include media($ms){
		margin-right: 20px;
	}

	@include media($def){
		margin-right: 0;
	}

	@include media($lg){
		margin-right: 20%;
	}

	&__text{
		font-size: 12px;
		font-weight: 300;
		line-height: 14px;
		color: currentColor;
		font-family: 'Open Sans';
		max-width: 236px;
		margin-left: 16px;
		transition: color 0.3s 0s ease, margin-left 0.3s ease;
		color: $c-white;

	}
	&__logo{
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;	
		background-color: #ed1a3b;
		border-radius: 0;
		transition: border-radius 0.3s ease;

		svg{
			fill: $c-white;
			width: 34px;
			height: 12px;
		}
	}

	&:hover{
		color: $c-red;

		.wezom{
			&__logo{
				border-radius: 100%;
			}
			&__text{
				color: $c-red;
				margin-left: 4px;
			}
		}
	}
}