.page404-content{
	max-width: 530px;
	width: 100%;
	margin-bottom: 20px;
	order: 2;

	@include media($defp){
		order: 1;
	}

	@include media($def){
		margin-bottom: 115px;
	}
}
.page404-image{
	order: 1;
	width: 100%;

	@include media($defp){
		order: 2;
	}
}