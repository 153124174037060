////
/// @group _partials/view-footer.scss
////


// scss
// ================================

.view-footer {
	position: relative;
	background-color: $c-dblue;
    backface-visibility: hidden;
	&__top-row{
		padding: 70px 2.6% 58px 6%;
		border-bottom: 1px solid $c-gblue;
	}
	&__block{
		width: 100%;
	}

	&__bottom-row{
		padding: 52px 2.6% 30px 6%;

		@include media($defp){
			padding: 30px 2.6% 30px 6%;
		}
	}
}