.categories-item{
	margin: 0 auto;
	width: 100%;
	// max-width: 478px;
	height: 82px;
	position: relative;
	margin-bottom: 2px;
	max-height: initial;
	backface-visibility: hidden;
	min-height: initial;

	@include media($ms){
		height: calc(50vh - 30px);
		min-height: 180px;
		max-height: 280px;
	}

	@include media($md){
		height: 240px;
	}

	@include media($def){
		height: 280px;
	}

	&__image{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		margin: auto;
		z-index: 1;
		overflow: hidden;
		background-color: $c-black;

		img{
			position: absolute;
			left: 50%;
			top: 50%;
			margin: auto;
			transition: transform 0.4s ease, opacity 0.3s ease;
			max-width: initial;
			max-height: initial;
			transform: translate(-50%, -50%);
			min-width: 100%;
			opacity: 0.5;
		}
	}
	&__descr{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		padding: 6px;
		display: flex;

		@include media($ms){
			padding: 10% 5% 9% 8.2%;
			display: block;
		}
	}
	&__num{
		font-size: 24px;
		color: $c-white;
		opacity: 0.2;
		font-weight: bold;
		margin-bottom: 14px;
		position: relative;
		transition: opacity 0.6s ease;
	}
	&__text{
		font-size: 20px;
		line-height: 24px;
		font-family: 'Open Sans';
		color: $c-white;
		padding-top: 0;
		overflow: hidden;
		position: relative;
		margin-left: 6px;
		margin-top: 2px;

		@include media($ms){
			padding-top: 20px;
			margin-top: 0;
			margin-left: 0;
		}

		@include media($md){
			font-size: 24px;
			line-height: 26px;
		}

		@include media($mg){
			font-size: 30px;
			line-height: 36px;
		}

		&:before{
			position: absolute;
			left: 0;
			top: 0;
			height: 2px;
			width: 24px;
			background-color: $c-white;
			content: "";
			display: none;

			@include media($ms){
				display: block;
			}
		}
	}

	&:hover{
		.categories-item{
			&__image{

				&:after{
					opacity: 0.7;
					transition: opacity 0.6s ease;
				}

				img{
					transform: scale(1.05) translate(-50%, -50%);
					transition: transform 0.8s ease;
					opacity: 0.3;
				}
			}
			&__num{
				opacity: 1;
			}
		}
	}
}

.touchevents{
	.categories-item{
			&__image{

				&:after{
					opacity: 0.7;
					transition: opacity 0.6s ease;
				}

				img{
					transform: scale(1.05) translate(-50%, -50%);
					transition: transform 0.8s ease;
				}
			}
			&__num{
				opacity: 1;
			}
		}
}