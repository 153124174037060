$filterBgc: $c-white;
$filterGlobalColor: $c-dark;
$globalFonts: "Open Sans";

$titleColor: $c-dark;

$textColor: currentColor;
$textActiveColor: $c-red;
$textHoverColor: $c-red;

$resetBtn: currentColor;
$resetIcon: currentColor;
$resetBtnHoverColor: $c-red;

$insIcon: "pic/okay_check.png";
$insColor: $c-lgrey2;
$insActiveColor: $c-red;
$insHoverColor: $c-red;
$insBorderRadius: 0;

$borderColor: $c-lblue2;




.filter-submenu{
	list-style-type: none;
	padding: 0 0 20px 0;
	margin: 0 0 44px;
	display: none;

	@include media($def){
		display: block;
	}

	&__prev-list{
		list-style-type: none;
		padding: 0;
		margin: 0;

		.filter-submenu{
			&__item,
			&__prev{

		.filter-submenu{
			&__item{
				.filter-submenu{
					&__link{
				padding-left: 50px
					}
				}
			}
			&__prev{
				.filter-submenu{
					&__link{
				padding-left: 22px;
					}
				}
				.filter-submenu{
					&__item{
						.filter-submenu{
							&__link{
						padding-left: 72px;
							}
						}
					}
					&__prev{
						.filter-submenu{
							&__link{
						padding-left: 44px;
							}
						}
					}
				}
			}
		}
			}
		}
	}
	&__link{
		color: $c-black;
		text-decoration: none;
		font-size: 18px;
		line-height: 100%;
		padding: 14px 0;
		display: block;

		svg{
			width: 10px;
			height: 14px;
			fill: $c-red;
			margin-right: 14px;
		}

		&:hover{
			color: $c-red;
		}

		&.is-active{
			color: $c-red;
			pointer-events: none;
		}
	}
	&__prev{
		border-top: 1px solid $c-lblue2;
		border-bottom: 1px solid $c-lblue2;
	}
}




.filter{
	// width: 40%;
	// min-width: 240px;
	min-width: 260px;
	width: 300px;
	position: fixed;
	margin-right: 10px;
	flex-shrink: 0;
    background-color: $filterBgc;
    z-index: 15;
    transform: translateX(-400%) scale(0.9);
    opacity: 0.5;
    top: 60px;
    font-family: $globalFonts;
    color: $filterGlobalColor;
    left: 0;
    padding: 10px;
    transition: transform 0.1s ease, z-index 0s 0.1s, opacity 0.1s;
    height: calc(100% - 60px);
    overflow: overlay;
	padding-bottom: 30px;
	min-height: calc(100% - 60px);
	display: block;

	@include media($ms){
		top: 80px;
	}

	@include media($def){
		width: 350px;
	}

	@include media($mg){
		width: 450px;
	}



	@include media($def) {
		top: 0;
		position: relative;
		transform: translate(0);
    	height: auto;
		opacity: 1;
		padding: 0;
	}
	&__main-title{
		font-size: 30px;
		font-weight: bold;
		line-height: 100%;
		margin-bottom: 32px;
		padding-bottom: 20px;
		border-bottom: 1px solid $c-lblue2;
	font-family: 'DIN Pro';
	}
	&__items-list{
		list-style-type: none;
		padding: 0;
		margin: 0;
		max-height: 158px;
		overflow-x: hidden;
		overflow-y: auto;
		// transition: max-height 0.7s 0s linear;

		&.is-active{
			max-height: 1200px;
		}
	}
	&__show-more{
		color: $c-dark;
		font-size: 12px;
		transition: color 0.3s ease;
		cursor: pointer;
		margin-top: 18px;

		&:hover{
			color: $c-red;
		}

		&.is-disabled{
			display: none;
		}

		&.is-active{
			.filter{
				&__show-more-icon{
					transform: scale(-1);
				}
			}
		}

	}
	&__show-more-text{
		letter-spacing: 1px;
		font-weight: 300;
		text-transform: uppercase;
		margin-left: 10px;
		color: currentColor;
	}
	&__show-more-icon{
		display: inline-flex;
		justify-content: flex-end;
		width: 22px;

		svg{
			width: 12px;
			height: 10px;
			fill: currentColor;
		}
	}
	&.is-active{
		transform: translateY(0);
		z-index: 125;
		opacity: 1;
		transition: transform 0.1s ease, z-index 0s 0s, opacity 0.1s;
	}

	&__reset-button{
		display: flex;
		align-items: center;
		color: $resetBtn;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 1px;
		font-weight: 300;
		text-decoration: none;
		svg{
			width: 32px;
			height: 32px;
			fill: $resetIcon;
			margin-right: 10px;
		}
		&:hover{
			color: $resetBtnHoverColor;
		}
		&.js-loading{
			svg{
				animation: spin 5s infinite;
			}
		}
	}

	&__item-link{
		text-decoration: none;
		display: inline-flex;
		align-items: center;
		color: $textColor;

	}
	&__item-input{
		display: none;
	}
	&__item-text{
		transition: color 0.1s ease;
		text-decoration: none;
		font-size: 18px;
		letter-spacing: 1px;
		line-height: 110%;
	}
	&__item-ins{
		width: 24px;
		height: 24px;
		border-radius: $insBorderRadius;
		border: 1px solid $insColor;
		margin-right: 10px;
		display: block;
		transition: border-color 0.1s ease;
		flex-shrink: 0;
	}
	&__group{
		margin-bottom: 32px;
	}
	&__groups-wrapper{
		border-bottom: 1px solid $borderColor;
		margin-bottom: 30px;
	}
	&__title{
		margin-bottom: 10px;
		color: $titleColor;
		padding-bottom: 10px;
		border-bottom: 1px solid $borderColor;
		display: block;
		font-size: 22px;
		line-height: 110%;
		position: relative;

		@include media($md){
			padding-bottom: 20px;
			margin-bottom: 20px;
		}

		&:after{
			position: absolute;
			left: 0;
			top: 100%;
			width: 24px;
			height: 2px;
			content: "";
			background-color: $c-red;
		}
	}
	&__item{
		margin-bottom: 6px;
		&.is-active{
			.filter__item-text{
				color: $textActiveColor;
			}
			.filter__item-ins{
				background: url($insIcon);
		    	background-repeat: no-repeat;
		    	background-size: 80%;
		    	background-position: 50%;
		    	background-color: $insHoverColor;
		    	border-color: $insActiveColor;
			}

		}
		&:hover{
			a{
				.filter__item-text{
					color: $textHoverColor;
				}
				.filter__item-ins{
					border-color: $insHoverColor;
				}
			}
		}
	}
}

@keyframes spin {
  0%   { transform: rotate(0); }
  100% { transform: rotate(720deg); }
}
