.inner-page{
	margin-bottom: 26px;
	width: 100%;

	@include media($def){
		margin-bottom: 56px;
	}	
	&--page404{
		padding-top: 20px;

		@include media($md){
			padding-top: 50px;
		}
		.inner-page-doubletitle{
			margin-bottom: 0;
		}
	}
	&--item-page{
		.socs-widget{
			margin-bottom: 10px;
		}
		.inner-page-content--double{
			margin-bottom: 0;
		}
	}
}
.inner-page-doubletitle{
	width: 100%;
	margin-bottom: 20px;

	@include media($ms){
		margin-bottom: 40px;
	}

	@include media($def){
		margin-bottom: 60px;
	}

	&__title{
		font-size: 28px;
		font-weight: bold;
		color: $c-dark;
		line-height: 100%;
	    word-break: break-word;
	    word-wrap: break-word;
		font-family: 'DIN Pro';
		margin-bottom: 16px;

		@include media($ms){
			font-size: 34px;
		}

		@include media($defp){
			font-size: 38px;
		}

		@include media($lg){
			font-size: 48px;
		}
	}

	&__text{
		color: $c-dark;
		font-size: 16px;
		line-height: 22px;

		@include media($def){
			font-size: 18px;
			line-height: 30px;
		}
	}
}
.inner-page-content-aside{
	width: 100%;
	margin-right: 0;
	display: inline-block;
	text-align: center;
	margin-bottom: 10px;

	@include media($def){
		width: 31%;
		margin-right: 2%;
	 	text-align: left;
	 	margin-bottom: 0;
	}

	img{
		max-height: 90vh;
	}
}
.inner-page-content{
	display: inline-block;
	width: 100%;

	&--double{
		width: 100%;
		margin-bottom: 40px;


		@include media($md){
			margin-bottom: 60px;
		}
		
		@include media($def){
			width: 67%;
			margin-bottom: 90px;
		}
	}
}