.burger{
	position: relative;
	width: 40px;
	height: 28px;
	cursor: pointer;

	&__decor{
		width: 100%;
		height: 3px;
		position: absolute;
		left: 0;
		margin: auto;
		
		&:nth-child(1),
		&:nth-child(3){
			&:before{
				left: 0;
			}
			&:after{
				right: 0;
			}
			&:before,
			&:after{
				position: absolute;
				top: 0;
				width: 50%;
				height: 100%;
				content: "";
				background-color: $c-black;
				transition: background-color 0.1s ease, transform 0.3s ease;
			}
		}

		&:nth-child(1){
			transform-origin: center;
			top: 0;
			right: auto;
			bottom: auto;
			transition: transform 0.3s ease, top 0.3s ease, left 0.3s ease, right 0.3s ease, bottom 0.3s ease, margin 0.3s ease;
		}
		&:nth-child(3){
			bottom: 0;
			right: auto;
			transform-origin: center;
			transition: transform 0.3s ease, top 0.3s ease, left 0.3s ease, right 0.3s ease, bottom 0.3s ease, margin 0.3s ease;
		}
		&:nth-child(2){
			background-color: $c-black;
			transition: background-color 0.1s ease, transform 0.3s 0.1s ease, opacity 0.3s 0.2s ease;
			opacity: 1;
			top: 0;
			bottom: 0;
			right: auto;
			transform: translateX(0px);
		}
	}

	&:hover{
		.burger{
			&__decor{
				&:nth-child(2){
					background-color: $c-blue;
					transition: background-color 0.3s ease, transform 0.3s 0s ease, opacity 0.3s 0.1s ease;
				}
				&:nth-child(1),
				&:nth-child(3){
					&:before,
					&:after{
						background-color: $c-blue;
						transition: background-color 0.3s ease, transform 0.3s 0s ease;
					}
				}
			}
		}
	}

	&.is-active{
		height: 40px;

		.burger{
			&__decor{

				&:nth-child(1){
					transform: rotate(45deg);
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					&:before{
						transform: translateX(-5px);
					}
					&:after{
						transform: translateX(5px);
					}
					&:before,
					&:after{
						transition: transform 0.3s 0.2s ease, top 0.3s ease, left 0.3s ease, right 0.3s ease, bottom 0.3s ease, margin 0.3s ease;
					}
				}
				&:nth-child(3){
					transform: rotate(-45deg);
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					&:before{
						transform: translateX(-5px);
					}
					&:after{
						transform: translateX(5px);
					}
					&:before,
					&:after{
						transition: transform 0.3s 0.2s ease, top 0.3s ease, left 0.3s ease, right 0.3s ease, bottom 0.3s ease, margin 0.3s ease;
					}
				}
				&:nth-child(2){
					transform: translateX(-30px);
					opacity: 0;
					transition: transform 0.3s ease, opacity 0.3s ease;
				}
			}
		}
	}
}


