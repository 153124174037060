.seo-block{

	&__title{
		font-size: 32px;
		line-height: 100%;
		font-weight: bold;
		color: $c-dark;
		margin-bottom: 28px;

		@include media($md){
			font-size: 40px;
		}

		@include media($def){
			font-size: 48px;
		}
	}

	&--innerpage{
		margin-bottom: 0;

		.view-text{
			margin-bottom: 0;

			p{
				font-size: 14px;
				line-height: 110%;
				margin:  20px 0;
			}
		}
	}
}