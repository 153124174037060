$overlay_color: $c-black;
$overlay_opacity: 0.5;
$menu_main_color: $c-white;
$menu_width: 300px;





.m-menu{
	position: fixed;
	left: -100%;
	top: 60px;
	height: calc(100vh - 60px);
	z-index: 9999;
	transition: left 0.3s ease;
	width: 80vw;
	max-width: 300px;
	min-width: 280px;
	background-color: $menu_main_color;
	display: none;

	&.is-inited{
		display: block;
	}

	@include media($ms){
		top: 80px;
		height: calc(100vh - 80px);
	}

	&__decor{
		width: 100%;
		height: 62px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		@include media($ms){
			display: none;
		}
		svg{
			height: 56px;
		}
	}

	&__list-item{
		color: $c-dark;
		background-color: transparen;
		display: flex;
		width: 100%;
		align-items: center;
	}
	&__title{
		font-size: 18px;
		padding: 4px 40px;
		justify-content: center;	
		text-align: center;
		display: none;
		position: relative;
		min-height: 40px;
		align-items: center;

		@include media($def){
			font-size: 20px;
			padding: 8px 40px;
		}

		&:after{
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 90%;
			height: 1px;
			background-color: $c-dark;
			content: "";
		}
	}
	&__sub-list-item{
		color: $c-dark;
		background-color: transparen;
		display: flex;
		align-items: center;
	}
	&__list-link{
		padding: 6px 4px 6px 20px;
	}
	&__sub-list-link{
		padding: 6px 10px 6px 20px;
	}
	&__list-link,
	&__sub-list-link{
		min-height: 30px;
		display: flex;
		align-items: center;
		text-decoration: none;
		font-size: 14px;
		color: currentColor;
		line-height: 16px;
		transition: background-color 0.1s ease, color 0.2s ease;
		width: 100%;

		@include media($def){
			min-height: 30px;
			font-size: 14px;
			line-height: 16px;
			padding: 4px 4px 4px 20px;
		}

		@include media($mg){
			min-height: 50px;
			font-size: 16px;
			line-height: 20px;
			padding: 6px 4px 6px 40px;
		}

		&.is-quostil-for-client{
			margin-top: 18px;
		}
	}
	&__list,
	&__sub-list{
		padding: 0;
		margin: 0;
		list-style-type: none;
		overflow: auto;
		max-height: calc(100vh - 360px);
		min-height: 200px;

		@include media($ms){
			max-height: calc(100vh - 280px);
		}

		@include media($def){
			max-height: calc(100vh - 380px);
		}

		&::-webkit-scrollbar{
			width: 0;
			transition: width 0.2s ease;
			z-index: -1;
		}


		&:hover{
			&::-webkit-scrollbar{
				width: 6px;
			}

			.m-menu__list-link,
			.m-menu__sub-list-link{
				padding-right: 4px;
			}
		}
	}

	&__sub-list{
		// display: none;
		height: 100%;	
		width: 426px;
		min-width: 270px;
		top: 0;
	}
	&__bottom-widgets{
		display: flex;
		justify-content: center;
		flex-direction: column;
		position: relative;
		z-index: 25;
		background-color: $c-white;
	}
	&__nav-wrapper{
		margin-bottom: 20px;
		min-height: 200px;
	}
	&__next-arrow,
	&__prev-arrow{
		display: none;
	}
	&.is-active{
		left: 0;
	}
}

.m-menu{
	&.is-resp{
		overflow-y: auto;

		.m-menu{
			&__list-item{
			}

			&__nav-wrapper{
				position: relative;
				height: auto;
			}
			&__title{
				display: flex;
			}
			&__list-link,
			&__sub-list-link{
				min-height: 40px;

				&:hover{
					color: $c-white;
					background-color: $c-red;
				}
			}

			&__bottom-widgets{
				padding-bottom: 40px;
			}
			&__next-arrow{
				display: block;
				position: relative;
				margin-left: auto;
				width: 40px;
				display: flex;
				align-items: center;
				justify-content: center;	
				flex-shrink: 0;
				align-self: stretch;
				cursor: pointer;
				background-color: rgba(0, 0, 0, 0.5);
				transition: background-color 0.3s ease;

				svg{
					width: 20px;
					height: 20px;
					fill: $c-white;
				}

				&:hover{
					background-color: $c-red;
				}
			}
			&__prev-arrow{
				display: block;
				position: absolute;
				margin-left: auto;
				width: 40px;
				display: flex;
				align-items: center;
				justify-content: center;	
				flex-shrink: 0;
				align-self: stretch;
				cursor: pointer;
				background-color: rgba(0, 0, 0, 0.5);
				transition: background-color 0.3s ease;
				left: 0;
				top: 0;
				bottom: 0;

				svg{
					width: 20px;
					height: 20px;
					fill: $c-white;
				}

				&:hover{
					background-color: $c-red;
				}
			}



			&__sub-list-item{

			}

			&__sub-list{
				display: none;
				width: 100%;
				position: absolute;
				left: 0;
				top: 0;
				background-color: $c-white;

				&.is-active{
					display: block;
					overflow-y: auto;
				}
				&.is-prev{
					overflow: hidden;
				}
			}
		}
	}
}

.m-menu{
	&.is-desktop{
		width: 15.7vw;
		min-width: 220px;

		.m-menu{
			&__list{
				position: relative;
				z-index: 25;
				background-color: $c-white;
			}
			&__sub-list{
				position: absolute;
				width: 280px;
				left: 100%;
				visibility: hidden;
				background-color: $c-lgrey;
				background: linear-gradient(to right, #aaafb1 0, #efefef 10px, #ffffff 18px, #ffffff 100%);
				// transition: opacity 0s 0.2s ease, visibility 0s 0.2s ease, transform 0.2s ease;
				max-height: calc(100vh - 80px);
				opacity: 0;

				@include media($lg){
					width: 300px;
				}

				@include media($mg){
					width: 424px;	
				}

				&.is-open{
					opacity: 1;
					visibility: visible;
					transition: opacity 0.2s 0s ease, visibility 0s 0s ease, transform 0.2s ease;
					transform: translateX(0);
				}
				&.level1{
					left: calc(100% + 280px);
					@include media($lg){
						left: calc(100% + 300px);
					}

					@include media($mg){
						left: calc(100% + 424px);
					}
				}

				&.level2{
					left: calc(100% + 560px);
					@include media($lg){
						left: calc(100% + 600px);
					}

					@include media($mg){
						left: calc(100% + 848px);
					}
				}
			}
			&__sub-list-link{
				width: calc(100% - 6px);
			}
			&__list-item,
			&__sub-list-item{
				&:hover{
					background-color: $c-red;
				}
				&.is-hover{
					background-color: $c-red;
					.m-menu__list-link,
					.m-menu__sub-list-link{
						background-color: $c-red;
						color: $c-white;
					}
				}
				.m-menu__list-link,
				.m-menu__sub-list-link{
					&:hover{
						background-color: $c-red;
						color: $c-white;
					}
				}
			}
		}
	}
}


.m-menu{
	&__phones-widget{
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 auto;
		padding: 14px 0;
		border-bottom: 1px solid $c-grey-lblue;
		border-top: 1px solid $c-grey-lblue;
		margin-bottom: 8px;
		align-items: center;

		@include media($mg){
			padding: 34px 0;
			margin-bottom: 28px;
		}
	}
	&__socs-widget{
		display: inline-flex;
		padding: 0 10px;
		justify-content: center;
		@include media($mg){
			padding: 0 40px;
		}	
	}
}




.menu-overlay{
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	background-color: $overlay_color;
	z-index: -1;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s 0.3s ease, opacity 0.3s 0s ease, z-index 0s 0.3s ease;

	&.is-active{
		opacity: $overlay_opacity;
		z-index: 900;
		visibility: visible;
		transition: visibility 0s 0s ease, opacity 0.3s 0.1s ease, z-index 0s 0s ease;
	}
}



.no-touchevents{
	body{
		&.scroll-disabled{
			overflow: hidden;
			padding-right: 6px;

			.view-header{
				    padding: 4px 46px 4px 40px;
			}
			.ask-button{
				margin-right: 6px;
			}
		}
	}
}


.touchevents{
	.m-menu__sub-list,
	.m-menu__list{
		&::-webkit-scrollbar{
			width: 6px;
			transition: width 0.2s ease;
			z-index: -1;
		}
	}
}