._mgb50{
	margin-bottom: 26px;

	@include media($md){
		margin-bottom: 36px;
	}		

	@include media($def){
		margin-bottom: 40px;
	}

	@include media($lg){
		margin-bottom: 50px;
	}
}
._mgb30{
	margin-bottom: 20px;

	@include media($ms){
		margin-bottom: 30px;
	}
}
._mgb20{
	margin-bottom: 20px;
}


._mgb10{
	margin-bottom: 10px;
}

._df{
	display: flex;
}
._aic{
	align-items: center;
}