.thumbs-slider-wrapper{
	margin-bottom: 20px;
	position: relative;

	&:after{
		position: absolute;
		content: "";
		left: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.5);
		height: 105px;
		width: 	100%;
		z-index: 2;
		opacity: 0;
		transform: translateX(-10px);
		transition: opacity 0.3s ease, transform 0.3s ease;

		@include media(md){
			height: 130px;
		}
	}

	&.is-nichego-net{
		&:after{
			display: none;
		}
	}

	@include media($ms){
		margin-bottom: 36px;
	}

	&--item{
		.thumbs-slider__item{
			width: 123px;
			height: 148px;
		}

		.thumbs-slider{
			position: relative;
			bottom: 0;
			width: calc(100% - 86px);
			left: 0;
			margin: 0 auto;

			@include media($ms){
				width: calc(100% - 48px);
			}
		}
		.thumbs-slider-main-image{
			margin-bottom: 18px;
			max-width: 430px;
			height: 380px;
			margin-left: auto;
			margin-right: auto;

			@include media($md){
				margin-bottom: 38px;
				height: 420px;
			}

			@include media($def){
				height: 520px;
			}
		}
		.slider-custom-arrow{
			width: 20px;
			height: 32px;
			color: $c-dark;
			background-color: transparent;
			display: flex;
			justify-content: center;
			align-content: center;
			align-items: center;

			&--left{
				left: 0!important;
				top: auto!important;
				bottom: 62px!important;
				right: auto!important;
			}

			&--right{
				right: 0!important;
				top: auto!important;
				bottom: 52px!important;
				left: auto!important;
			}


			svg{
				width: 14px;
				height: 22px;
				fill: currentColor!important;
			}

			&:hover{
				background-color: transparent;
				color: $c-red;
			}
		}
	}

	.slider-custom-arrow--left{
		bottom: 50px;
		right: 0;
		top: auto;
		left: auto;
		opacity: 0;
		transform: translateX(-10px);
		transition: opacity 0.3s ease, transform 0.3s ease;

		@include media($sm){
			bottom: 60px;
			right: 10px;
		}

		@include media($mg){
			bottom: 10px;
			right: 110px;
		}
	}

	.slider-custom-arrow--right{
		bottom: 0px;
		right: 0;
		top: auto;
		left: auto;
		opacity: 0;
		transform: translateX(-10px);
		transition: opacity 0.3s ease, transform 0.3s ease;

		@include media($sm){
			bottom: 10px;
			right: 10px;
		}
	}

	&:hover{
		.thumbs-slider,
		.slider-custom-arrow--right,
		.slider-custom-arrow--left,
		&:after{
			opacity: 1;
			transform: translateY(0);
		}
	}
}

.thumbs-slider-main-image{
	width: 100%;
	height: 320px;
	overflow: hidden;
	position: relative;
	background-color: transparent;
	display: block;
	outline: none;

	@include media($sm){
		height: 486px;
	}

	@include media($md){
		height: 686px;
	}

	img{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		max-width: initial;
		max-height: 100%;
	}
}

.thumbs-slider{
		bottom: 10px;
	left: 0;
	width: calc(100% - 56px);
	position: relative;
	margin-right: auto;
	margin-left: 0;
	z-index: 5;
	opacity: 0;
	transform: translateX(-10px);
	transition: opacity 0.3s ease, transform 0.3s ease;

	@include media($sm){
		position: absolute;
		width: calc(100% - 70px);
	}

	@include media($mg){
		width: calc(100% - 212px);
	}

	&__item{
		cursor: pointer;
		width: 92px;
		height: 66px;
		margin-right: 6px;
		border: 1px solid transparent;

		@include media($sm){
			width: 136px;
			height: 97px;
		}

		&.is-active{
			cursor: default;
			border-color: $c-red;
		}
	}
}