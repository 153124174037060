.inview{
	opacity: 0;
	transition: opacity 0.2s 0.1s ease-out;
	pointer-events: none;
	will-change: transform;

	&.is-inviewed{
		opacity: 1;
		pointer-events: auto;
	}

	&--from-left{
		transform: translate(-15%, -2%);
		transition: opacity 0.4s 0.1s ease-out, transform 0.2s 0.1s ease-out;

		&.is-inviewed{
			transform: translate(0, 0);
		}
	}

	&--from-bottom{
		transform: translate(0, 60px);
		transition: opacity 0.4s 0.1s ease-out, transform 0.2s 0.1s ease-out;

		&.is-inviewed{
			transform: translate(0, 0);
		}
	}

	&--from-top{
		transform: translate(0, -60px);
		transition: opacity 0.4s 0.1s ease-out, transform 0.2s 0.1s ease-out;

		&.is-inviewed{
			transform: translate(0, 0);
		}
	}

	&--from-right{
		transform: translate(15%, -2%);
		transition: opacity 0.4s 0.1s ease-out, transform 0.2s 0.1s ease-out;

		&.is-inviewed{
			transform: translate(0, 0);
		}
	}
}