.alphabet-block{
	width: 100%;
}

.alphabet-nav{
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 14px;
	margin-bottom: 16px;
	border-bottom: 1px solid $c-red;
	font-family: 'DIN Pro';

	@include media($md){
		padding-bottom: 26px;
		margin-bottom: 24px;
	}

	&__item{
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 2px;
		margin-bottom: 10px;
		position: relative;
		color: $c-dark;
		margin-right: 10px;
		cursor: pointer;
		transition: color 0.3s ease;
		min-width: 22px;
		display: flex;
		justify-content: center;
		align-items: center;

		@include media($md){
			font-size: 30px;
			margin-bottom: 16px;
			margin-right: 15px;
		}

		&:after{
			position: absolute;
			left: 0;
			bottom: -5px;
			right: 0;
			height: 2px;
			background-color: $c-red;
			content: "";
			opacity: 0;
			transition: opacity 0.3s ease;

			@include media($md){
				bottom: -16px;
			}
		}
	
		&.is-active{
			&:after{
				opacity: 1;
			}
		}

		&:hover{
			color: $c-red;
		}
	}
}
.alphabet-item{
	overflow: hidden;
	page-break-inside: avoid;
	margin-bottom: 26px;

	&__title{
		font-size: 24px;
		font-family: 'DIN Pro';
		font-weight: bold;
		color: $c-dark;
		padding-bottom: 20px;
		margin-bottom: 24px;
		border-bottom: 1px solid $c-lblue2;
		position: relative;
		transition: color 0.3s 0s ease;

		&:after{
			position: absolute;
			left: 0;
			bottom: -1px;
			width: 24px;
			height: 2px;
			background-color: $c-red;
			content: "";
			transition: width 0.3s 0s ease;
		}
	}
	&__list{
		padding: 0;
		margin: 0;
		list-style-type: none;
		padding-right: 10px;
	}
	&__list-item{
		padding: 0;
		margin-bottom: 4px;
	}
	&__list-link{
		font-size: 16px;
		line-height: 22px;
		color: $c-dark;
		text-decoration: none;

		@include media($lg){
			font-size: 18px;
			line-height: 30px;
		}

		&:hover{
			color: $c-red;
			text-decoration: underline;
		}
	}

	&.is-active{
		.alphabet-item{
			&__title{
				color: $c-red;
				transition: color 0.3s 0.3s ease;

				&:after{
					width: 100%;
					transition: width 0.3s 0.3s ease;
				}
			}
		}
	}
}
.alphabet-grid{
	columns: 1;
	
	@include media($ms){
		columns: 2;
	}
	
	@include media($defp){
		columns: 3;
	}
}