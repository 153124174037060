.catalog-grid{
	display: flex;
	width: 100%;
}

.items-grid{
	display: flex;
	flex-direction: column;
	margin-bottom: 54px;
}

.items-grid-wrapper{
	width: 100%;
	@include media($def){
		width: 82%;
	}

	@include media($mg){
		width: 76%;
	}
}

.catalog-item{
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 2px;
	transition: border-color 0.3s ease, opacity 1.8s 0.1s ease-out, transform 0.8s 0.1s ease-out;
	border: 1px solid $c-lgrey2;

	&__top{
		display: flex;
		margin-bottom: 10px;
		width: 100%;
		flex-wrap: wrap;

		@include media($lg){
			flex-wrap: nowrap;
		}	
	}

	&__descr-block{
		padding-top: 10px;
		max-width: 636px;
		min-width: 300px;
		width: 100%;
		padding-right: 8px;
		padding-left: 8px;

		@include media($ms){
			width: calc(100% - 250px);
			padding-left: 0;
			padding-right: 20px;
			padding-top: 40px;
		}

		@include media($lg){
			width: 50%;
		}
	}

	&__stats{
		display: flex;
		align-items: center;
		padding-top: 20px;
		width: 100%;
		flex-direction: column;

		@include media($sm){
			flex-direction: row;
		}

		@include media($lg){
			width: 38%;
			padding-top: 90px;
		}
	}

	&__bottom{
		display: flex;
		padding-bottom: 24px;
		align-items: center;
		flex-direction: column;

		@include media($ms){
			align-items: flex-end;
			flex-direction: row;
		}

		.stats-widget__item{
			padding-left: 0;
			max-width: 100%;
			margin-right: 0;
			padding-right: 0;
			margin-bottom: 14px;
			border: none;

			@include media($ms){
				padding-left: 34px;
				margin-bottom: 0;
				max-width: 230px;
				margin-right: 14px;
			}

			.stats-widget__title{
				font-size: 14px;
				font-weight: 300;
				padding-bottom: 0;
				margin-bottom: 16px;
				line-height: 18px;
			}
		}
		.buy-widget{
			margin-bottom: 0;
			margin-right: 0;
			padding: 0 10px;
			@include media($ms){
				padding: 0;
			}
		}
		.buy-widget__form{
			.form__group{
				margin-bottom: 14px;

				@include media($ms){
					margin-bottom: 0;
				}
			}
			.button{
				margin-bottom: 0;
			}
		}
	}

	&__text{
		font-size: 16px;
		line-height: 18px;
		color: $c-dark;
		overflow: hidden;
		max-height: 126px;

		@include media($defp){
			font-size: 18px;
			line-height: 24px;
			max-height: 118px;
		}
	}

	&__title{
		font-size: 20px;
		line-height: 22px;
		margin-bottom: 10px;
		padding-bottom: 8px;
		position: relative;
		text-decoration: none;
		color: $c-dark;
		display: block;

		@include media($defp){
			font-size: 22px;
			line-height: 24px;
			margin-bottom: 16px;
			padding-bottom: 15px;
		}

		&:after{
			position: absolute;
			left: 0;
			bottom: 0;
			width: 24px;
			height: 2px;
			background-color: $c-red;
			content: "";
		}

		&:hover{
			color: $c-red;
		}
	}

	&__image{
		flex-shrink: 0;
		width: 210px;
		height: 180px;
		overflow: hidden;
		position: relative;
		margin: 0 auto;

		@include media($ms){
			margin: 0 14px 0 0;
			width: 230px;
			height: 210px;
		}

		img{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&:hover{
		border-color: $c-red;
	}
}