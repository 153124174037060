.stats-widget{
	width: 100%;
	border-left: 1px solid $c-lblue2;
	border-right: 1px solid $c-lblue2;
	margin-bottom: 20px;

	@include media($md){
		margin-bottom: 35px;
	}

	&__item{
		width: 100%;
		border-left: 1px solid $c-lblue2;
		border-right: 1px solid $c-lblue2;
		padding: 0 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		text-align: center;
		flex-direction: column;
	}
	&__title{
		margin-bottom: auto;
		font-size: 16px;
		line-height: 18px;
		color: $c-dark;
		padding-bottom: 24px;
		width: 100%;

		@include media($mg){
			font-size: 18px;
			line-height: 24px;
		}
	}
	&__text{
		font-weight: bold;
		color: $c-dark;
		font-size: 18px;
		padding-bottom: 14px;
		width: 100%;

		&--icon{
			font-weight: normal;
			display: flex;
			text-decoration: none;
			align-items: center;
			padding-bottom: 4px;
			svg{
				width: 38px;
				margin-right: 12px;
				height: 40px;
				fill: $c-dark;
			}

			&:hover{
				color: $c-red;
				svg{
					fill: $c-red;
				}
			}
		}
	}
}