////
/// @group _partials/form.scss
////


// scss
// ================================

.form {
	position: relative;
	font-size: 1rem;

	&__group {
		display: block;
		position: relative;
		margin: 0 0 18px;
		width: 100%;

		@include media($md){
			margin: 0 0 28px;
		}
		& & {
			margin: 0 0 16px;
		}

		&--ask-flag{
			display: flex;
			align-items: center;
			padding-top: 14px;
		}
	}

	&__label {
		cursor: default;
		display: inline-block;
		vertical-align: top;
		user-select: none;
		margin-bottom: .4rem;
	}

	&__caption {
		font-size: 15px;
		color: $c-grey;
		display: block;
		margin-bottom: 3px;
	}

	&__info {
		display: none;
		pointer-events: none;
		position: absolute;
		top: 0;
		right: 0;
		font-size: .7em;
		background-color: #000;
		color: #fff;
		padding: .1em .3em;
		input:focus ~ &,
		textarea:focus ~ &,
		select:focus ~ & {
			display: block;
		}
	}
}

label.form__label {
	cursor: pointer;
}


.control-holder {
	position: relative;

	label.has-error {
		display: inline-block;
		vertical-align: top;
		user-select: none;
		font-size: 12px;
		right: 20px;
		line-height: 100%;
		padding: 2px 2px;
		color: $c-red;
		position: absolute;
		top: 100%;
		background-color: transparent;
	}

	&--text {
		input, textarea, select {
			padding: 12px 20px;
			display: block;
			width: 100%;
			border: 1px solid $c-esheodinGREY;
			transition: border-color 0.3s ease;
			outline: none;

			&:focus{
				border-color: $c-blue;
			}

			&.has-error{
				border-color: $c-red;
			}
			
		}
		textarea {
			resize: none;
			min-height: 7em;
			max-height: 18em;
		}

		input, select{
			height: 50px;
		}
	}

	&--flag {
		label {
			user-select: none;
			margin-right: 1em;
			cursor: pointer;
			position: relative;
			z-index: 1;
			&:only-child,
			&.has-error {
				cursor: default;
				margin-right: 0;
			}
		}
		ins {
			position: relative;
			display: inline-block;
			vertical-align: text-bottom;
			width: 25px;
			height: 25px;
			background-color: #fff;
			border: 1px solid $c-red;
			background-repeat: no-repeat;
			background-position: 50%;
			cursor: pointer;
		}
		span{
			font-size: 16px;
			color: $c-dark;
			cursor: pointer;
		}
		input {
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			opacity: 0;
			z-index: -1;
			&[type="radio"] {
				~ ins {
					border-radius: 50%;
				}
			}
			&:checked {
				~ ins {
					background-color: $c-red;
					background-image: url("pic/okay.svg");
				}
			}
			&:disabled {
				~ ins,
				~ span {
					opacity: .5;
					cursor: not-allowed;
				}
			}
		}
	}

	&--file {
		label {
			position: relative;
			display: inline-block;
			vertical-align: top;
		}

		input {
			position: absolute;
			outline: none;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			opacity: 0;
		}

		._ellipsis {
			max-width: 200px;
			width: auto;
			display: inline-block;
			vertical-align: top;
		}
	}
}
