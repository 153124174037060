////
/// @group style.scss
////


// Config
// ================================
	@import 'config';


// Фикс mqpacker
// ================================
	@include mqPacker;


// Normalize
// ================================

	@include normalize__document;
	@include normalize__sections;
	@include normalize__grouping-content;
	@include normalize__text-level-semantics;
	@include normalize__embedded-content;
	@include normalize__forms;
	@include normalize__interactive;
	@include normalize__scripting;
	@include normalize__hidden;

	@include normalize__text-level-semantics__psevdo-classes;
	@include normalize__forms__psevdo-classes;
	@include normalize__addons;


// Import Partials
// ================================
	// основа
	@import '_partials/base';

	// структура
	@import '_partials/view-wrapper';
	@import '_partials/view-size';
	@import '_partials/view-section';
	@import '_partials/view-header';
	@import '_partials/view-footer';

	// hidden
	@import '_partials/mfp-zoom-in';
	@import '_partials/hidden-wrapper';

	// контент
	@import '_partials/view-text';
	@import '_partials/table-wrapper';
	@import '_partials/content-image';
	@import '_partials/title';

	// ui
	@import '_partials/breadcrumbs';
	@import '_partials/sitemap';
	//@import '_partials/buttons';
	@import '_partials/buttons-default';
	@import '_partials/pagination.scss';
	@import '_partials/form';
	@import '_partials/fPopUp';


// Grid
// ================================
	@include grid-full;


// Include Utils
// ================================
	@include util-colors;
	@include util-bgcolors;
	@include util-fills;

	@include util-floats;
	@include util-floats-mq;

	@include util-texts;
	@include util-texts-mq;

	@include util-visibility;
	@include util-visibility-mq;

	@include util-gutters;
	@include util-gutters-mq;


// Custom css
// ================================


@import '_partials/_mobile-menu';
@import '_partials/_burger';
@import '_partials/_categories';
@import '_partials/_semiblock';
@import '_partials/_services';
@import '_partials/_solutions';
@import '_partials/_news';
@import '_partials/_seo';
@import '_partials/_wezom';
@import '_partials/_alphabet';
@import '_partials/_brends';
@import '_partials/_inner-page';
@import '_partials/_semislider';
@import '_partials/_main-slider';
@import '_partials/_to-top';
@import '_partials/_preheader';
@import '_partials/_catalog-widget';
@import '_partials/_socs-widget';
@import '_partials/_subnav-widget';
@import '_partials/_contacts';
@import '_partials/_map';
@import '_partials/_inview';
@import '_partials/_page404';
@import '_partials/_faq';
@import '_partials/_catalog';
@import '_partials/_search';
@import '_partials/_buttons';
@import '_partials/_buy-widget';
@import '_partials/_thumbs-slider';
@import '_partials/_stats-widget';
@import '_partials/_filter';



.ask-button{
	position: fixed;
	bottom: 0;
	top: auto;
	margin: 0;
	right: -180px;
	width: 46px;
	text-transform: uppercase;
	font-size: 14px;
	text-decoration: none;
	color: $c-white;
	letter-spacing: 0.40px;
	height: 46px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $c-blue;
	transition: background-color 0.3s ease;
	z-index: 25;
	text-align: center;
	justify-content: center;
	padding: 4px 6px;
	transform: rotate(0);
	opacity: 1;
	transition: right 0.5s ease-out, opacity 0.3s ease;

	@include media($def){
		width: 260px;
		height: 46px;
		transform: rotate(-90deg);
		top: 20%;
		bottom: 0;
		margin: auto 0;
	}

	span{
		display: none;
		@include media($def){
			display: block;
		}
	}

	svg{
		width: 28px;
		height: 28px;
		fill: $c-white;
		display: block;
		@include media($def){
			display: none;
		}
	}

	@include media($def){
		top: 50%;
	}

	&:hover{
		background-color: $c-red;
	}

	&.is-active{
		opacity: 1;
		right: 0;
		@include media($def){
		right: -108px;
		}
	}
}


.page-title{
	font-size: 24px;
	line-height: 100%;
	font-weight: bold;
	color: $c-dark;
	font-family: 'DIN Pro';

	@include media($ms){
		font-size: 38px;
	}


	@include media($md){
		font-size: 38px;
	}

	@include media($def){
		font-size: 48px;
	}

	&--inner{
		margin-bottom: 26px;

		@include media($def){
			margin-bottom: 56px;
		}
	}
}
.page-subtitle{
	font-size: 24px;
	line-height: 26px;
	color: $c-dark;
	font-weight: bold;
	margin-bottom: 54px;

	@include media($md){
		font-size: 30px;
		line-height: 36px;
	}
}

.logo{
	margin: 0 auto 0 40px;
	display: block;

	&--footer{
		margin: 0 0 60px 0;
	}

	svg{
		width: 	254px;
		height: 60px;
	}
}
.copyright{
	font-size: 12px;
	color: $c-white;
	line-height: 14px;
	font-weight: 300;
	max-width: 200px;
	display: flex;
	align-items: center;
	margin: 0 auto 20px auto;

	@include media($ms){
		margin: 0;
	}
}
.tools-widgets{
	display: flex;
	justify-content: center;
	margin-left: auto;
}
h1{
	margin: 0;
}
.tools-widget{
	width: 46px;
	height: 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	background-color: $c-blue;
	margin: 1px;
	cursor: pointer;
	font-weight: 300;
	transition: background-color 0.1s ease;
	position: relative;
	color: $c-white;
    backface-visibility: hidden;

	&__lngs{
		position: absolute;
		top: 100%;
		margin-top: 1px;
		left: 0;
		width: 100%;
		opacity: 1;
		transition: transform 0.3s ease;
		transform: scaleY(0);
		transform-origin: top;
	}
	&__lng{
		width: 46px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: center;
		align-content: center;
		background-color: $c-blue;
		text-decoration: none;
		cursor: pointer;
		font-weight: 300;
		margin-bottom: 1px;
		transition: background-color 0.3s ease, color 0.3s ease;
		position: relative;
		color: $c-white;

		&:hover{
			background-color: $c-lblue3;
			color: $c-blue;
		}
	}

	&--filter{

		display: flex;
		@include media($def){
			display: none;
		}
		.tools-widget{
			&__content{
				svg{
					width: 32px;
					height: 32px;
					fill: $c-red;
				}
			}
		}
	}

	&__content{
		font-size: 18px;
		color: currentColor;
		line-height: 100%;
		z-index: 	2;
		position: relative;

		svg{
			fill: currentColor;
			width: 24px;
			height: 16px;
		}
	}

	&__decor{
		width: 100%;
		height: 3px;
		position: absolute;
		left: 0;
		z-index: 3;
		margin: auto;
		opacity: 0;
		transition: opacity 0.3s ease;

		&:nth-child(2),
		&:nth-child(4){
			&:before{
				left: 0;
			}
			&:after{
				right: 0;
			}
			&:before,
			&:after{
				position: absolute;
				top: 0;
				width: 45%;
				height: 100%;
				content: "";
				background-color: $c-blue;
				transition: background-color 0.1s ease, transform 0.3s ease;
			}
		}

		&:nth-child(2){
			transform-origin: center;
			top: 0;
			right: auto;
				bottom: 0;
			transition: transform 0.3s ease, top 0.3s ease, left 0.3s ease, right 0.3s ease, bottom 0.3s ease, margin 0.3s ease;
		}
		&:nth-child(4){
			top: 0;
			bottom: 0;
			right: auto;
			transform-origin: center;
			transition: transform 0.3s ease, top 0.3s ease, left 0.3s ease, right 0.3s ease, bottom 0.3s ease, margin 0.3s ease;
		}
		&:nth-child(3){
			display: none;
		}
	}

	&:hover{
		background-color: $c-lblue3;
		transition: background-color 0.3s ease;
		color: $c-blue;
		.tools-widget{
			&__lngs{
				transform: scaleY(1);
			}
		}
	}

	&:after{
		position: absolute;
		transition: top 0.3s ease, opacity 0.3s ease;
		opacity: 0;
		left: 0;
		height: 16px;
		background-color: $c-lblue3;
		content: "";
		width: 100%;
		top: calc(100% - 16px);
	}

	&.js-close-mfp,
	&.is-active{
		background-color: $c-lblue3;

		.tools-widget{
			&__content{
				opacity: 0;
			}
			&__lngs{
				transform: scaleY(1);
			}
			&__decor{
				opacity: 1;
				&:nth-child(2){
					transform: rotate(45deg);
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					&:before{
						transform: translateX(-3px);
					}
					&:after{
						transform: translateX(3px);
					}
					&:before,
					&:after{
						transition: transform 0.3s 0.2s ease, top 0.3s ease, left 0.3s ease, right 0.3s ease, bottom 0.3s ease, margin 0.3s ease;
					}
				}
				&:nth-child(4){
					transform: rotate(-45deg);
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					&:before{
						transform: translateX(-3px);
					}
					&:after{
						transform: translateX(3px);
					}
					&:before,
					&:after{
						transition: transform 0.3s 0.2s ease, top 0.3s ease, left 0.3s ease, right 0.3s ease, bottom 0.3s ease, margin 0.3s ease;
					}
				}
				&:nth-child(3){
					transform: translateX(-30px);
					opacity: 0;
					transition: transform 0.3s ease, opacity 0.3s ease;
				}
			}
		}

		&:after{
			top: 100%;
			opacity: 1;
		}
	}

	&.is-disabled{
		pointer-events: none;
	}
}

.footer-nav{
	list-style-type: none;
	padding: 0;
	margin: 0;
	columns: 2;
	padding-left: 0;
	width: 100%;

	@include media($lg){
		padding-left: 14%;
	}

	&__item{
		margin-bottom: 10px;
		padding: 0 10px;
	}
	&__link{
		color: $c-white;
		text-decoration: none;
		font-size: 16px;
		font-family: 'Open Sans';

		&:hover{
			text-decoration: underline;
		}
	}
}
.mfp-wrap{
	transition: top 0.2s ease;
}

.item-page{
	&__text{
		font-size: 16px;
		line-height: 20px;
		color: $c-dark;
		margin-bottom: 20px;

		@include media($md){
			margin-bottom: 40px;
			font-size: 18px;
			line-height: 30px;
		}
	}
}

.page-minititle{
	font-size: 22px;
	color: $c-dark;
	margin-bottom: 20px;

	@include media($md){
		margin-bottom: 28px;
	}
}
.hide-in-normal{
	display: flex;

	@include media($def){
		display: none;
	}
}
.m-menu__sub-list-item{

	&:hover{
		& >
		a svg{
			fill: #fff;
		}
	}

	& >
	a svg{
		width: 40px;
		height: 26px;
		margin-left: 20px;
		fill: $c-dark;
	}
}
.show-in-normal{
	display: none;
	margin-top: 14px;

	@include media($def){
		display: flex;
	}
}
.phone{
	margin: 4px 0;

	&--menu{
		color: $c-dark;
		font-size: 16px;

		@include media($mg){
			font-size: 24px;
		}
	}
	&--footer{
		color: $c-white;
		font-size: 18px;

		@include media($def){
			font-size: 24px;
		}
	}
	&__link{
		font-family: 'DIN Pro';
		line-height: 110%;
		font-weight: bold;
		color: currentColor;
		text-decoration: none;
		outline: none;

		&:hover{
			color: $c-red;
		}
	}
}
.footer-socs{
	display: flex;
	align-items: center;
	margin: 0 auto;
}
.soc-item{
	text-decoration: none;
	display: flex;
	width: 22px;

	&--menu{
		color: $c-grey;
		margin-right: 10px;
		@include media($mg){
			margin-right: 20px;
		}
			svg{
				fill: $c-grey;
			}
		&:hover{
			svg{
				fill: $c-red;
			}
		}
	}
	&--footer{
		color: $c-white;
		margin-right: 20px;
			svg{
				fill: $c-white;
			}
		&:hover{
			svg{
				fill: $c-red;
			}
		}
	}
	&__image{
		max-width: 22px;
		max-height: 23px;
		width: 100%;
		height: 100%;

		// &--1{
		// 	width: 11px;
		// 	height: 22px;
		// }
		// &--2{
		// 	width: 21px;
		// 	height: 12px;
		// }
		// &--3{
		// 	width: 12px;
		// 	height: 17px;
		// }
		// &--4{
		// 	width: 19px;
		// 	height: 18px;
		// }
		// &--5{
		// 	width: 19px;
		// 	height: 23px;
		// }
	}
}


@media (max-width: 1025px){
	._def-dn{
		display: none!important;
	}
}
@media (max-width: 641px){
	._ms-dn{
		display: none!important;
	}
}


.slider-custom-arrow{
	position: absolute;
	right: 10px;
	width: 50px;
	height: 50px;
	background-color: $c-wred;
	transform: translateY(-50%);
	z-index: 25;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: background-color 0.3s ease;
	user-select: none;

	@include media($mg){
		width: 100px;
		height: 100px;
		right: 0;
	}

	svg{
		width: 22px;
		height: 22px;
		fill: $c-red;
		transition: fill 0.3s ease;
	}

	&:hover{
		 background-color: $c-red;

		 svg{
		 	fill: $c-white;
		 }
	}

	&--resp{
		display: flex;

		@include media(md){
			display: none;
		}
	}

	&--right{
		top: calc(50% - 25px);
		@include media($mg){
			top: calc(50% - 50px);
		}
	}
	&--left{
		top: calc(50% + 25px);
		@include media($mg){
			top: calc(50% + 50px);
		}
	}
}

.characteristics{
	width: 100%;
	border: none;
	border-spacing: 0;
	border-bottom: 1px solid $c-lgrey;
	margin-bottom: 20px;

	@include media($md){
		margin-bottom: 40px;
	}

	&__content{
		display: flex;
		align-items: center;
	}
	&__row{
		&:nth-child(odd){
			background-color: $c-lgrey;
		}
		.characteristics{
			&__cell{
				padding: 8px 6px;
				@include media($ms){
					padding: 12px 26px;
				}
				&:nth-child(1){
					width: 38%;
					min-width: 230px;
					.characteristics{
						&__text{
							margin-right: 10px;
						}

						&__content{
							display: flex;
							&:after{
								content: ":";
								margin-left: 4px;
								font-size: 16px;
								color: $c-dark;
								padding-bottom: 2px;

								@include media($ms){
									margin-left: auto;
									font-size: 18px;
									padding-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
	}
	&__text{
		font-size: 14px;
		color: $c-dark;

		@include media($ms){
			font-size: 16px;
		}

		@include media($md){
			font-size: 18px;
		}
	}
}
@media (max-width: 520px){
	.characteristics__row{
		display: flex;
		flex-direction: column;
	}
}
.partners-item{
	height: 120px;

	@include media($def){
		height: 160px;
	}

	@include media($mg){
		height: 200px;
	}

	&__image{
		position: absolute;
		width: 100%;
		height: 100%;
		transition: transform 0.3s ease, opacity 0.3s ease;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			overflow: hidden;

		img{
			position: absolute;
			max-width: calc(100% - 100px);
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
}


@import '_partials/_halpers';
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .button--main .button__icon{
  	margin-right: 20px;
  }
  .m-menu__list-link, .m-menu__sub-list-link{
  	padding: 14px 4px 14px 40px;
  	min-height: 36px;
  }
  .button--sub .button__icon{
  	right: 35px;
  	position: relative;
  }
  .alphabet-item.inview{
  	opacity: 1;
  }
  @media (max-height: 1540px){

  .m-menu__list-link, .m-menu__sub-list-link{
  	padding: 6px 4px 6px 20px;
  	min-height: 16px;
  }
}
}


body::-webkit-scrollbar-track,
body *::-webkit-scrollbar-track
{
	background-color: transparent;
}

body::-webkit-scrollbar,
body *::-webkit-scrollbar
{
	width: 6px;
	background-color: rgba(224, 224, 224, 0.4);
}

body::-webkit-scrollbar-thumb,
body *::-webkit-scrollbar-thumb
{
	background-color: #999;
	border-radius: 4px;
}

.disabled-filter-input{
	opacity: 0.7;
	cursor: no-drop;
}

.breadcrumbs-dummy{
	width: 100%;
	opacity: 0;
	position: absolute;
	left: 0;
	top: 0;
}
.breadcrumb-mega-wrapper{
	position: relative;
}
*.no-image{
	background-color: #031c3c;
}
.gcell.swiper-slide{
	height: initial;
}
.js-resp-slider .swiper-wrapper{
	box-sizing: border-box;
}
.swiper-container-horizontal{
	.swiper-wrapper{
		box-sizing: content-box;
		&.grid{
			flex-wrap: nowrap;
		}
		.gcell.swiper-slide{
			height: 100%;
			padding: 0;
		}
	}
}
