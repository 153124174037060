////
/// @group _partials/view-wrapper.scss
////


// scss
// ================================

.view-wrapper {
	position: relative;
	min-height: 100%;
	overflow: hidden;
	padding-top: 60px;
	display: flex;
	flex-direction: column;

	@include media($ms){
		padding-top: 80px;
	}
}
.view-footer{
	margin-top: auto;
}