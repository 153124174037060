////
/// @group _partials/breadcrumbs.scss
////

// scss
// ================================

.breadcrumbs {
	width: 100%;
	display: table;
	font-size: 0;
	line-height: 0;
	font-size: 14px;
	color: $c-white;
	font-family: 'Open Sans';
	@include media($ms){
		font-size: 16px;
	}
	@include media($def){
		font-size: 18px;
	}
}

.breadcrumbs-wrapper{
	background-color: $c-blue;
	width: 100%;
	padding: 10px 16px;

	@include media($ms){
		padding: 20px 32px;
	}
}
.breadcrumb-mega-wrapper{
	margin-bottom: 30px;
	
	@include media($def){
		margin-bottom: 60px;
	}
}

.breadcrumbs a {
	color: currentColor;
	text-decoration: none;
	font-weight: normal;

	&:hover{
		color: $c-red;
	}
}

.breadcrumbs span {
	color: currentColor;
	display: inline-block;
	vertical-align: top;
	font-size: 1em;
	line-height: 1.4;
	margin-right: .5em;
	font-weight: bold;

	&:before {
		margin-right: .5em;
		content: '\203A';
		display: inline-block;
		vertical-align: top;
		font-weight: normal;
		line-height: 90%;
		font-size: 22px;
	}

	&:first-child:before {
		display: none;
	}
}

.breadcrumbs {
	@include media( $md, max ) {
		display: block;

		span {
			&, &:before {
				display: none;
			}

			&:nth-last-child(2) {
				display: block;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: 100%;
				background: none;
				margin: 0;

				&:before {
					content: '\2039';
					margin: 0 5px 0 3px;
					display: inline-block;
				}
			}
		}
	}
}
