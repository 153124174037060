////
/// @group _partials/sitemap.scss
////


// vars
// ================================

/// @type Color
$sitemap-hoverbg: rgba( #000, .035 ) !default;

// scss
// ================================

// общие правила
.sitemap {
	max-width: $md;
	padding: 1rem;
	position: relative;
	font-size: 1rem;
	margin-bottom: 1.5rem;

	@include media( $md ) {
	}

	ul {
		list-style: none;
		padding: 0 0 0 2em;
		margin: 0;
	}

	li {
		list-style: none;
		line-height: 1.4em;
	}

	a {
		display: block;
	}
}

// первый уровень вложенности
.sitemap > ul {
   
    & > li > a{
    	font-size: 1.2rem;
	    font-weight: 500;
	    
	    text-decoration: none;
	    color: $c-red;
	    transition: color .5s ease;
	    border-bottom: 2px solid #f1ebdf;
	    min-height: 40px;
	    padding: 8px 0;
	    display: flex;
	    align-items: center;
	    text-decoration: none;
	    cursor: pointer;
	    position: relative;
	    &:after {
		    position: absolute;
		    left: 0;
		    bottom: -2px;
		    content: "";
		    height: 2px;
		    width: 100%;
		    transform: rotateY(86deg);
		    transform-origin: left;
		    transition: background-color .5s ease,-webkit-transform .6s ease;
		    transition: background-color .5s ease,transform .6s ease;
		    transition: background-color .5s ease,transform .6s ease,-webkit-transform .6s ease;
		    background-color: #b89b5e;
		}
		&:hover{
			color: $c-blue;
			&:after{
				background-color: $c-blue;
			}
		}
    }
}

// второй уровень
// без паддингов
.sitemap > ul > li > ul {
	padding-left: 0;
	& > li > a{
		// padding-left: 20px;
		// font-size: 1.75rem;
	 //    font-weight: 500;
	 //    
	 //    text-decoration: none;
	 //    color: #b89b5e;
	 //    transition: color .5s ease;
	 //    border-bottom: 2px solid #f1ebdf;
	 //    height: 60px;
	 //    display: flex;
	 //    align-items: center;
	 //    text-decoration: none;
	 //    cursor: pointer;
	 //    position: relative;
	 //    &:after {
		//     position: absolute;
		//     left: 0;
		//     bottom: -2px;
		//     content: "";
		//     height: 2px;
		//     width: 100%;
		//     transform: rotateY(86deg);
		//     transform-origin: left;
		//     transition: background-color .5s ease,-webkit-transform .6s ease;
		//     transition: background-color .5s ease,transform .6s ease;
		//     transition: background-color .5s ease,transform .6s ease,-webkit-transform .6s ease;
		//     background-color: #b89b5e;
		// }
		// &:hover{
		// 	color: $c-blue;
		// 	border-color: #f1ebdf;
		// 	&:after{
		// 		background-color: $c-blue;
		// 	}
		// }
	}
}
.sitemap > ul > li > ul > li > ul > li > ul > li > a{
	color: $c-red;
}

// все остальные уровни
.sitemap > ul ul {
	list-style: none;
	padding: 0 0 0 2em;
	margin: 0;
	a{
	    font-size: 1rem;
	    
	    color: #2d3b4e;
	    font-weight: 500;
	    transition: color .5s ease, border-color 0.5s ease;
	    border-bottom: 1px solid #e1dbcd;
	   	min-height: 30px;
	   	padding: 8px 0 8px 20px;
	    display: flex;
	    align-items: center;
	    text-decoration: none;
	    cursor: pointer;
	    position: relative;
	    &:hover{
	    	color: $c-blue;
	    	border-color: $c-blue;
	    }
	}
}

// внутрение элементы
// начиная со второго уровня вложености
.sitemap > ul ul li {

	position: relative;
	margin: .1em 0;
}

