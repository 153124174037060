.news-item{
	width: 100%;
	text-decoration: none;
	will-change: transform;
	backface-visibility: hidden;

	&__image{
		height: 180px;
		position: relative;
		overflow: hidden;
		background-color: $c-black;

		@include media($md){
			height: 240px;
		}

		@include media($def){
			height: 280px;
		}

		@include media($lg){
			height: 340px;
		}

		img{
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			position: absolute;
			min-width: 100%;
			max-width: initial;
			z-index: 1;
			transition: transform 0.3s ease, opacity 0.3s ease;
			opacity: 0.7;
		}
	}

	&__button-text{
		font-size: 12px;
		color: $c-white;
		letter-spacing: 1px;
		font-weight: 300;
		font-family: 'Open Sans';
		text-transform: uppercase;
		padding-left: 36px;
	}

	&__button-svg-wrapper{
		width: 50px;
		height: 50px;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		transition: background-color 0.3s ease;
		align-items: center;
	}

	&__button-decor{
		position: absolute;
		bottom: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		z-index: 3;
		align-items: center;

		svg{
			width: 10px;
			height: 15px;
			fill: $c-white;
			position: relative;
			z-index: 2;
		}
	}

	&__text{	
		font-size: 18px;
		color: $c-black;
		line-height: 24px;
		font-family: 'Open Sans';
		margin-bottom: 12px;
		transition: color 0.3s ease;

		@include media($md){
			font-size: 22px;
			line-height: 30px;
		}
	}

	&__date{
		font-weight: 300;
		font-size: 12px;
		color: $c-grey;
		font-family: 'Open Sans';
		letter-spacing: 1px;
	}

	&__descr{
		height: auto;
		border-left: 1px solid $c-lgrey2;
		border-right: 1px solid $c-lgrey2;
		border-bottom: 1px solid $c-lgrey2;
		padding: 8% 7.5%;

		@include media($md){
			height: 200px;
		}
	}

	&__decor{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
		
		&:after,
		&:before{
			content: "";
			position: absolute;
			width: 20px;
			height: 20px;
			opacity: 0;
			transition: opacity 0.3s 0.2s ease, transform 0.3s 0.2s ease;
		}
		&:after{
			left: 35px;
			top: 35px;
			border-top: 1px solid $c-red;
			border-left: 1px solid $c-red;
			transform: translate(-25px, -25px);

			@include media($md){
				left: 50px;
				top: 50px;
			}
		}
		&:before{
			right: 35px;
			bottom: 35px;
			border-bottom: 1px solid $c-red;
			border-right: 1px solid $c-red;
			transform: translate(25px, 25px);

			@include media($md){
				right: 50px;
				bottom: 50px;
			}
		}
	}

	&:hover{
		.news-item{
			&__text{
				color: $c-red;
			}
			&__button-svg-wrapper{
				background-color: $c-red;
			}
			&__decor{
				&:after,
				&:before{
					opacity: 1;
					transform: translate(0, 0);
					transition: opacity 0.3s ease, transform 0.3s ease;
				}
			}
			&__image{
				img{
					transform: scale(1.05) translate(-50%, -50%);
					opacity: 0.5;
				}
			}
		}
	}
}


.news-list{
	margin-bottom: 20px;

	@include media($def){
		margin-bottom: 50px;
	}
}



.innernews{
	&__date{
		font-size: 12px;
		letter-spacing: 1px;
		color: $c-grey;
		font-family: 'DIN Pro';
		font-weight: 300;
	}
}