////
/// @group _partials/hidden-wrapper.scss
////


// scss
// ================================

.hidden-wrapper {
	position: relative;
	padding: 2rem;
	margin: 1.2rem auto;
	background-color: #fff;
	width: 96%;
	max-width: 1024px;

	&--right{
		margin-top: 50px;
		margin-bottom: 0;
		margin-left: auto;
		margin-right: -0;
		max-width: 100%;
		min-height: calc(100vh - 80px);
		width: 100%;

		@include media($ms){
			max-width: 480px;
			margin-right: -8px;
			width: 96%;
			margin-top: 77px;
		}

		.mfp-close{
			display: none;
		}
	}
}

.hidden-title{
	font-size: 22px;
	color: $c-dark;
	margin-bottom: 20px;
	@include media($md){
		margin-bottom: 32px;
	}
}

// размеры
.hidden-wrapper {
	&--lg {
		max-width: 1280px;
	}

	&--md {
		max-width: 768px;
	}

	&--sm {
		max-width: 480px;
	}
}

// отображение
.hidden-wrapper {
	&--strip {
		background: none;
		padding: 0;
	}

	&--no-gap {
		padding: 0;
	}
}

// показать элементы только внутри
.hidden-wrapper {
	._show-in--hidden-wrapper {
		display: block;
	}
}
