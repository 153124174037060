.map-widget{
	width: 100%;
	margin-bottom: 12px;

	&__descr{
		width: 100%;
		position: relative;
		padding: 64px 20px 64px 8.9%;
		color: $c-white;
		font-size: 16px;
		line-height: 18px;
		display: flex;
		flex-direction: column;

		@include media($def){
			font-size: 18px;
			line-height: 20px;
		}
	}
	&__decor-bg{
		width: 100%;
		height: 100%;
		max-height: 700px;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0.1;

		svg{
			height: 614px;
			width: 650px;
			stroke: $c-white;
			fill: transparent;
			position: absolute;
			right: -6px;
			bottom: 6px;
			stroke-width: 0.3px;
		}
	}
	&__city{
		font-size: 36px;
		font-family: 'DIN Pro';
		font-weight: bold;
		padding-bottom: 18px;
		margin-bottom: 30px;
		position: relative;

		@include media($def){
			margin-bottom: 46px;
			padding-bottom: 24px;
		}

		&:after{
			position: absolute;
			left: 0;
			bottom: 0;
			width: 24px;
			height: 2px;
			background-color: $c-white;
			content: "";
		}
	}
	&__addres-text{
		font-size: 20px;
		font-weight: bold;
		font-family: 'DIN Pro';
		line-height: 114%;

		@include media($def){
			font-size: 24px;
		}
	}
	&__phones-wrapper{
		display: flex;
		flex-direction: column;
	}
	&__fax{
		display: flex;
		flex-direction: column;
	}
	&__fax-text{
		margin-bottom: 4px;
	}
	&__link{
		display: flex;
		color: currentColor;
		align-items: center;
	}
	&__work-text{
		display: flex;
		flex-wrap: wrap;
		max-width: 210px;
		width: 100%;
		span{
			width: 50%;
			display: inline-flex;
			margin-bottom: 6px;

			&:nth-child(odd){
				&:after{
					position: relative;
					content: ":";
					margin-left: auto;
					font-size: 18px;
					display: inline-block;
					margin-right: 14px;
					color: currentColor;
				}
			}
		}
	}
	&__addres{
		display: flex;
		margin-bottom: 20px;
		z-index: 1;
		position: relative;

		@include media($def){
			margin-bottom: 30px;
		}
	}
	&__phone{
		font-size: 20px;
		line-height: 100%;
		font-weight: bold;
		color: currentColor;
		font-family: 'DIN Pro';
		text-decoration: none;
		margin-bottom: 4px;

		@include media($def){
			font-size: 24px;
		}
	}
	&__phones{
		display: flex;
		flex-direction: column;
		margin-bottom: 10px;
	}
	&__phones-block{
		display: flex;
		margin-bottom: 30px;
		z-index: 1;
		position: relative;

		@include media($def){
			margin-bottom: 50px;
		}
	}
	&__work-data{
		display: flex;
		margin-bottom: 10px;
		z-index: 1;
		position: relative;

		@include media($def){
			margin-bottom: 20px;
		}
	}
	&__skype{
		display: flex;
		margin-bottom: 10px;
		z-index: 1;
		position: relative;

		@include media($def){
			margin-bottom: 20px;
		}
	}
	&__mail{
		display: flex;
		margin-bottom: 10px;
		z-index: 1;
		position: relative;

		@include media($def){
			margin-bottom: 20px;
		}
	}
	&__map{
		width: 100%;
		border: 1px solid;
		position: relative;
		min-height: 300px;
	}
	&__decor{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
	&__decor-image{
		position: absolute;
		width: 97px;
		height: 100px;
		left: 0;
		top: 0;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		opacity: 0.2;
	}
	&__image{
		width: 28px;
		margin-right: 12px;
		opacity: 0.55;

		@include media($lg){
			margin-right: 22px;
			width: 34px;
		}

		svg{
			max-width: 28px;
			max-height: 28px;
			fill: $c-white;

			@include media($lg){
				max-width: 32px;
				max-height: 32px;
			}
		}
	}

	&--red{
		a:hover{
			color: $c-grey-lblue2;
		}
		.map-widget{
			&__descr{
				background-color: $c-red;
			}
			&__map{
				border-color: $c-red;
			}
		}
	}

	&--blue{
		a:hover{
			color: $c-greyNUBLIA2;
		}
		.map-widget{
			&__descr{
				background-color: $c-blue;
			}
			&__map{
				border-color: $c-blue;
			}
		}
	}

	&--lblue{
		a:hover{
			color: $c-grey-lblue2;
		}

		.map-widget{
			&__descr{
				background-color: $c-lblue;
			}
			&__map{
				border-color: $c-lblue;
			}
		}
	}
}

.map{
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}