////
/// @group _partials/pagination.scss
////

// scss
// ================================

.pagination {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	margin-bottom: 20px;

	&__btns-group{
		display: flex;
		justify-content: center;
		margin: 0 3%;
		align-items: center;
	}
	&__arrow{
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		cursor: pointer;
		svg{
			width: 22px;
			height: 14px;
			fill: $c-dark;
			transition: fill 0.1s ease, transform 0.1s ease;
			transform-origin: center;
		}
		&:hover{
			svg{
				fill: $c-red;
			}
		}
		&:active{
			svg{
				fill: $c-dblue;
				transform: scale(0.92);
				transition: fill 0s ease, transform 0s ease;
			}
		}
	}
	&__btn{
		width: 28px;
		height: 28px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		color: $c-dark;
		text-decoration: none;
		margin: 0 2px;

		@include media($ms){
			width: 34px;
			height: 34px;
		}

		&.is-active{
			background-color: $c-red;
			color: $c-white;
			pointer-events: none;
		}
		&:hover{
			color: $c-red;
		}
	}
}
