.semiblock-widget{
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	@include media($lg){
		flex-direction: row;
	}
}
.semiblock-widget-descr{
	width: 100%;
	min-width: 100%;
	position: relative;
	padding: 20px 1.8%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	@include media($lg){
		width: 25%;
		min-width: 475px;
		padding: 44px 1.8%;
	}

	&__decor{
		width: 97px;
		height: 100px;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		opacity: 0.3;
	}

	&__title{
		position: relative;
		z-index: 3;
		font-size: 28px;
		width: 100%;
		font-weight: bold;
		line-height: 100%;
		color: $c-white;
		margin-bottom: 30px;

		@include media($ms){
			font-size: 32px;
		}

		@include media($md){
			font-size: 40px;
		}

		@include media($def){
			font-size: 48px;
		}

		@include media($lg){
			margin-bottom: auto;
		}
	}

	&__bg{
		width: 100%;
		height: 100%;
		max-height: 700px;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0.1;

		svg{
			height: 614px;
			width: 650px;
			stroke: $c-white;
			fill: transparent;
			position: absolute;
			right: -6px;
			bottom: 6px;
			stroke-width: 0.3px;
		}
	}
	&__bg-wrapper{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	&__button{
		font-size: 12px;
		color: $c-white;
		letter-spacing: 1px;
		text-transform: uppercase;
		font-weight: 300;
		font-family: 'Open Sans';
		text-decoration: none;
		display: inline-flex;
		align-items: center;
		position: relative;
		padding: 10px 70px 10px 10px;

		svg{
			width: 48px;
			height: 10px;
			fill: $c-white;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto 0;
			transition: right 0.3s ease;
		}

		span{
			position: relative;

			&:before{
				position: absolute;
				right: 0;
				bottom: 0;
				content: "";
				width: 0;
				height: 1px;
				background-color: $c-white;
				transition: width 0.3s ease;
			}
		}

		&:hover{
			span{
				&:before{
					width: 100%;
				}
			}
			svg{
				right: 8px;
			}
		}
	}

	&--bg-red{
		background-color: $c-red;
	}

	&--bg-blue{
		background-color: $c-blue;
	}

	&--bg-lblue{
		background-color: $c-lblue;
	}
	&--bg-grey{
		background-color: $c-grey;
	}
}
.semiblock-widget-grid{
	width: 100%;

	@include media($lg){
		width: 75%;
	}
}