
.button{
	border: none;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0;
	outline: none;
	background: transparent;
	text-decoration: none;
	
	&--main{
		.button{
			&__content{
				color: $c-white;
				background-color: $c-red;
				letter-spacing: 1px;
				font-weight: 300;
				text-transform: uppercase;
				padding: 0 20px;
				transition: background-color 0.3s ease;
			}
			&__text{
				margin-left: 0;
				transition: margin 0.3s ease;
			}
			&__icon{
				margin-right: 0;
				transition: margin 0.3s ease;
			}
		}

		&:hover{
			.button{
				&__content{
					background-color: $c-blue;
				}
				&__text{
					margin-left: 10px;
				}
				&__icon{
					margin-right: 10px;
				}
			}
		}

		&:active{
			.button{
				&__content{
					background-color: $c-lblue;
					transition: background-color 0s ease;
				}
				// &__text{
				// 	margin-left: auto;
				// 	margin-right: 2px;
				// 	transition: margin 0s ease;
				// }
				// &__icon{
				// 	margin-right: auto;
				// 	margin-left: 2px;
				// 	transition: margin 0s ease;
				// }
			}
		}
	}
	
	&__content{
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		width: 100%;
	}

	&--disabled{
		pointer-events: none;
	}
	&__icon{
		width: 50px;
		height: 12px;
		margin-left: auto;
		fill: currentColor;
	}
	
	&--w100{
		width: 100%;
		.button__content{
			width: 	100%;
		}
	}

	&--sub{
		.button{
			&__content{
				color: $c-red;
				background-color: transparent;
				border: 1px solid $c-red;
				letter-spacing: 1px;
				font-weight: 300;
				text-transform: uppercase;
				padding: 0 20px;
				transition: background-color 0.3s ease, color 0.3s ease;
			}
			&__text{
				margin-left: 0;
				transition: margin 0.3s ease;
			}
			&__icon{
				margin-right: 0;
				transition: margin 0.3s ease;
			}
		}

		&:hover{
			.button{
				&__content{
					background-color: $c-red;
					color: $c-white;
				}
				&__text{
					margin-left: 10px;
				}
				&__icon{
					margin-right: 10px;
				}
			}
		}

		&:active{
			.button{
				&__content{
					background-color: $c-white;
					transition: background-color 0s ease;
					color: $c-red;
				}
				// &__text{
				// 	margin-left: auto;
				// 	margin-right: 2px;
				// 	transition: margin 0s ease;
				// }
				// &__icon{
				// 	margin-right: auto;
				// 	margin-left: 2px;
				// 	transition: margin 0s ease;
				// }
			}
		}
	}
	&--sm{
		width: 174px;
		height: 50px;
		.button__content{
			height: 50px;
			font-size: 16px;
			width: 174px;
		}
	}
	&--md{
		width: 210px;
		height: 40px;
		.button__content{
			height: 40px;
			font-size: 12px;
			width: 210px;
		}
	}
	&--mdb{
		width: 210px;
		height: 50px;
		.button__content{
			height: 50px;
			font-size: 12px;
			width: 210px;
		}
	}
}