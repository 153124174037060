.to-top{
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
	cursor: pointer;
	display: flex;
	align-items: center;
	padding-right: 0;
	height: 50px;

	&__text{
		text-transform: uppercase;
		font-size: 16px;
		letter-spacing: 1px;
		font-family: 'Open Sans';
		margin-right: 10px;
		color: $c-red;
		display: none;
		transition: opacity 0.3s ease, color 0.3s ease;

		@include media($ms){
			display: block;
		}
	}

	&__button{
		width: 50px;
		height: 50px;
		background-color: $c-red;
		display: flex;
		align-items: center;
		justify-content: center;	
		position: relative;
		top: 0;
		right: 0;
		left: 0;
		margin: 0 auto;
		transition: left 0.3s ease, background-color 0.3s ease;

		svg{
			width: 12px;
			height: 8px;
			fill: $c-white;
		}
	}

	&:hover{
		.to-top{
			&__text{
				// opacity: 0;
				color: $c-lblue;
			}
			&__button{
				// left: 0;
				background-color: $c-lblue;
			}
		}
	}
}

.touchevents{
	&:hover{
		.to-top{
			&__text{
				// opacity: 0;
				color: $c-red;
			}
			&__button{
				// left: 0;
				background-color: $c-red;
			}
		}
	}
}