////
/// @group _partials/view-header.scss
////


// scss
// ================================

.view-header {
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	padding: 4px 16px;
	display: flex;
	align-items: center;
	height: 60px;
	border-bottom: 1px solid #ccd3dd;
		z-index: 11000;
	background-color: $c-white;

	@include media($ms){
		height: 80px;
		padding: 4px 40px;
		z-index: 1100;
	}
	
	@include media($def){
		// z-index: 1100;
	}	
}
