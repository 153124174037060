
.no-touch .main-slider a:hover {
  border-bottom: 2px solid rgba(76, 92, 98, 0.6);
}
.main-slider a.selected {
  color: #00A7E1;
  border-bottom: 2px solid rgba(0, 167, 225, 0.4);
}
.no-touch .main-slider a.selected:hover {
  border-bottom: 2px solid rgba(0, 167, 225, 0.4);
}

.main-slider-wrapper {
  position: relative;
  width: 100%;
  margin: 0;
  height: 300px;
  margin-bottom: 78px;

  @include media($md){
 	 height: 400px;
  }

  @include media($def){
 	 height: 520px;
  }

  @include media($lg){
 	 height: 600px;
  }

  @include media($mg){
  	height: 672px;
  }

	&:hover{
		.main-slider-navigation{
			opacity: 1;
		}
	}
}

.main-slider{
	list-style-type: none;
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
}

.main-slider-item{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 0;
	overflow: hidden;
	height: 100%;
	display: inline-block;
	background-color: rgba(0, 0, 0, 0.8);

	&.is-animating{
		z-index: 3;
		opacity: 1;

		.main-slider-item{
			&__content{
				opacity: 0;
				transition: opacity 0.2s ease;
			}
		}
	}
	&.is-visible {
		position: relative;
		z-index: 2;
		opacity: 1;

		.main-slider-item{
			&__content{
				transition: opacity 0.4s ease;
				opacity: 1;
			}
		}
	}

	&__content{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		padding: 20px 14px;
		z-index: 5;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		opacity: 0;
		transition: opacity 0.2s ease;

		@include media($md){
			padding: 40px 34px;
		}
	}
	&__button{
		padding: 6px 20px;
		height: 40px;
		background-color: $c-red;
		text-decoration: none;
		display: flex;
		font-family: 'Open Sans';
		align-items: center;
		transition: background-color 0.3s ease;

		@include media($md){
			height: 50px;
		}

		&:hover{
			transition: background-color 0.3s ease;
			background-color: $c-lblue;
		}

		span{
			color: $c-white;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 12px;
			font-weight: 300;
		}
		svg{
			fill: $c-white;
			width: 50px;
			height: 12px;
			margin-left: 38px;
		}
	}
	&__text{
		font-size: 32px;
		line-height: 100%;
		color: $c-white;
		font-weight: bold;
		font-family: 'DIN Pro';
		max-width: 868px;
		margin-bottom: 28px;

		@include media($ms){
			font-size: 44px;
		}

		@include media($def){
			font-size: 60px;
		}
	}
	&__image{
		height: 100%;
		width: 100%;
		position: absolute;
		  width: 1914px;
		  margin: 0;
		  height: 300px;
		  left: 50%;
		  transform: translateX(-50%);

		  @include media($md){
		 	 height: 400px;
		  }

		  @include media($def){
		 	 height: 520px;
		  }

		  @include media($lg){
		 	 height: 600px;
		  }

		  @include media($mg){
		  	height: 672px;
		  }

		svg{

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
		}

		&:after{
			position: absolute;
			content: "";
			left: 0;
			bottom: 0;
			width: 100%;
			height: 35%;
			background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.45) 50%, rgba(0,0,0,0.7) 80%, rgba(0,0,0,0.8) 100%);
		}
	}
}

.main-slider-navigation {
	opacity: 0;
	z-index: 15;
		transition: opacity 0.3s ease;
		position: absolute;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
		top: 50%;
		width: 100%;
		list-style-type: none;

	&__item{
		position: absolute;
		z-index: 3;
		bottom: 80px;
		margin: auto 0;
		top: 0;
		height: 40px;
		width: 30px;
		background-color: $c-grey;
		user-select: none;
		transition: background-color	 0.3s ease;

		@include media($md){
			height: 100px;
			width: 50px;
		}

			svg{
				width: 20px;
				height: 30px;
				fill: $c-white;
			}
		&--left{
			left: 0;
			
		}
		&--right{
			right: 0;
		}

		&:hover{
			background-color: 	$c-red;
		}
	}

	&__link{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		color: transparent;
		-webkit-transition: -webkit-transform 0.2s;
		-moz-transition: -moz-transform 0.2s;
		transition: transform 0.2s;
	}
}

.main-slider-controls {
	position: absolute;
	bottom: -25px;
	left: 50%;
	right: auto;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: 3;
	text-align: center;
	width: 90%;
	margin: 0;

	&__item{
		display: inline-block;
		margin-right: 12px;
		color: $c-grey;
		cursor: pointer;

		&:last-of-type {
			margin-right: 0;
		}
		
		&.selected{
			color: $c-red;
			cursor: default;
		}

	}
	&__link{
		display: block;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		height: 5px;
		width: 48px;
		background-color: currentColor;
	}
}
.main-slider-controls::after {
  clear: both;
  content: "";
  display: table;
}
.no-touch .main-slider-controls a:hover {
  background-color: #ffffff;
}
