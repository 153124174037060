////
/// @group _partials/view-section.scss
////


// scss
// ================================

.view-section {
	position: relative;
}
