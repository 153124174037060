.brends-catalog-widget{
	display: flex;
	flex-direction: column;
	width: 100%;

	&__item{
		text-decoration: none;
		width: 100%;
		display: flex;
		align-items: center;
		min-height: 100px;
		padding: 10px 14px;
		position: relative;
		margin-bottom: 2px;

		@include media($ms){
			padding: 30px 38px;
		}

		&:hover{
			.brends-catalog-widget{
				&__item-num{
					span{
						opacity: 1;
					}
				}
				&__image{
					img{
						opacity: 0.3;
						transform: scale(1.05) translate(-50%, -50%);
					}
				}
			}
		}
	}
	&__item-num{
		font-size: 20px;
		font-weight: bold;
		line-height: 22px;
		color: $c-white;
		position: relative;
		padding-bottom: 6px;
		z-index: 2;
		margin-right: 12px;

		@include media($ms){
			margin-right: 34px;
			line-height: 30px;
			font-size: 24px;
		}
		span{
			transition: opacity 0.3s ease;
			opacity: 0.2;
		}

		&:after{
			position: absolute;
			background-color: $c-white;
			content: "";
			width: 100%;
			bottom: 2px;
			left: 0;
			height: 2px;
		}
	}
	&__title{
		font-size: 20px;
		color: $c-white;
		line-height: 24px;
		position: relative;
		z-index: 2;

		@include media($ms){
			line-height: 36px;
			font-size: 30px;
		}
	}
	&__image{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: $c-black;
		overflow: hidden;

		img{
			position: absolute;
			left: 50%;
			top: 50%;
			opacity: 0.5;
			transition: opacity 0.3s ease, transform 0.3s ease;
			transform: translate(-50%, -50%);
			max-width: initial;
			max-height: initial;
			min-height: 100%;
		}
	}
}