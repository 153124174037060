.brend-item{
	height: 240px;
	position: relative;
	box-sizing: border-box;
	backface-visibility: hidden;
	transition: border-color 0.3s ease;

	@include media($def){
		height: 240px;
	}

	@include media($mg){
		height: 338px;
	}

	&__image{
		position: absolute;
		width: 100%;
		height: 100%;
		transition: transform 0.3s ease, opacity 0.3s ease;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			overflow: hidden;

		img{
			position: absolute;
			max-width: calc(100% - 100px);
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&__name-row{
		position: absolute;
		bottom: 0;
		left: 50px;
		opacity: 0;
		transition: opacity 0.3s ease;
	}
	&__name{
		font-size: 24px;
		font-weight: 300;
		color: $c-red;
		font-family: 'Open Sans';
		margin-bottom: 10px;
	}
	&__text{
		font-size: 12px;
		font-weight: 300;
		font-family: 'Open Sans';
		color: $c-black;
		letter-spacing: 1px;
		transition: color 0.3s ease;
		margin-bottom: 14px;
	}
	&__decor{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		&:after,
		&:before{
			content: "";
			position: absolute;
			width: 20px;
			height: 20px;
			opacity: 0;
			transition: opacity 0.3s 0.2s ease, transform 0.3s 0.2s ease;
		}
		&:after{
			left: 35px;
			top: 35px;
			border-top: 1px solid $c-red;
			border-left: 1px solid $c-red;
			transform: translate(-25px, -25px);

			@include media($md){
				left: 50px;
				top: 50px;
			}
		}
		&:before{
			right: 35px;
			bottom: 35px;
			border-bottom: 1px solid $c-red;
			border-right: 1px solid $c-red;
			transform: translate(25px, 25px);

			@include media($md){
				right: 50px;
				bottom: 50px;
			}
		}
	}
	&__decor-button{
		width: 50px;
		height: 50px;
		right: 0;
		bottom: 0;
		background-color: $c-red;
		display: flex;
		align-items: center;
		justify-content: center;	
		position: absolute;
		opacity: 0;
		transition: opacity 0.3s ease, background-color 0.3s ease;

		svg{
			fill: $c-white;
			width: 8px;
			height: 12px;
		}
	}

	&:hover{
		.brend-item{
			&__image{
				transform: scale(1.06) translate(-50%, -50%);
				opacity: 0.2;
			}
			&__name-row{
				opacity: 1;
			}
			&__decor{
				&:after,
				&:before{
					opacity: 1;
					transform: translate(0, 0);
					transition: opacity 0.3s ease, transform 0.3s ease;
				}
			}
			&__decor-button{
				opacity: 1;
			}
		}
	}
	&--list{
		border: 1px solid $c-lgrey2;
		width: 100%;
		margin-bottom: 2px;

		.brend-item{
			&__name-row{
				opacity: 1;
			}
			&__name{
				opacity: 0;
				transition: opacity 0.3s ease;
			}
			&__decor-button{
				opacity: 1;
				background-color: $c-greyNUBLIA;
			}
		}

		&:hover{
			border-color: $c-red;

			.brend-item{
				&__name-row{
					opacity: 1;
				}
				&__text{
					color: $c-red;
				}
				&__name{
					opacity: 1;
				}
				&__decor-button{
					background-color: $c-red;
				}
			}
		}
	}
}
.brends-list{
	margin-bottom: 20px;

	@include media(def){
		margin-bottom: 50px;
	}	
}

// .touchevents{
// 	.brend-item{
// 			&__image{
// 				transform: scale(0.94) translate(-50%, -50%);
// 				opacity: 0.2;
// 			}
// 			&__name-row{
// 				opacity: 1;
// 			}
// 			&__decor{
// 				&:after,
// 				&:before{
// 					opacity: 1;
// 					transform: translate(0, 0);
// 					transition: opacity 0.3s ease, transform 0.3s ease;
// 				}
// 			}
// 			&__decor-button{
// 				opacity: 1;
// 			}
// 		}
// }