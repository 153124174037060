.preheader{
	background-color: $c-black;
	position: relative;
	height: 140px;
	width: 100%;
	overflow: hidden;
	margin-bottom: 0;
	display: none;

	@include media($md){
		height: 180px;
		display: block;
	}

	@include media($def){
		height: 220px;
	}
	
	&__decor{
		position: absolute;
		left: 50%;
		top: 20%;
		transform: translate(-50%, -50%);

		svg{
			width: 590px;
			stroke: #fff;
			fill: transparent;
			opacity: 0.1;
			height: 470px;
			stroke-width: 0.3px;
		}
	}

	&__image{
		opacity: 0.5;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

		img{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			max-width: 1920px;
			min-width: 1920px;
			width: 1920px;
		}
	}
}