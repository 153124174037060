////
/// @group _partials/view-size.scss
////


// scss
// ================================

.view-size {
	position: relative;
	max-width: 1820px;
	max-width: calc(1820px + 48px );
	padding: 0 14px;
	margin: 0 auto;
	width: 100%;
	flex-wrap: wrap;
	display: flex;

	&--md{
		max-width: 960px;
	}
	&--lg{
		max-width: 1440px;
	}
	&--mg{
		max-width: 100%;
		width: 100%;
		padding: 0 14px 0 14px;

		@include media($def){
			padding: 0 0 0 30px;
		}
	}
}

// отображение
.view-size {
	&--no-gap {
		max-width: $lg;
	}
}
